import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'

const arraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length != b.length) return false

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

function indexOfClosest(numbers, target) {
  let closest = Number.MAX_SAFE_INTEGER
  let index = 0

  numbers.forEach((number, i) => {
    const dist = Math.abs(target - number)

    if (dist < closest) {
      index = i
      closest = dist
    }
  })
  return index
}
const defaultArrowStyle = css`
  top: 35%;
  width: 64px;
  height: 64px;
`

export default class HorizontalScrollable extends React.Component {
  static defaultProps = {
    reachEndThreshold: 0,
    slidesToScroll: 'auto',
    containerStyle: ``,
    sliderStyle: ``,
    slideStyle: ``,
    rightArrowStyle: defaultArrowStyle,
    leftArrowStyle: defaultArrowStyle,
    loop: false,
  }
  static propTypes = {
    slidesToScroll: PropTypes.oneOfType([
      PropTypes.oneOf(['auto']),
      PropTypes.number,
    ]),
    reachEndThreshold: PropTypes.number,
    onReachEnd: PropTypes.func,
    children: PropTypes.node,
  }
  static HANDLE_SCROLL_BOUNCE_INTERVAL = 200

  constructor(props) {
    super(props)

    this.containerRef = React.createRef()

    this.slidesOffsetLeft = []

    this.debouncedHandleResize = _.debounce(
      this.handleResize,
      HorizontalScrollable.HANDLE_SCROLL_BOUNCE_INTERVAL
    )

    this.state = {
      displayRightArrow: false,
      displayLeftArrow: false,
      slidesToScroll: props.slidesToScroll,
    }
  }

  componentDidUpdate(prevProps) {
    if (!arraysEqual(prevProps.children, this.props.children)) {
      this.displayArrows()
    }
  }

  displayArrows = () => {
    const el = this.containerRef.current

    this.setState({
      displayLeftArrow: el.scrollLeft != 0,
      displayRightArrow: el.scrollWidth !== el.offsetWidth + el.scrollLeft,
    })
  }

  handleScroll = () => {
    const el = this.containerRef.current

    if (!el) return

    this.displayArrows()

    if (
      this.props.onReachEnd &&
      el.scrollWidth - (el.offsetWidth + el.scrollLeft) <=
        this.props.reachEndThreshold
    ) {
      this.removeListeners()
      Promise.resolve(this.props.onReachEnd() || Promise.reject())
        .finally(this.addListeners)
        .then(this.handleScroll, () => {})
    }
  }

  addListeners = () => {
    window.addEventListener('resize', this.debouncedHandleResize)
    this.containerRef.current.addEventListener('scroll', this.handleScroll)
  }

  removeListeners = () => {
    window.removeEventListener('resize', this.debouncedHandleResize)
    this.containerRef.current.removeEventListener('scroll', this.handleScroll)
  }

  handleResize = () => {
    this.handleScroll()
    this.autoDetectSlidesToScroll()
  }

  autoDetectSlidesToScroll = () => {
    if (this.props.slidesToScroll == 'auto') {
      const container = this.containerRef.current
      if (container) {
        const leftBoundary = indexOfClosest(
          this.slidesOffsetLeft,
          container.scrollLeft
        )
        const rightBoundary = indexOfClosest(
          this.slidesOffsetLeft,
          container.scrollLeft + container.offsetWidth
        )

        this.setState({
          slidesToScroll: this.slidesOffsetLeft.slice(
            leftBoundary,
            rightBoundary
          ).length,
        })
      }
    }
  }

  componentDidMount() {
    this.autoDetectSlidesToScroll()
    smoothscroll.polyfill()
    this.handleScroll()
    this.addListeners()
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  scroll = direction => {
    let { slidesToScroll } = this.state
    const container = this.containerRef.current

    if (container) {
      const closestIndex = indexOfClosest(
        this.slidesOffsetLeft,
        container.scrollLeft
      )
      const indexMax = this.slidesOffsetLeft.length - 1
      const nextSlideIndex = closestIndex + slidesToScroll * direction
      const scollTo =
        nextSlideIndex > indexMax
          ? container.scrollWidth
          : this.slidesOffsetLeft[
              Math.min(indexMax, Math.max(0, nextSlideIndex))
            ]

      container.scroll({
        left: scollTo,
        behavior: 'smooth',
      })
    }
  }

  render() {
    const {
      children,
      containerStyle,
      sliderStyle,
      slideStyle,
      leftArrowStyle,
      rightArrowStyle,
      loop,
    } = this.props

    const { displayRightArrow, displayLeftArrow } = this.state

    // TODO: implement real infinite loop
    const el = this.containerRef.current
    const displayArrows = el && el.offsetWidth < el.scrollWidth
    const items =
      loop && displayArrows
        ? [...children, ...children, ...children, ...children, ...children]
        : children

    return (
      <Container containerStyle={containerStyle}>
        <Slider sliderStyle={sliderStyle} ref={this.containerRef}>
          {displayLeftArrow && (
            <LeftArrow
              onClick={e => {
                e.stopPropagation()
                this.scroll(-1)
              }}
              className="arrow"
              leftArrowStyle={leftArrowStyle}
            >
              <svg
                viewBox="0 0 18 18"
                style={{
                  fill: 'currentcolor',
                  height: '1em',
                  width: '1em',
                  display: 'block',
                }}
              >
                <path
                  d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </LeftArrow>
          )}
          {items.map((children, index) => (
            <Slide
              key={index}
              ref={slide => {
                if (slide) this.slidesOffsetLeft[index] = slide.offsetLeft
              }}
              slideStyle={slideStyle}
            >
              {children}
            </Slide>
          ))}
          {displayRightArrow && (
            <RightArrow
              onClick={e => {
                e.stopPropagation()
                this.scroll(1)
              }}
              className="arrow"
              rightArrowStyle={rightArrowStyle}
            >
              <svg
                viewBox="0 0 18 18"
                style={{
                  fill: 'currentcolor',
                  height: '1em',
                  width: '1em',
                  display: 'block',
                }}
              >
                <path
                  d="m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </RightArrow>
          )}
        </Slider>
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  ${props => props.containerStyle}
`

const Slider = styled.div`
  display: flex;
  flex-wrap: nowrap
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-autohiding-scrollbar: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${props => props.sliderStyle}
`

const Arrow = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: #fff;
  font-size: 1em;
  color: #333;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e6e6e6;
  &:hover {
    background-color: #f9f9f9;
  }
  &:active {
    background-color: #f3f3f3;
  }
`

const LeftArrow = styled.div`
  left: 0;
  margin-left: -20px;
  ${Arrow}
  ${props => props.leftArrowStyle}
`

const RightArrow = styled.div`
  right: 0;
  margin-right: -20px;
  ${Arrow}
  ${props => props.rightArrowStyle}
`

const Slide = styled.div`
  &:not(:last-child) {
    margin-right: 15px;
  }
  ${props => props.slideStyle}
`
