import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  HelpBlock,
} from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import styled from 'styled-components'
import { ControlledButtonWithLoader } from 'shared/components/ButtonWithLoader'
import fetchAPI, { APIError } from 'shared/fetchAPI'
import Layout from 'creators/Settings/Layout'
import { CreatorContext } from 'creators/CreatorProvider'
import PhoneIcon from 'assets/images/phone.svg'
import PhoneShinyIcon from 'assets/images/phone-shiny.svg'
import CongratsIcon from 'assets/images/congrats.svg'
import setProperty from 'creators/common/setProperty'

const ERROR_WORDING = {
  phone_not_present: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.phone_not_present'
  ),
  phone_already_exists: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.phone_already_exists'
  ),
  phone_invalid: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.phone_invalid'
  ),
  code_not_present: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.phone_pin_not_present'
  ),
  code_expired: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.phone_pin_expired'
  ),
  code_invalid: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.phone_pin_invalid'
  ),
  sns_error_no_budget: I18n.t(
    'js.creators.campaigns.modal.phoneconfirm.errormsg.sns_error_no_budget'
  ),
  default: I18n.t('js.creators.campaigns.modal.phoneconfirm.errormsg.default'),
}

const consent = {
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
}

const ConfirmPhoneModal = ({
  startScreen,
  onClose,
  onFinish,
  fromMenu = false,
}) => {
  const { creator, refreshMe } = useContext(CreatorContext)
  const [state, setState] = useState({
    loading: false,
    error: null,
    screen: startScreen,
  })

  const [userPhone, setUserPhone] = useState({
    country: creator.country_code,
    phone: creator.unconfirmed_phone || '',
  })
  const [code, setCode] = useState('')
  const [smsConsent, setSmsConsent] = useState(false)

  const checkPhone = async () => {
    if (!state.loading) {
      setState({ ...state, loading: true })

      try {
        await fetchAPI('/api/v3/creators/phone/confirm_step1', {
          method: 'POST',
          body: userPhone,
        })
        setState({ ...state, loading: false, screen: 'pinCode', error: null })
        setCode('')
      } catch (error) {
        if (error instanceof APIError) {
          if (error.data[0] === 'phone_same')
            setState({
              ...state,
              loading: false,
              screen: 'success',
              error: null,
            })
          else
            setState({
              ...state,
              loading: false,
              error: ERROR_WORDING[error.data[0]],
            })
        } else
          setState({
            ...state,
            loading: false,
            error: ERROR_WORDING['default'],
          })
      }
    }
  }

  const checkPin = async () => {
    if (!state.loading) {
      setState({ ...state, loading: true })

      try {
        await fetchAPI('/api/v3/creators/phone/confirm_step2', {
          method: 'POST',
          body: { code },
        })

        // If user approved SMS consent, update creator notification settings
        if (smsConsent) {
          await fetchAPI(`/api/v3/creators/me/notifications`, {
            method: 'PATCH',
            body: {
              creator: {
                ['creator:sms:marketing_communication_consent']: true,
              },
            },
          })
        }

        // Set property for SMS marketing communication consent
        setProperty('sms_marketing_communication_consent', {
          action: smsConsent ? consent.ACCEPTED : consent.REFUSED,
          action_at: new Date().getTime(),
        })

        await refreshMe()
        setState({ ...state, loading: false, screen: 'success', error: null })
      } catch (error) {
        const errorMsg = ERROR_WORDING[error.data ? error.data[0] : 'default']
        setState({ ...state, loading: false, error: errorMsg })
      }
    }
  }

  const Step = fromMenu ? SettingsStep : ModalStep

  /**
   *  PHONE NUMBER SCREEN
   *  CONTAINS INPUT WHERE USER ENTERS ITS PHONE NUMBER
   */
  if (state.screen === 'phoneNumber') {
    return (
      <Step
        title={I18n.t(
          'js.creators.campaigns.modal.phoneconfirm.screen.phone_number.title'
        )}
        subtitle={I18n.t(
          'js.creators.campaigns.modal.phoneconfirm.screen.phone_number.subtitle'
        )}
        icon={<PhoneIcon />}
        closeButton
        body={
          <>
            <div>
              <PhoneInput
                country={creator.country_code.toLowerCase()}
                value={userPhone.phone}
                placeholder=""
                onlyCountries={
                  creator.free_store_country_code === 'US' ? ['us'] : undefined
                }
                enableTerritories
                countryCodeEditable={false}
                containerStyle={{ width: 'unset' }}
                inputProps={{ autoFocus: true }}
                onChange={(phone, { countryCode }) => {
                  setUserPhone({
                    country: countryCode.toUpperCase(),
                    phone,
                  })
                }}
                specialLabel=""
              />
              <HelpError className="has-error" style={{ minHeight: '18px' }}>
                {state.error}
              </HelpError>
            </div>
            <div className="bottom-20">
              <label>
                <input
                  id="smsConsent"
                  type="checkbox"
                  checked={smsConsent}
                  onChange={() => {
                    setSmsConsent(!smsConsent)
                  }}
                />{' '}
                {I18n.t(
                  'js.creators.campaigns.modal.phoneconfirm.screen.sms_consent'
                )}
              </label>
            </div>
          </>
        }
        footer={
          <ControlledButtonWithLoader
            bsStyle="primary"
            loading={state.loading}
            onClick={checkPhone}
          >
            {I18n.t(
              'js.creators.campaigns.modal.phoneconfirm.buttons.send_code'
            )}
          </ControlledButtonWithLoader>
        }
      />
    )
  }

  /**
   *  PIN CODE SCREEN
   *  CONTAINS INPUT WHERE USER ENTERS THE CODE RECEIVED
   */
  if (state.screen === 'pinCode') {
    return (
      <Step
        title={I18n.t(
          'js.creators.campaigns.modal.phoneconfirm.screen.pin_code.title'
        )}
        subtitle={I18n.t(
          'js.creators.campaigns.modal.phoneconfirm.screen.pin_code.subtitle'
        )}
        icon={<PhoneShinyIcon />}
        body={
          <FormGroup
            bsSize="lg"
            validationState={state.error ? 'error' : null}
            className="bottom-15"
          >
            <div className="d-flex flex-column align-items-center">
              <FormControlNumber
                type="number"
                className={state.error ? 'has-error' : null}
                autoFocus
                value={code}
                onChange={e => {
                  // NOTE: 'maxLength' props does not work with
                  // reactbootstrap's FormControl with type="number" prop
                  const userCode = e.target.value.slice(0, 4)
                  setCode(userCode)
                }}
              />
              <HelpError className="has-error" style={{ minHeight: '18px' }}>
                {state.error}
              </HelpError>
            </div>
          </FormGroup>
        }
        footer={
          <>
            <ControlledButtonWithLoader
              bsStyle="primary"
              loading={state.loading}
              onClick={checkPin}
              className="bottom-15"
            >
              {I18n.t(
                'js.creators.campaigns.modal.phoneconfirm.buttons.submit'
              )}
            </ControlledButtonWithLoader>
            <ActionButton
              onClick={() =>
                setState({ ...state, screen: 'phoneNumber', error: null })
              }
            >
              {I18n.t(
                'js.creators.campaigns.modal.phoneconfirm.screen.pin_code.send_back_code'
              )}
            </ActionButton>
          </>
        }
      />
    )
  }

  /**
   *  SUCCESS SCREEN
   *  NOTIFIY USER THAT ITS PHONE NUMBER HAS BEEN SUCCESSFULLY VERIFIED
   */
  if (state.screen === 'success') {
    return (
      <Step
        title={I18n.t(
          'js.creators.campaigns.modal.phoneconfirm.screen.success.title'
        )}
        subtitle={I18n.t(
          'js.creators.campaigns.modal.phoneconfirm.screen.success.subtitle'
        )}
        icon={<CongratsIcon />}
        footer={
          <Button
            className="top-40 left-0"
            bsStyle="primary"
            onClick={onFinish}
          >
            {fromMenu
              ? 'OK'
              : I18n.t(
                  'js.creators.campaigns.modal.phoneconfirm.buttons.complete'
                )}
          </Button>
        }
      />
    )
  }

  /**
   *  ERROR SCREEN
   *  NOTIFIY USER THAT SOMETHING WENT WRONG
   */
  return (
    <Step
      title={I18n.t(
        'js.creators.campaigns.modal.phoneconfirm.screen.error.title'
      )}
      subtitle={ERROR_WORDING.default}
      body={undefined}
      footer={
        <Button className="top-20 left-0" onClick={onClose}>
          {I18n.t('js.creators.campaigns.modal.phoneconfirm.buttons.close')}
        </Button>
      }
    />
  )
}

function ModalStep({
  title,
  subtitle,
  icon,
  body,
  footer,
  closeButton = false,
}) {
  return (
    <>
      <Modal.Header closeButton={closeButton}>
        <Title componentClass="div">
          {icon && <div className="top-30">{icon}</div>}
          <h1 className="font-light text-uppercase text-center">{title}</h1>
          <h4 className="text-muted font-light text-center">{subtitle}</h4>
        </Title>
      </Modal.Header>
      {body && (
        <Modal.Body className="align-items-center flex flex-column ptop-40 pbottom-0">
          {body}
        </Modal.Body>
      )}
      <Modal.Footer className="align-items-center flex flex-column pbottom-45">
        {footer}
      </Modal.Footer>
    </>
  )
}

function SettingsStep({ title, subtitle, body, footer }) {
  return (
    <Layout.Box>
      <Layout.Title>{title}</Layout.Title>
      <Layout.Subtitle>{subtitle}</Layout.Subtitle>
      {body}
      {footer}
    </Layout.Box>
  )
}

/**
 * STYLED COMPONENTS
 */
const Title = styled(Modal.Title)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0 27.45px;

  h1 {
    font-size: 36px;
    line-height: 40px;
    margin: 30px 0 8px 0;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    padding: 0 40px;
    margin: 0;
  }
`

const HelpError = styled(HelpBlock)`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`

const ActionButton = styled.button`
  color: #3045a1;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  outline: none;
  border: none;
  background-color: transparent;
`

const FormControlNumber = styled(FormControl)`
  background-color: #e7f5f9;
  color: #3045a1;
  height: 72px !important;
  width: 185px;
  padding: 5px 0 0 15px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 40px !important;
  line-height: 72px !important;
  letter-spacing: 20px;
  border: none;
  border-radius: 8px !important;
  box-shadow: none;
  text-align: center;
  -moz-appearance: textfield;

  &:focus,
  &:active {
    padding: 5px 0 0 0 !important;
  }

  &.has-error {
    border: 1px solid #d0021b;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

/* const SMSConsentCheckbox = styled.input`
  margin-right: 6px;
  margin-top: 
` */

ConfirmPhoneModal.propTypes = {
  startScreen: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
}

ConfirmPhoneModal.defaultProps = {
  startScreen: 'phoneNumber', // Possible values: 'phoneNumber', 'pinCode'
}

export default ConfirmPhoneModal
