import React from 'react'
import cn from 'classnames'

export default React.forwardRef(function MaterialInput(
  { label, style, className, ...props },
  ref
) {
  return (
    <div className={cn('material-input-group', className)} style={style}>
      <input placeholder=" " {...props} ref={ref} />
      <span className="bar" />
      <label className="color-gray-text-light">{label}</label>
    </div>
  )
})
