import React, { useEffect, useRef, useState, createContext } from 'react'
import reject from 'lodash/fp/reject'
import { hot } from 'react-hot-loader/root'
import Launcher from './Launcher'
import update from 'immutability-helper'

window.OCTOLY_DEVTOOL_ENABLED = true
export const DebugContext = createContext()

const DebugProvider =
  function DebugProvider({ children }) {
    const callbackRef = useRef({})
    const [open, baseSetOpen] = useState(
      JSON.parse(localStorage.getItem('_octolyDebugOpen') ?? 'false')
    )
    const [activeTool, setActiveTool] = useState()
    const [registeredTools, setRegisteredTools] = useState([])
    const registerTool = ({ key, label, color }) => {
      setRegisteredTools(registeredTools => [
        ...registeredTools,
        {
          key,
          label,
          color,
        },
      ])
    }
    const unregisterTool = key => {
      setRegisteredTools(
        registeredTools => registeredTools |> reject(tool => tool.key === key)
      )
    }
    const updateTool = (key, $merge) => {
      setRegisteredTools(
        update(registeredTools, {
          $find: ['key', key, { $merge }],
        })
      )
    }
    const setOpen = value => {
      baseSetOpen(value)
      if (!value) setActiveTool(undefined)
    }

    useEffect(() => {
      localStorage.setItem('_octolyDebugOpen', JSON.stringify(open))
      window.OCTOLY_DEVTOOL_OPENED = open
    }, [open])
    useEffect(() => {
      const registerListener = document.addEventListener(
        'octolyDevtoolRegisterTool',
        function (e) {
          registerTool(e.detail)
          callbackRef.current[e.detail.key] = e.detail.onClick
        }
      )
      const unregisterListener = document.addEventListener(
        'octolyDevtoolUnregisterTool',
        function (e) {
          unregisterTool(e.detail)
        }
      )
      return () => {
        document.removeEventListener(
          'octolyDevtoolRegisterTool',
          registerListener
        )
        document.removeEventListener(
          'octolyDevtoolUnregisterTool',
          unregisterListener
        )
      }
    }, [])
    return (
      <DebugContext.Provider
        value={{
          open,
          setOpen,
          activeTool,
          setActiveTool,
          registeredTools,
          registerTool,
          unregisterTool,
          callbackRef,
          updateTool,
        }}
      >
        {children}
        <Launcher />
      </DebugContext.Provider>
    )
  } |> hot

export default DebugProvider
