import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

export default ({ title, children, placement }) => (
  <OverlayTrigger
    placement={placement || 'top'}
    overlay={<Tooltip id="tooltip">{title}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
)
