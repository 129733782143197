import React from 'react'
import cn from 'classnames'

import Alert from 'assets/images/alert-circle.svg'
import nl2br from 'shared/nl2br'

export function InputFeedback({ type, message, className }) {
  return (
    <div
      type={type}
      className={cn(
        'd-flex-inline align-items-start bgc-transparent br-default mt-8',
        className
      )}
    >
      {(() => {
        switch (
          type // Cases for further uses
        ) {
          case 'info':
          case 'valid':
          case 'warning':
          case 'error':
          default:
            return (
              <Alert
                width={16}
                height={16}
                css={`
                  min-width: 16px;
                  border: 1px solid red;
                  border-radius: 50%;
                  & > path + path {
                    fill: red;
                  }
                `}
              />
            )
        }
      })()}
      <div className="mb-0 ml-4 ft-14 lh-18 tc-redAlt font-light">
        {message |> nl2br}
      </div>
    </div>
  )
}
