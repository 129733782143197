import React, { useEffect, useRef } from 'react'

const ReactVersionBridge = ({
  legacyComponentName,
  legacyComponentProps,
  style,
  className,
}) => {
  const nodeRef = useRef()
  useEffect(() => {
    if (!nodeRef.current) return
    if (!window.React || !window.ReactDOM)
      throw new Error(`Missing React or ReactDOM in global scope.`)
    if (!window[legacyComponentName])
      throw new Error(`Missing ${legacyComponentName} in global scope.`)
    window.ReactDOM.render(
      window.React.createElement(
        window[legacyComponentName],
        legacyComponentProps
      ),
      nodeRef.current
    )
    return () => {
      if (nodeRef.current) {
        window.ReactDOM.unmountComponentAtNode(nodeRef.current)
      }
    }
  }, [nodeRef.current])
  return <div ref={nodeRef} style={style} className={className} />
}

export default ReactVersionBridge
