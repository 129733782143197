import React from 'react'
import StoreCard from './StoreCard'
import MainBlock from './MainBlock'
import SmallTextInfo from './SmallTextInfo'
import styled from 'styled-components'
import { BREAKPOINTS } from 'shared/scss'

const Aside = () => {
  return (
    <AsideContainer>
      <MainBlock />
      <StoreCard />
      <SmallTextInfo />
    </AsideContainer>
  )
}

const AsideContainer = styled.div`
  .box {
    padding: 20px 0;
    @media screen and (min-width: ${BREAKPOINTS.md}px) {
      padding: 20px;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    @media screen and (max-width: ${BREAKPOINTS.md - 0.02}px) {
      border: none;
    }
  }
`

export default Aside
