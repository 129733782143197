import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'

const FetchSelect = ({
  onLoad,
  defaultValue,
  onChange,
  onItemsFetched,
  setFetchedItems,
  labelName,
  valueName,
  style,
  fetchingMessage,
  errorMessage,
  placeholder,
  disabled,
}) => {
  const [items, setItems] = useState([
    { [valueName]: '', [labelName]: fetchingMessage },
  ])

  useEffect(() => {
    fetchItems()
  }, [])

  let retryCount = 0

  const fetchItems = async () => {
    let fetchedItems

    try {
      fetchedItems = await onLoad()
    } catch (e) {
      if (retryCount <= 3) {
        retryCount += 1
        fetchItems()
      } else {
        setItems([{ [valueName]: '', [labelName]: errorMessage }])
      }
      return
    }
    onItemsFetched(fetchedItems)
    if (setFetchedItems) {
      fetchedItems = setFetchedItems(fetchedItems, valueName, labelName)
    }
    if (placeholder) {
      fetchedItems = [
        { [valueName]: '', [labelName]: placeholder },
        ...fetchedItems,
      ]
    }
    setItems(fetchedItems)
  }

  return (
    <FormControl
      style={style}
      componentClass="select"
      disabled={disabled || items.length === 1}
      onChange={e =>
        onChange(items.find(item => item[valueName] === e.target.value))
      }
      defaultValue={defaultValue}
      key={items.length}
    >
      {items.map((item, index) => (
        <option key={index} value={item[valueName]}>
          {item.name}
        </option>
      ))}
    </FormControl>
  )
}

FetchSelect.defaultProps = {
  onChange: () => {},
  onItemsFetched: () => {},
  labelName: 'label',
  valueName: 'value',
  style: {},
  fetchingMessage: I18n.t('js.items_select.fetching'),
  errorMessage: I18n.t('js.items_select.error'),
}

FetchSelect.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onItemsFetched: PropTypes.func,
  setFetchedItems: PropTypes.func,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  style: PropTypes.object,
  fetchingMessage: PropTypes.string,
  erroMessage: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default FetchSelect
