import React, { useRef, useContext } from 'react'
import {
  InputGroup,
  DropdownButton,
  MenuItem,
  Popover,
  Overlay,
} from 'react-bootstrap'
import { CampaignContext } from '../..'
import cn from 'classnames'
import sortBy from 'lodash/fp/sortBy'

export default function OptionsSelect({
  valid,
  value,
  placeholder,
  onChange,
  productId,
}) {
  const {
    campaign: { products },
  } = useContext(CampaignContext)

  const product = products.find(product => product.id === productId)
  const options = product.options.filter(option => option.cpo) |> sortBy('name')
  const isDisabled = option => option.cpo.quantity_left <= 0
  const selected = options.find(option => option.id === value)

  const onSelectionChange = option => {
    if (option |> isDisabled) return
    if (typeof onChange === 'function') {
      onChange(option.id)
    }
  }

  const selectorRef = useRef()

  return (
    <div className="bottom-5 dropdown-button-text-overflow">
      <DropdownButton
        id="campaign-select-options"
        componentClass={InputGroup.Button}
        title={
          value ? (
            <span
              dangerouslySetInnerHTML={{
                __html: selected.name || product.title,
              }}
            />
          ) : (
            placeholder
          )
        }
        className={cn({ 'has-error': !valid })}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        block={true}
        ref={selectorRef}
        onClick={e => e.stopPropagation()}
      >
        {options.map(option => {
          const text = generateText(option, option |> isDisabled, product)

          return (
            <MenuItem
              key={`option-${option.id}`}
              onClick={() => onSelectionChange(option)}
              active={option.id === value}
              className={option |> isDisabled ? 'disabled' : ''}
            >
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </MenuItem>
          )
        })}
      </DropdownButton>
      <Overlay show={!valid} placement="top" target={selectorRef.current}>
        <Popover id="please-select-option" placement="top">
          {I18n.t('js.creators.campaigns.show.campaign_options_validation')}
        </Popover>
      </Overlay>
    </div>
  )
}

const generateText = (option, disabled, product) => {
  const disabledText = disabled
    ? ` - ${I18n.t('creators.campaigns.load_product.out_of_stock')}`
    : ''
  return `${option.name || product.title}${disabledText}`
}
