import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import useDimensions from 'react-use-dimensions'

import { BaseInputStyle } from './BaseInput'
import { InputFeedback } from './InputFeedback'

function ControlledTextareaInput({
  threshold,
  minMax,
  className,
  name,
  id,
  error,
  value,
  onChange,
  onBlur,
  required,
  rows = 3,
  innerRef,
  ...props
}) {
  const [lengthCheck, setLengthCheck] = useState()
  const [counterRef, { width: counterWidth }] = useDimensions()

  useEffect(() => {
    if (threshold && minMax !== 'min' && minMax !== 'max') {
      throw new Error(
        '`minMax` must equal to `min` or `max` if `threshold` is provided'
      )
    }
    if (
      (!threshold || threshold === 0) &&
      (minMax === 'min' || minMax === 'max')
    ) {
      throw new Error(
        '`threshold` must be greater than 0 if `minMax` is provided'
      )
    }
  }, [threshold, minMax])

  const isLengthValid = value => {
    if (required && !value) {
      return I18n.t('js.errors.blank')
    }
    if (minMax === 'min') {
      return value.length >= threshold
        ? null
        : I18n.t('js.components.textareainput.too_short', {
            min_length: threshold,
            current_length: value.length,
          })
    } else if (minMax === 'max') {
      return value.length <= threshold
        ? null
        : I18n.t('js.components.textareainput.too_long', {
            max_length: threshold,
            current_length: value.length,
          })
    } else {
      return null
    }
  }

  const onBlurCheck = e => {
    setLengthCheck(isLengthValid(value))
    if (onBlur) {
      onBlur(e)
    }
  }

  const _onChange = e => {
    if (minMax === 'min') {
      setLengthCheck(null)
    } else {
      setLengthCheck(isLengthValid(e.target.value))
    }
    onChange(e)
  }

  const displayError = error || lengthCheck

  return (
    <Container className={className}>
      <Textarea
        ref={innerRef}
        paddingRight={counterWidth}
        name={name}
        id={id || name}
        error={displayError}
        onBlur={onBlurCheck}
        value={value}
        onChange={_onChange}
        required={required}
        rows={rows}
        {...props}
      />
      {!!threshold && (
        <Counter
          ref={counterRef}
          className={`flex flex-center ${
            lengthCheck ? 'tc-redAlt' : 'font-light tc-grayTextLight'
          }`}
        >
          {value?.length || 0}/{threshold} {minMax}.
        </Counter>
      )}
      {displayError && <InputFeedback type="error" message={displayError} />}
    </Container>
  )
}

function UncontrolledTextareaInput({ defaultValue = '', ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledTextareaInput
      value={value}
      onChange={e => setValue(e.target.value)}
      {...props}
    />
  )
}

export const TextareaInput = React.forwardRef((props, ref) => {
  if (props.onChange)
    return <ControlledTextareaInput innerRef={ref} {...props} />
  return <UncontrolledTextareaInput innerRef={ref} {...props} />
})

const Container = styled.div`
  position: relative;
`

const Textarea = styled.textarea`
  ${BaseInputStyle};
  ${({ paddingRight }) => css`
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: ${paddingRight ? `${paddingRight}px` : '16px'};
    height: unset;
  `};
`

const Counter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  padding: 11px 16px 8px;
`
