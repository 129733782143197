import styled, { css } from 'styled-components'

export const BaseInputStyle = css`
  width: 100%;
  height: ${({ theme }) => theme.global.inputs.height};
  margin: 0;
  border: ${({ theme }) => theme.global.inputs.border};
  border-radius: ${({ theme }) => theme.global.inputs.borderRadius};
  background-color: ${({ theme }) => theme.global.inputs.backgroundColor};
  padding: 0 16px;
  font-family: ${({ theme }) => theme.global.inputs.fontFamily};
  font-size: ${({ theme }) => theme.global.inputs.fontSize};
  line-height: ${({ theme }) => theme.global.inputs.lineHeight};
  color: ${({ theme }) => theme.global.inputs.color};
  outline: none;
  transition: ${({ theme }) => theme.global.transition};
  display: block;

  &::placeholder {
    color: ${({ theme }) => theme.global.inputs.placeholderColor};
  }

  &:hover {
    border: ${({ theme, disabled }) =>
      disabled ? theme.global.inputs.border : theme.global.inputs.hover.border};
  }

  &:focus {
    border: ${({ theme }) => theme.global.inputs.focus.border};
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    border: ${({ theme }) => theme.global.inputs.border};
  }

  ${({ error }) => {
    if (error)
      return css`
        border: ${({ theme }) => theme.global.inputs.error.border};
      `
  }}
`

export const BaseInput = styled.input`
  ${BaseInputStyle}
`
