import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import CloseIcon from 'assets/images/cross.svg'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import { DebugContext } from './DebugProvider'
import ButtonWithLoader from 'shared/components/ButtonWithLoader'

export default function DebugBar({ children, className }) {
  const { setActiveTool } = useContext(DebugContext)
  return createPortal(
    <DebugToolBarContainer
      className={cn('d-flex align-items-center', className)}
    >
      <div className="d-flex flex-wrap flex-grow-1">{children}</div>
      <CloseIcon
        onClick={() => {
          setActiveTool(undefined)
        }}
        style={{
          color: '#aba6a2',
          width: 12,
          height: 12,
          marginRight: 15,
          marginBottom: 5,
          cursor: 'pointer',
        }}
      />
    </DebugToolBarContainer>,
    document.body
  )
}

DebugBar.Button = styled(ButtonWithLoader)`
  margin: 5px;
`

const DebugToolBarContainer = styled.div`
  border-top: solid 4px #ffe6c1;
  background-color: #fffbea;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  min-height: 44px;
`
