import React from 'react'
import cn from 'classnames'
import PercentIcon from 'assets/images/percent.svg'
import LinkIcon from 'assets/images/link.svg'

export function CampaignInfo({
  title,
  iconComponent: Icon,
  description,
  copiableText,
  bgColorName,
  textColorName,
  noMargins = false,
}) {
  const isPlainHtml = description && !description.props

  return (
    <div
      className={cn(`bgc-${bgColorName} p-16 br-default`, {
        'my-20': !noMargins,
      })}
    >
      <div
        className={`bold tc-${textColorName} d-flex align-items-center bottom-10 fs-18`}
        css="line-height: 1em;"
      >
        {Icon && <Icon width={22} height={22} className="right-10" />}
        {title}
      </div>
      {isPlainHtml ? (
        <div
          className="top-5"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : (
        description && <div className="top-5">{description}</div>
      )}
      {copiableText && (
        <div className="top-10">
          <span
            className={`bgc-${textColorName} color-white bold d-inline-block p-10 br-default`}
            css="max-width: 100%; word-wrap: break-word;"
          >
            {copiableText}
          </span>
        </div>
      )}
    </div>
  )
}

function DigitalProductInfo({ digitalAsset, digitalAssetUsage, noMargins }) {
  const copiableText = digitalAsset?.content || digitalAsset
  return (
    <CampaignInfo
      noMargins={noMargins}
      title={I18n.t('js.creators.campaigns.show.digital_product_block.title')}
      iconComponent={PercentIcon}
      description={
        digitalAssetUsage ||
        I18n.t('js.creators.campaigns.show.digital_product_block.description')
      }
      copiableText={copiableText}
      bgColorName="yellowLight"
      textColorName="orange"
    />
  )
}

function AffiliateLinkInfo({ link, personalized = true, noMargins }) {
  return (
    <CampaignInfo
      noMargins={noMargins}
      title={I18n.t('js.creators.campaigns.show.affiliate_link_block.title')}
      description={
        personalized
          ? I18n.t('js.creators.campaigns.show.affiliate_link_reminder_long')
          : null
      }
      copiableText={link}
      iconComponent={LinkIcon}
      bgColorName="teaLighter"
      textColorName="tea"
    />
  )
}

function PromoCodeInfo({
  promoCodeTitle,
  promoCodeDescription,
  promoCode,
  noMargins,
}) {
  return (
    <CampaignInfo
      noMargins={noMargins}
      title={I18n.t('js.creators.campaigns.show.promo_code_reminder_long')}
      iconComponent={PercentIcon}
      description={
        <>
          {promoCodeTitle && (
            <div className="bold color-dark">{promoCodeTitle}</div>
          )}
          {promoCodeDescription && <div>{promoCodeDescription}</div>}
        </>
      }
      copiableText={promoCode}
      bgColorName="teaLighter"
      textColorName="tea"
    />
  )
}

export { DigitalProductInfo, AffiliateLinkInfo, PromoCodeInfo }
export default CampaignInfo
