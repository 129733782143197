import React, { useContext } from 'react'
import isIn from 'shared/fp/isIn'
import cn from 'classnames'
import useMe from 'creators/useMe'
import { CampaignContext } from '../..'

function StatusText({ textKey }) {
  const {
    campaign: { store },
  } = useContext(CampaignContext)
  const isConsumerCampaign = false
  return (
    <div
      className={cn('padding-10 bottom-10 border-radius-2', {
        'color-bg-green-light':
          textKey |> isIn(['approved', 'shipped', 'reviewing']),
        'color-bg-red-lighter':
          textKey |> isIn(['declined', 'canceled_after_ship', 'expired']),
        'color-bg-gray-lighter':
          textKey
          |> isIn([
            'out_of_stock',
            'no_orders_left',
            'reviewed',
            'ordered',
            'closed',
          ]),
      })}
      dangerouslySetInnerHTML={{
        __html: I18n.t(
          `js.creators.campaigns.show.status.${textKey}${
            textKey === 'approved' && isConsumerCampaign ? '_consumer' : ''
          }`,
          { store: store.title }
        ),
      }}
    />
  )
}

StatusText.IfAny = function () {
  const me = useMe()
  const {
    campaign: { closed, sold_out },
    order,
  } = useContext(CampaignContext)
  let textKey
  if (order && order.status !== 'canceled_before_ship') {
    textKey = order.status
  } else if (closed) {
    textKey = 'closed'
  } else if (sold_out) {
    textKey = 'out_of_stock'
  } else if (me && me.credits === 0) {
    textKey = 'no_orders_left'
  }

  // status vide => pas de order ou order en canceled_before_ship ET pas closed ET pas out of stock ET pas de creator ou avec du credit
  if (!textKey) return null
  return <StatusText textKey={textKey} />
}
StatusText.IfAny.displayName = 'StatusText.IfAny'

export default StatusText
