import React from 'react'

export default function ClassicFlashMessageLayout({ type, message }) {
  return (
    <div id="flash-message">
      <div className={`flash-message-${type}`} style={{ top: 45 }}>
        {message}
      </div>
    </div>
  )
}
