import React from 'react'
import { imagePath } from 'shared/images'
import cn from 'classnames'
import FtcIGIcon from 'assets/images/social-platforms/instagram-black.svg'
import FtcTikTokIcon from 'assets/images/social-platforms/tiktok-black.svg'
import FtcYoutubeIcon from 'assets/images/social-platforms/youtube-black.svg'
import FtcIG from 'assets/images/campaigns/instagram-ftc.png'
import FtcImageVideo from 'assets/images/campaigns/image-video-ftc.png'
import FtcYoutube from 'assets/images/campaigns/youtube-ftc.png'
import HashtagIcon from 'assets/images/campaigns/hashtag-icon.svg'
import AudioIcon from 'assets/images/campaigns/audio-icon.svg'
import TextIcon from 'assets/images/campaigns/text-icon.svg'
import { SkpIcon } from '@skeepers/skeepers-ui-react'
import { faCopy } from '@skeepers/skeepers-icons/solid/faCopy'
import showFlashMessage from 'creators/common/showFlashMessage'

const allowedHashtagsURL =
  'https://skeepers-octoly.zendesk.com/hc/en-us/articles/7353102936988-My-review-is-not-compliant-with-Skeepers-transparency-rules-What-can-I-do'

function getIcon(type) {
  switch (type) {
    case 'audio':
      return AudioIcon
    case 'text':
      return TextIcon
    case 'hashtag':
      return HashtagIcon
  }
}

function FtcRule({ type, message, highlight }) {
  let Icon = getIcon(type)

  return (
    <div className="media pbottom-10 ptop-10 top-0">
      <div className="media-left">
        <Icon className="width-24" />
      </div>
      <div className="media-body">
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <div
          className="pt-10 mb-10 creator-orders"
          onClick={() => {
            navigator.clipboard.writeText(highlight)
            showFlashMessage('notice', I18n.t('js.creators.text_copied'))
          }}
        >
          <span className="label label-hashtag inline-block align-middle cursor-pointer">
            {highlight}
            <SkpIcon
              icon={faCopy}
              size="medium"
              className="badge-inline inline-block align-midle left-8"
            />
          </span>
        </div>
        <span className="font-weight-normal pt-20">
          <a href={allowedHashtagsURL} target="blank" rel="noopener">
            {I18n.t('js.creators.campaigns.order_modal.see_hashtags')}
          </a>
        </span>
      </div>
    </div>
  )
}

export function getFtcHeader(platform) {
  let icon
  let title
  let image
  switch (platform) {
    case 'instagram_reels':
      icon = FtcIGIcon
      title = 'Instagram Reels'
      image = FtcImageVideo
      break
    case 'instagram':
      icon = FtcIGIcon
      title = 'Instagram Post'
      image = FtcIG
      break
    case 'instagram_stories':
      icon = FtcIGIcon
      title = 'Instagram Stories'
      image = FtcImageVideo
      break
    case 'tiktok':
      icon = FtcTikTokIcon
      title = 'TikTok'
      image = FtcImageVideo
      break
    case 'youtube':
      icon = FtcYoutubeIcon
      title = 'Youtube'
      image = FtcYoutube
      break
  }

  return { icon, title, image }
}

function displayFTCRules(rules) {
  const htmlRules = []
  for (let platform in rules) {
    const header = getFtcHeader(platform)
    const Icon = header.icon

    if (!header.icon) continue

    htmlRules.push(
      <div className="container-fluid pleft-0 mb-10" key={platform}>
        <div className="col-sm-12 pleft-0">
          <div className="media mb-0 d-flex align-items-center">
            <div className="media-left pr-8">
              <Icon width="24px" height="auto" />
            </div>
            <div className="media-body pb-4">
              <h5>{header.title}</h5>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 pleft-0">
            <img
              src={header.image}
              width="100%"
              height="auto"
              key={header.image}
            />
          </div>
          <div className="col-sm-6">
            {rules[platform].map(rule => (
              <FtcRule
                key={rule.message}
                type={rule.type}
                message={rule.message}
                highlight={rule.highlight}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  return htmlRules
}

export default function RulesSectionAlt({
  ftc,
  rules,
  logo: { image, brand },
  title,
  bulletImg = imagePath('list_bullet_green.png'),
}) {
  const iconSize = brand ? 32 : 20
  if (!rules?.length && !Object.keys(rules).length) {
    return null
  }

  return (
    <div className="rules-section" key={rules}>
      <div className="media bottom-10 d-flex align-items-baseline">
        <div className="media-left">
          <img
            className={cn({ 'img-circle': brand, bordered: brand })}
            src={image}
            width={iconSize}
            height={iconSize}
          />
        </div>
        <div className="media-body">
          <h5 className="margin-0">{title}</h5>
        </div>
      </div>
      {ftc && displayFTCRules(rules)}
      {!ftc &&
        rules.map((rule, i) => (
          <div key={i} className="media pbottom-10 ptop-10 top-0">
            <div className="media-left">
              <img
                src={bulletImg}
                className="media-object top-3"
                width="12"
                height="12"
              />
            </div>
            <div className="media-body">
              <div dangerouslySetInnerHTML={{ __html: rule }} />
            </div>
          </div>
        ))}
    </div>
  )
}
