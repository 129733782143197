import { useState, useEffect, useCallback } from 'react'
import fetchAPI from 'shared/fetchAPI'
import countriesWithStates from './countriesWithStates'

const useCountryStates = countryCode => {
  // ex. { US: ['Alabama', 'Alaska', ...], ... }
  const [countryStates, setCountryStates] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const fetchStatesFrom = useCallback(async (countryCode, abortController) => {
    setIsFetching(true)
    const data = await fetchAPI(
      `/api/v3/countries/${countryCode}/departments`,
      {
        signal: abortController.signal,
      }
    )
    setCountryStates({ ...countryStates, [countryCode]: data })
    setIsFetching(false)
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    if (
      countriesWithStates.includes(countryCode) &&
      !countryStates[countryCode]
    ) {
      fetchStatesFrom(countryCode, abortController)
    }

    return () => {
      abortController.abort()
    }
  }, [countryCode, countryStates, fetchStatesFrom])

  return { states: countryStates[countryCode] || [], isFetching }
}

export default useCountryStates
