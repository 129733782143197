import { useState } from 'react'
import lowerFirst from 'lodash/lowerFirst'

/*
  USAGE:

  const [
    application,
    {
      setContractId,
      setPlatformAccountIds,
      setPrice,
      setMessage,
    }
  ] = useClassState(
    class {
      constructor() {
        this.state = {
          contractId: undefined,
          platformAccountIds: undefined,
          price: undefined,
          message: undefined,
        }
        if (applicable_contracts.length === 1) {
          this.setContractId(applicable_contracts[0].id)
        }
      }
      setContractId(contractId) {
        this.state = {
          ...this.state,
          contractId,
        }
        if (applicable_contracts |> byId(contractId) |> get('applicable_options') |> every(applicableOption => applicableOption.applicable_platform_accounts.length === 1)) {
          this.setPlatformAccountIds(
            (applicable_contracts |> byId(contractId)).applicable_options.map(
              option => [option.id, { id: option.applicable_platform_accounts[0].id, type: option.applicable_platform_accounts[0].type }]
            )
          )
        }
      }
      setPlatformAccountIds(platformAccountIds) {
        this.state = {
          ...this.state,
          platformAccountIds,
          price: (
            platformAccountIds |> sumBy(
              ([optionId, typedId]) => (me.platform_accounts |> byTypedId(typedId)).platform_entity.paid_campaign_price || 0
            )
          )
        }
      }
      setPrice = true
      setMessage = true
    }
  )
*/

function useClassState(clazz) {
  const [state, setState] = useState(new clazz().state)
  const obj = new clazz()
  obj.state = state
  const actions = {}
  for (const methodName of Object.getOwnPropertyNames(
    Object.getPrototypeOf(obj)
  )) {
    if (methodName === 'constructor') continue
    actions[methodName] = (...args) => {
      obj[methodName](...args)
      setState(obj.state)
    }
  }
  for (const setterPropertyName of Object.getOwnPropertyNames(obj)) {
    if (
      new clazz()[setterPropertyName] === true &&
      setterPropertyName.match(/^set[A-Z]/)
    ) {
      const propertyName = setterPropertyName.replace(/^set/, '') |> lowerFirst
      actions[setterPropertyName] = value => {
        setState({
          ...state,
          [propertyName]: value,
        })
      }
    }
  }
  return [state, actions]
}

export default useClassState
