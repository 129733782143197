import React from 'react'

// usage:
// const MyEnhancedComponent = function() {
//   const { pathname } = useLocation()
// } |> withHOC(BrowserRouter)

export default function withHOC(WrapperComponent) {
  return function (WrappedComponent) {
    return function (props) {
      return (
        <WrapperComponent>
          <WrappedComponent {...props} />
        </WrapperComponent>
      )
    }
  }
}
