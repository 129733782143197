import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { BaseInput } from './BaseInput'
import { InputFeedback } from './InputFeedback'

import Minus from 'assets/images/minus.svg'
import Plus from 'assets/images/plus.svg'

function ControlledStepperInput({
  width = 105,
  height = 30,
  min,
  max,
  name,
  id,
  value,
  onChange,
  error,
  className,
  disabled,
  required,
  innerRef,
  ...props
}) {
  const [valueCheck, setValueCheck] = useState()
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    if (isDirty) setValueCheck(isValueInvalid(value))
  }, [value])

  const onClick = val => {
    const parseValue = parseInt(value) || 0
    const newValue = parseValue + val
    setIsDirty(true)
    onChange(`${newValue}`)
  }

  const onInputChange = e => {
    if (!isNaN(parseInt(e.target.value)) || e.target.value == '') {
      setIsDirty(true)
      onChange(e.target.value)
    }
  }

  const isValueInvalid = value => {
    if (required && !value) {
      return I18n.t('js.errors.blank')
    }

    if (isNaN(parseInt(value))) {
      return I18n.t('js.errors.invalid')
    }

    if (!isNaN(min) && parseInt(value) < min) {
      return I18n.t('js.components.stepperinput.too_low', { min })
    }

    if (!isNaN(max) && parseInt(value) > max) {
      return I18n.t('js.components.stepperinput.too_big', { max })
    }

    return null
  }

  const displayError = error || valueCheck

  return (
    <div className="d-flex-inline flex-column">
      <InputContainer width={width} height={height} className={className}>
        <Button
          size={height}
          onClick={() => onClick(-1)}
          disabled={
            disabled || (!isNaN(min) && (!value || parseInt(value) <= min))
          }
        >
          <Minus />
        </Button>
        <Input
          ref={innerRef}
          type="number"
          name={name}
          id={id || name}
          height={height}
          error={displayError}
          value={value}
          onChange={onInputChange}
          disabled={disabled}
          {...props}
        />
        <Button
          size={height}
          onClick={() => onClick(1)}
          disabled={disabled || (!isNaN(max) && parseInt(value) === max)}
        >
          <Plus />
        </Button>
      </InputContainer>
      {displayError && <InputFeedback type="error" message={displayError} />}
    </div>
  )
}

function UncontrolledStepperInput({ defaultValue = '', ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledStepperInput
      value={value}
      onChange={e => setValue(e.target.value)}
      {...props}
    />
  )
}

export const StepperInput = React.forwardRef((props, ref) => {
  if (props.onChange)
    return <ControlledStepperInput innerRef={ref} {...props} />
  return <UncontrolledStepperInput innerRef={ref} {...props} />
})

const InputContainer = styled.div`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
    display: inline-flex;
  `}
`

const Input = styled(BaseInput)`
  ${({ theme, height }) => css`
    height: ${height}px;
    border-radius: 0;
    font-size: 14px;
    font-family: ${theme.global.fontFamilyRegular};
    text-align: center;
    padding: 0;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  `}
`

const Button = styled.button`
  ${({ theme, size }) => css`
    min-width: ${size}px;
    min-height: ${size}px;
    max-width: ${size}px;
    max-height: ${size}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: ${theme.global.inputs.border};
    border-radius: ${theme.global.inputs.borderRadius};
    background-color: ${theme.global.inputs.backgroundColor};
    transition: ${theme.global.transition};

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      border: ${theme.global.inputs.hover.border};
      border-color: ${theme.global.inputs.hover.borderColor};
      border-width: ${theme.global.inputs.hover.borderWidth};
    }

    &:active {
      border: ${theme.global.inputs.focus.border};
      border-color: ${theme.global.inputs.focus.borderColor};
      border-width: ${theme.global.inputs.focus.borderWidth};
      transform: scale(0.9);
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
      border: ${theme.global.inputs.border} !important;
      transform: none;
    }

    svg {
      width: ${size / 2.5}px;
      height: ${size / 2.5}px;

      path {
        fill: ${theme.global.inputs.color};
      }
    }
  `}
`
