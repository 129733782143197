import React, { createContext, useEffect, useState } from 'react'

export const FacebookSdkContext = createContext()

export function FacebookSdkProvider({ children }) {
  const [isFacebookSdkReady, setIsFacebookSdkReady] = useState(false)

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '330477294603305',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v17.0',
      })
      window.FB.AppEvents.logPageView()

      setIsFacebookSdkReady(true)
    }
    ;(function (d, s, id) {
      var script,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      script = d.createElement(s)
      script.id = id
      script.src = `https://connect.facebook.net/${window.navigator.language}/sdk.js`
      fjs.parentNode.insertBefore(script, fjs)
    })(document, 'script', 'facebook-jssdk')
  }, [])

  return (
    <FacebookSdkContext.Provider value={{ isFacebookSdkReady }}>
      {isFacebookSdkReady && children}
    </FacebookSdkContext.Provider>
  )
}

FacebookSdkContext.displayName = 'FacebookSdkContext'

export default FacebookSdkProvider
