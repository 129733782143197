import React, { useContext } from 'react'
import { OrderContext } from '.'

function MessageBlock() {
  const { message, setMessage } = useContext(OrderContext)
  return (
    <>
      <label htmlFor="message">
        <strong>
          {I18n.t('js.creators.campaigns.order_modal.message_label')}
        </strong>
      </label>
      <textarea
        id="message"
        value={message || ''}
        onChange={e => {
          setMessage(e.target.value || undefined)
        }}
        className="form-control mb-16"
        rows="4"
        placeholder={I18n.t(
          'js.creators.campaigns.order_modal.message_placeholder'
        )}
      ></textarea>
    </>
  )
}

export default MessageBlock
