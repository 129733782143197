import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import cn from 'classnames'
import BlueQuestionMark from 'assets/images/question-mark-blue.svg'

export function QuestionMark({ className, children, id, placement = 'top' }) {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={
        <Popover id={id} className="ff-light">
          {children}
        </Popover>
      }
    >
      <BlueQuestionMark
        className={cn('fa fa-question-circle text-muted fs-12', className)}
      />
    </OverlayTrigger>
  )
}
