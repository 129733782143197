import fetchAPI from 'shared/fetchAPI'
import APP_ISO_CONF from 'shared/appIsoConf'
import Cookies from 'js-cookie'

const CONFIG = {
  refreshInterval: 5 * 60,
  appName: process.env.RAILS_ENV,
}

// TODO: This function removes the old impletation of Unleash proxy data cache.
// Remove this function later when we think all active users local storage have
// been cleared. Also remove "unleash_proxy_cache_name" from APP_ISO_CONF
const removeLocalStorageCache = () => {
  const oldUnleashProxyCache = localStorage.getItem(
    APP_ISO_CONF.unleash_proxy_cache_name
  )

  if (oldUnleashProxyCache) {
    localStorage.removeItem(APP_ISO_CONF.unleash_proxy_cache_name)
  }
}

const getCachedUnleashProxyData = () => {
  removeLocalStorageCache()

  const unleashProxyUrlCache = Cookies.get(
    APP_ISO_CONF.unleash_proxy_url_cache_name
  )
  const unleashProxyKeyCache = Cookies.get(
    APP_ISO_CONF.unleash_proxy_key_cache_name
  )

  const unleashProxyCache = {
    url: unleashProxyUrlCache || '',
    clientKey: unleashProxyKeyCache || '',
  }

  return unleashProxyCache
}

const setCachedUnleashProxyData = ({ url, clientKey }) => {
  Cookies.set(APP_ISO_CONF.unleash_proxy_url_cache_name, url)
  Cookies.set(APP_ISO_CONF.unleash_proxy_key_cache_name, clientKey)
}

const getFreshUnleashProxyData = async () => {
  const config = await fetchAPI(APP_ISO_CONF.api_config_url, {
    useToken: false,
  })

  return config.unleash_proxy
}

export {
  CONFIG,
  getCachedUnleashProxyData,
  setCachedUnleashProxyData,
  getFreshUnleashProxyData,
}
