import React from 'react'
import styled, { css } from 'styled-components'

import { InputFeedback } from './InputFeedback'

import check from 'assets/images/check-checkbox.png'
import { COLORS } from 'shared/scss'

export function _Checkbox(
  { id, name, className, error, label, ...props },
  ref
) {
  return (
    <div className={className}>
      <div css={'position: relative;'}>
        <Input
          ref={ref}
          id={id || name}
          name={name || id}
          type="checkbox"
          label={label ? ' ' : ''}
          {...props}
        />
        <label htmlFor={id || name}>{label}</label>
      </div>
      {error && <InputFeedback type="error" message={error} />}
    </div>
  )
}

export const Checkbox = React.forwardRef(_Checkbox)

const Input = styled.input`
  ${({
    theme,
    disabled,
    labelColor,
    labelFont,
    labelSize,
    checkboxColor,
    width = 16,
    height = 16,
  }) => css`
    position: absolute;
    opacity: 0;

    &:checked + label {
      &:before {
        background-color: ${checkboxColor || COLORS.primary};
        border: ${`1px solid ${checkboxColor || COLORS.primary}`};
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        vertical-align: top;
        width: ${width}px;
        height: ${height}px;
        background-image: url(${check});
        background-position: center;
        background-size: 75%;
        background-repeat: no-repeat;
        left: 0;
        top: 0;
      }
    }

    & + label {
      display: flex;
      position: relative;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      padding: 0;
      margin: 0;
      font-size: ${labelSize ? `${labelSize}px` : '16px'};
      line-height: 20px;
      color: ${labelColor ? COLORS[labelColor] : COLORS.grayDarker};
      opacity: ${disabled ? 0.4 : 1};
      font-family: ${labelFont
        ? theme.global[`fontFamily${labelFont}`]
        : theme.global.fontFamilyRegular};
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        margin-right: ${({ label }) => (label ? '8px' : '0')};
        margin-top: 1px;
        min-height: 16px;
        min-width: 16px;
        opacity: ${disabled ? 0.4 : 1};
        transition: ${theme.global.transition};
        vertical-align: top;
        width: ${width}px;
        height: ${height}px;
        background-color: ${COLORS.white};
        border: ${`1px solid ${COLORS.grayTextLight}`};
        border-radius: ${theme.global.inputs.borderRadius};
        transition: ${theme.global.transition};
        opacity: ${disabled ? 0.4 : 1};
      }

      &:hover {
        &:before {
          border-color: ${disabled
            ? `1px solid ${COLORS.grayTextLight}`
            : checkboxColor || COLORS.primary};
        }
      }
    }
  `}
`
