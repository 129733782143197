import 'shared/appConfig'
import 'react-hot-loader'
import update from 'immutability-helper'
import findIndex from 'lodash/fp/findIndex'
import 'shared/debug/injectWindowObjects.debug'

update.extend('$each', function (patch, arr) {
  return arr.map(item => update(item, patch))
})

update.extend(
  '$renameKey',
  function ([oldName, newName, additionalPatch = undefined], obj) {
    if (oldName in obj) {
      const { [oldName]: value, ...rest } = obj
      return {
        ...rest,
        [newName]: update(value, additionalPatch || {}),
      }
    }
    return obj
  }
)

update.extend('$reduce', function (patches, obj) {
  return patches.reduce((currentObj, patch) => update(currentObj, patch), obj)
})

update.extend('$find', function ([field, value, patch], arr) {
  const index = arr |> findIndex(item => item[field] === value)
  if (index === -1) {
    throw new Error(`Couldn't find item with ${field} ${value}`)
  }
  return update(arr, {
    [index]: patch,
  })
})
