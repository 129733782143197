import React, { useState, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { isSymbolBefore } from 'shared/currency'
import { toCurrency } from 'shared/numbers'
import { CampaignContext } from '../..'
import { OrderContext } from '.'

function PriceBlock() {
  const { price, setPrice } = useContext(OrderContext)
  const {
    campaign: { store },
  } = useContext(CampaignContext)
  const [editPrice, setEditPrice] = useState(false)
  return (
    <div
      style={{ borderTop: '1px #E8EBED solid' }}
      className="ptop-20 pbottom-20"
    >
      <div className="d-flex">
        <h5 className="flex-grow-1">
          {I18n.t('js.creators.campaigns.order_modal.paid_proposal.title')}
        </h5>
        <div className="h5 bold">{toCurrency(price, store.locale)}</div>
      </div>
      <div>
        {I18n.t('js.creators.campaigns.order_modal.paid_proposal.text')}
      </div>
      <div className="top-10">
        {editPrice ? (
          <PriceEdition
            initialPrice={price}
            onCancel={() => {
              setEditPrice(false)
            }}
            onSubmit={setPrice}
          />
        ) : (
          <Button
            bsStyle="default"
            onClick={() => {
              setEditPrice(true)
            }}
          >
            {I18n.t('js.creators.campaigns.order_modal.paid_proposal.edit_cta')}
          </Button>
        )}
      </div>
    </div>
  )
}

function PriceEdition({ onSubmit, onCancel, initialPrice }) {
  const {
    campaign: { store },
  } = useContext(CampaignContext)
  const [value, onChange] = useState(initialPrice.toString())
  return (
    <div className="form-inline">
      <span className="input-group right-20">
        {isSymbolBefore(store.currency.iso_code) && (
          <span className="input-group-addon">{store.currency.symbol}</span>
        )}
        <input
          type="number"
          className="form-control"
          style={{ width: '5em' }}
          value={value}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
        {!isSymbolBefore(store.currency.iso_code) && (
          <span className="input-group-addon">{store.currency.symbol}</span>
        )}
      </span>{' '}
      <button className="btn btn-default" onClick={onCancel}>
        {I18n.t('js.creators.campaigns.order_modal.edit_price.cancel_cta')}
      </button>{' '}
      <button
        className="btn btn-primary"
        onClick={() => {
          onSubmit(parseFloat(value))
        }}
      >
        {I18n.t('js.creators.campaigns.order_modal.edit_price.save_cta')}
      </button>
    </div>
  )
}

export default PriceBlock
