import React from 'react'

export default function nl2br(str) {
  if (typeof str !== 'string') return str
  return str.split('\n').map((line, i, lines) =>
    i === lines.length - 1 ? (
      line
    ) : (
      <React.Fragment key={`${str}_${i}`}>
        {line}
        <br />
      </React.Fragment>
    )
  )
}
