import React from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-bootstrap'
import CampaignCard from 'creators/common/components/CampaignCard'

export default function CampaignList({ campaigns = [], hideTooltips }) {
  return (
    <Container fluid className="bottom-100 top-40">
      <Row>
        {campaigns.map(campaign => (
          <Col key={campaign.id} xs={12} sm={4} md={3}>
            <CampaignCard campaign={campaign} hideTooltips={hideTooltips} />
          </Col>
        ))}
        {campaigns.length === 0 && (
          <h4 className="text-muted text-center">
            {I18n.t('creators.stores.show.no_products_html')}
          </h4>
        )}
      </Row>
    </Container>
  )
}

const Container = styled(Grid)`
  position: relative;
  width: 80vw;
  max-width: 1100px;

  @media (min-width: 768px) {
    h4 {
      margin-bottom: 100px;
    }
  }
`
