import { locals } from '!css-loader!sass-loader!./scssVariables.scss'
import camelCase from 'lodash/camelCase'

export const COLORS = {}
export const BREAKPOINTS = {}

for (const varName in locals) {
  if (varName.includes('color-')) {
    COLORS[varName.replace('color-', '') |> camelCase] = locals[varName]
  }
  if (varName.includes('screen-')) {
    BREAKPOINTS[varName.replace('screen-', '')] =
      locals[varName].replace('px', '') |> parseInt
  }
}

export default { COLORS, BREAKPOINTS }
