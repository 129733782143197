import React, { useState, useEffect } from 'react'
import { FlagProvider, useFlagsStatus } from '@unleash/proxy-client-react'
import { getUserId } from 'shared/modules/userManagement'
import {
  CONFIG,
  getCachedUnleashProxyData,
  setCachedUnleashProxyData,
  getFreshUnleashProxyData,
} from 'shared/modules/unleashProxy'

import useMe from 'creators/useMe'
import { CURRENT_USER as proUser } from 'pro/common/backendValues'
import { AppLoader } from './AppLoader'

const isCacheOutdated = (cache, fetchedData) => {
  const { url: cachedUrl, clientKey: cachedClientKey } = cache
  const { url: freshUrl, clientKey: freshClientKey } = fetchedData

  return cachedUrl !== freshUrl || cachedClientKey !== freshClientKey
}

const FeatureFlagProvider = ({ children }) => {
  const me = useMe()
  const [unleashProxy, setUnleashProxy] = useState(getCachedUnleashProxyData)

  const fetchUnleashProxy = async () => {
    try {
      const unleashProxyFetchedData = await getFreshUnleashProxyData()

      if (isCacheOutdated(unleashProxy, unleashProxyFetchedData)) {
        setUnleashProxy(unleashProxyFetchedData)
        setCachedUnleashProxyData(unleashProxyFetchedData)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchUnleashProxy()
  }, [])

  const { url, clientKey } = unleashProxy

  if (!url || !clientKey) {
    return <AppLoader />
  }

  const userId = getUserId(me, proUser)

  return (
    <FlagProvider config={{ ...CONFIG, ...unleashProxy, context: { userId } }}>
      <FlagStatusWrapper>{children}</FlagStatusWrapper>
    </FlagProvider>
  )
}

const FlagStatusWrapper = ({ children }) => {
  const { flagsReady } = useFlagsStatus()

  return flagsReady ? children : <AppLoader />
}

export default FeatureFlagProvider
