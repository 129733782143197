/*
 * Usage: ['Pierre', 'Paul', 'Jacques'] |> toSentence()
 * returns 'Pierre, Paul et Jacques' (localized)
 */
const toSentence =
  ({ altAnd = false } = {}) =>
  words => {
    const wordsConnector = I18n.t('support.array.words_connector')
    const lastWordConnector = altAnd
      ? ' & '
      : I18n.t('support.array.last_word_connector')
    const twoWordsConnector = altAnd
      ? ' & '
      : I18n.t('support.array.two_words_connector')
    let sentence = ''
    words.forEach((word, i) => {
      if (i > 0) {
        sentence +=
          i === words.length - 1
            ? words.length === 2
              ? twoWordsConnector
              : lastWordConnector
            : wordsConnector
      }
      sentence += word
    })
    return sentence
  }

export default toSentence
