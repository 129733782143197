import React, { useEffect, useRef, useState } from 'react'
import Quill from 'quill'
import MediaModal from './MediaModal'
import './ImageBlot'
import './VideoBlot'

const MEDIA_TYPES = {
  image: {
    type: 'image',
    blot: 'imageBlot',
  },
  video: {
    type: 'video',
    blot: 'videoBlot',
  },
}

const QuillEditor = ({
  s3Config,
  name,
  initialValue = '',
  allowMedia = true,
  onChange,
}) => {
  const quillRef = useRef(null)
  const containerRef = useRef(null)
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false)
  const [selectedMediaType, setSelectedMediaType] = useState('')
  const [textAreaValue, setTextAreaValue] = useState(initialValue)

  useEffect(() => {
    const container = containerRef.current
    const editorContainer = container.appendChild(
      container.ownerDocument.createElement('div')
    )

    const quill = new Quill(editorContainer, {
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'bullet' }, { list: 'ordered' }],
            ['link', ...(allowMedia ? ['image', 'video'] : [])],
            ['clean'],
          ],
          handlers: {
            image: () => mediaHandler(MEDIA_TYPES.image.type),
            video: () => mediaHandler(MEDIA_TYPES.video.type),
          },
        },
      },
    })

    quillRef.current = quill

    quill.clipboard.dangerouslyPasteHTML(initialValue)

    quill.on(Quill.events.TEXT_CHANGE, () => {
      const newValue = quill.root.innerHTML
      setTextAreaValue(newValue)
      onChange?.(newValue)
    })

    // Workaround for easier video deletion:
    // when clicking on a video put cursor immediately after it
    quill.root.addEventListener('click', e => {
      const videoClicked = e.target.closest('.video-blot')
      const paragraphClickedVideos = e.target.querySelectorAll('.video-blot')
      const videoElement =
        videoClicked ||
        paragraphClickedVideos?.[paragraphClickedVideos.length - 1]

      if (videoElement) {
        const videoBlot = Quill.find(videoElement)
        const index = quill.getIndex(videoBlot) + 1
        quill.setSelection(index, 0)
      }
    })

    // Workaround for the Link tooltip not getting cut by Modal boundaries
    const tooltip = document.querySelector('.ql-tooltip')
    const observer = new MutationObserver(() => {
      const xPosition = parseInt(tooltip.style.left, 10)
      if (xPosition < 0) tooltip.style.left = 0
    })
    observer.observe(tooltip, {
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => {
      quillRef.current = null
      container.innerHTML = ''
      observer.disconnect()
    }
  }, [])

  const mediaHandler = async type => {
    setSelectedMediaType(type)
    setIsMediaModalOpen(true)
  }

  const insertMedia = async ({ file, source, width, height }) => {
    try {
      let src = source
      if (file) {
        src = await uploadFileToS3(file, s3Config)
      }

      const range = quillRef.current.getSelection(true)
      const mediaBlotType = MEDIA_TYPES[selectedMediaType].blot

      quillRef.current.insertEmbed(
        range.index,
        mediaBlotType,
        {
          src,
          width,
          height,
        },
        'user'
      )

      // Add newline after video to make it easier to handle and delete
      if (selectedMediaType === MEDIA_TYPES.video.type) {
        quillRef.current.insertText(range.index + 1, '\n')
        quillRef.current.setSelection(range.index + 2, 0)
      }
    } catch (err) {
      console.error(err)
    }
  }

  async function uploadFileToS3(file, s3Config) {
    const body = new FormData()
    body.append('key', `${s3Config.key_start}${file.name}`)
    body.append('Content-Type', file.type)
    body.append('AWSAccessKeyId', s3Config.access_key)
    body.append('policy', s3Config.policy)
    body.append('signature', s3Config.signature)
    body.append('X-Requested-With', 'xhr')
    body.append('success_action_status', '201')
    body.append('acl', 'public-read')
    body.append('file', file)

    const res = await fetch(
      `https://s3-${s3Config.region}.amazonaws.com/${s3Config.bucket}`,
      {
        method: 'POST',
        body,
      }
    )

    const url = new DOMParser()
      .parseFromString(await res.text(), 'application/xml')
      .querySelector('Location').textContent
    return url
  }

  return (
    <>
      <div ref={containerRef} />
      {/* We save the value inside this textarea to be able to use it with SimpleFrom (HAML) */}
      <textarea
        name={name}
        value={textAreaValue}
        readOnly
        style={{ display: 'none' }}
      />
      <MediaModal
        isOpen={isMediaModalOpen}
        mediaType={selectedMediaType}
        onSave={insertMedia}
        onClose={() => setIsMediaModalOpen(false)}
      />
    </>
  )
}

export default QuillEditor
