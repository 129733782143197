import React from 'react'
import { NavItem } from 'react-bootstrap'
import { useRouteMatch, useHistory, useLocation, matchPath } from 'react-router'

function RoutedNavItem({
  to,
  href,
  exact = false,
  strict = false,
  isActive,
  altRoutes = [],
  ...otherProps
}) {
  const dest = href || to
  const outsideNav = !!href
  const match = useRouteMatch({
    path: dest,
    exact,
    strict,
  })
  const history = useHistory()
  const location = useLocation()
  const active =
    typeof isActive === 'function'
      ? isActive(match, location)
      : !!match ||
        altRoutes.some(altRoute => matchPath(location.pathname, altRoute))
  return (
    <NavItem
      {...otherProps}
      href={dest}
      onClick={
        outsideNav
          ? undefined
          : e => {
              e.preventDefault()
              history.push(dest)
            }
      }
      active={active}
    />
  )
}

export default RoutedNavItem
