import React, { useContext } from 'react'
import { CampaignContext } from '..'
import GiftIcon from 'assets/images/gift.svg'

const SmallTextInfo = () => {
  const {
    campaign: {
      store: { title: storeTitle, locale: storeLocale, currency },
      affiliate_link: affiliateLink,
      has_personalized_affiliate_links: hasPersonalizedAffiliateLinks,
      products,
    },
    order = {},
  } = useContext(CampaignContext)

  return (
    <div className="top-10">
      {products?.[0]?.delivery_type == 'physical' && (
        <div>
          <span className="text-muted">
            {I18n.t('js.creators.campaigns.show.shipped_by')}
          </span>
          &nbsp;
          {products?.[0]?.shipping_by_client ? storeTitle : 'Skeepers'}
        </div>
      )}
      {products.map(({ id, title, link: productLink, price }) => {
        let priceText
        if (price)
          priceText = new Intl.NumberFormat(storeLocale, {
            style: 'currency',
            currency: currency.iso_code,
            minimumFractionDigits: 2,
            currencyDisplay: 'symbol',
          }).format(price)

        let linkToDisplay = productLink
        if (
          hasPersonalizedAffiliateLinks &&
          order.status === 'approved' &&
          order.personalized_affiliate_link
        ) {
          linkToDisplay = order.personalized_affiliate_link
        } else if (affiliateLink) {
          linkToDisplay = affiliateLink
        }
        const singleProductMargin = products.length > 1 ? {} : { marginTop: 12 }
        return (
          <React.Fragment key={id}>
            {products.length > 1 && (
              <div className="bold top-12 bottom-3">
                <GiftIcon
                  css={`
                    vertical-align: middle;
                    margin-right: 5px;
                  `}
                />
                {title}
              </div>
            )}
            {linkToDisplay && products.length === 1 && (
              <div style={singleProductMargin} className="text-muted">
                <a
                  className="ellipsis"
                  href={linkToDisplay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {I18n.t('js.creators.campaigns.show.product_link')}
                </a>
              </div>
            )}
            {priceText && (
              <div style={singleProductMargin} className="text-muted">
                <span>{I18n.t('js.creators.campaigns.show.public_price')}</span>
                &nbsp;
                {priceText}
              </div>
            )}
            {linkToDisplay && products.length > 1 && (
              <div style={singleProductMargin} className="text-muted">
                <a
                  className="ellipsis"
                  href={linkToDisplay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {I18n.t('js.creators.campaigns.show.product_view_more')}
                </a>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default SmallTextInfo
