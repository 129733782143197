import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import qs from 'qs'
import Menu from './Menu'
import useBreakpoint from 'shared/useBreakpoint'
import { useLocation } from 'react-router'
import { hot } from 'react-hot-loader/root'
import styled from 'styled-components'

function Layout({ children }) {
  const mobileUI = !useBreakpoint('sm')
  const { search } = useLocation()
  const standalone = !!qs.parse(search, { ignoreQueryPrefix: true }).standalone

  if (standalone) return children

  if (mobileUI) return <div className="padding-16">{children}</div>

  return (
    <Grid className="pbottom-50 ptop-50">
      <Row>
        <Col sm={2}>
          <Menu />
        </Col>
        <Col sm={10}>{children}</Col>
      </Row>
    </Grid>
  )
}

Layout.Box = styled.div.attrs(() => ({ className: 'creators-settings-box' }))``
Layout.Title = styled.h1.attrs(() => ({
  className: 'creators-settings-title',
}))``
Layout.Subtitle = styled.div.attrs(() => ({
  className: 'creators-settings-subtitle',
}))``

export default hot(Layout)
