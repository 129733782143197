import uniq from 'lodash/uniq'
import all from 'lodash/fp/all'
import sortBy from 'lodash/fp/sortBy'
import groupBy from 'lodash/fp/groupBy'

export const PLATFORMS = {
  INSTAGRAM: 'instagram',
  INSTAGRAM_STORIES: 'instagram_stories',
  INSTAGRAM_REELS: 'instagram_reels',
  YOUTUBE: 'youtube',
  TIKTOK: 'tiktok',
  CONSUMER: 'consumer',
}

export const PLATFORMS_LIST = [
  PLATFORMS.INSTAGRAM,
  PLATFORMS.INSTAGRAM_STORIES,
  PLATFORMS.INSTAGRAM_REELS,
  PLATFORMS.YOUTUBE,
  PLATFORMS.TIKTOK,
  PLATFORMS.CONSUMER,
]

export const PLATFORMS_CONFIG = {
  [PLATFORMS.INSTAGRAM]: {
    key: PLATFORMS.INSTAGRAM,
    group: 'influencer',
    useNewAPI: true,
    hasSegmentFilters: true,
  },
  [PLATFORMS.INSTAGRAM_STORIES]: {
    key: PLATFORMS.INSTAGRAM_STORIES,
    group: 'influencer',
    useNewAPI: true,
    hasSegmentFilters: true,
  },
  [PLATFORMS.INSTAGRAM_REELS]: {
    key: PLATFORMS.INSTAGRAM_REELS,
    group: 'influencer',
    useNewAPI: true,
    hasSegmentFilters: true,
  },
  [PLATFORMS.YOUTUBE]: {
    key: PLATFORMS.YOUTUBE,
    group: 'influencer',
    useNewAPI: false,
    hasSegmentFilters: true,
  },
  [PLATFORMS.TIKTOK]: {
    key: PLATFORMS.TIKTOK,
    group: 'influencer',
    useNewAPI: true,
    hasSegmentFilters: true,
  },
  [PLATFORMS.CONSUMER]: {
    key: PLATFORMS.CONSUMER,
    group: 'consumer',
    useNewAPI: false,
    hasSegmentFilters: false,
  },
}

/**
 * USAGE
 * Import lodash `sortBy` method
 * Examples:
 *   const example1 = sortBy(myPlatforms, platformOrder)
 *   const example2 = myPlatforms |> sortBy(platformOrder)
 */

export function platformOrder(platform) {
  const index = PLATFORMS_LIST.indexOf(platform)
  return index === -1 ? PLATFORMS_LIST.length : index
}

export const sortPlatforms = sortBy(platformOrder)

/**
 * useNewAPI('instagram') // true
 */
export function useNewAPI(platform) {
  return !!PLATFORMS_CONFIG[platform]?.useNewAPI
}

/**
 * hasSegmentFilters('instagram') // true
 */
export function hasSegmentFilters(platform) {
  return !!PLATFORMS_CONFIG[platform]?.hasSegmentFilters
}

/**
 * getPlatformGroup('instagram') === 'influencer' // true!
 */
export function getPlatformGroup(platform) {
  if (PLATFORMS_CONFIG[platform]?.group) return PLATFORMS_CONFIG[platform].group
  if (platform === 'influencer') return 'influencer'
  throw new Error(`Invalid platform ${platform}`)
}

// Examples:
// 'instagram' |> isInfluencer // => true
export const isInfluencer = platform =>
  getPlatformGroup(platform) === 'influencer'
export const isConsumer = platform => getPlatformGroup(platform) === 'consumer'

// Examples:
// ['instagram', 'consumer', 'youtube'] |> onlyInfluencer // => ['instagram', 'youtube']
// ['instagram', 'consumer', 'youtube'] |> onlyOfGroup('consumer') // => ['consumer']
export const onlyOfGroup = platformGroup => platforms =>
  platforms.filter(platform => getPlatformGroup(platform) === platformGroup)
export const onlyInfluencer = platforms => platforms.filter(isInfluencer)
export const onlyConsumer = platforms => platforms.filter(isConsumer)

// ['instagram', 'consumer', 'youtube'] |> areAllInfluencer // => false
// ['instagram', 'youtube'] |> areAllInfluencer // => true
export const areAllInfluencer = platforms => platforms |> all(isInfluencer)
export const areAllConsumer = platforms => platforms |> all(isConsumer)

// ['instagram', 'consumer', 'youtube'] |> hasInfluencer // => true
// ['instagram', 'tiktok'] |> hasConsumer // => false
export const hasInfluencer = platforms => platforms.some(isInfluencer)
export const hasConsumer = platforms => platforms.some(isConsumer)

/**
 * @param {(Array.<string>|Array.<Array.<string>>)} platforms The platforms of the creators we're talking about:
 *   - if it's an array of strings [platform1, platform2], it reads (platform1 OR platform2)
 *   - if it's an array of arrays of strings [[platform1, platform2], [platform3, platform4]], it reads: (platform1 AND platform2) OR (platform3 AND platform4)
 * @returns The creator key to use.
 * Examples:
 *   - getCreatorTermKey(['instagram']) // 'influencer'
 *   - getCreatorTermKey(['consumer']) // 'consumer'
 *   - getCreatorTermKey(['instagram', 'consumer']) // => "creators that have instagram OR consumer" => 'creator'
 *   - getCreatorTermKey([['instagram', 'consumer'], ['youtube', 'consumer']]) // => "creators that have (instagram and consumer) OR (youtube and consumer)" => 'influencer' (because influencers are also consumers)
 *   - getCreatorTermKey([['instagram', 'consumer'], ['consumer']]) // => "creators that have (instagram and consumer) OR (consumer)" => 'creator'
 */
export function getCreatorTermKey(platforms, operator = 'OR') {
  const platformGroups =
    platforms.map(platformOrPlatforms =>
      Array.isArray(platformOrPlatforms)
        ? getCreatorTermKey(platformOrPlatforms, 'AND')
        : getPlatformGroup(platformOrPlatforms)
    ) |> uniq
  if (platformGroups.length === 0) return 'creator'
  if (platformGroups.length === 1) return platformGroups[0]
  return operator === 'OR' ? 'creator' : 'influencer' // because influencers are also consumers!
}

export function getCreatorTerm({ platforms = [], count = 0 }) {
  return I18n.t(`global.${getCreatorTermKey(platforms)}_term`, { count })
}

export function getOrigin(platform) {
  return platform.includes('instagram') ? 'instagram' : platform
}

// Returns true if the platform is an instagram platform, false otherwise
export function isInstagramPlatform(platform) {
  return (platform |> getOrigin) === 'instagram'
}

// Returns true if all the platforms are instagram platforms, false otherwise
export function isInstagramPlatformGroup(platforms) {
  return platforms.every(isInstagramPlatform)
}

/**
 * Groups platforms in arrays.
 * ex. ["instagram", "tiktok", "instagram_stories"]
 * returns [["instagram", "instagram_stories"], ["tiktok"]]
 */
export function getGroupedPlatforms(platforms) {
  return (
    platforms
    |> groupBy(getOrigin)
    |> Object.values
    |> sortBy(groupPlatforms => platformOrder(groupPlatforms[0]))
  )
}

/**
 * Returns "instagram" if all platforms in the array are instagram platforms,
 * otherwise returns the first platform in the array.
 */
export function getPlatformsGroupName(platforms) {
  return getOrigin(platforms[0])
}

/**
 * Segment keys are named like: instagram_*** | instagram_reels_*** | instagram_stories_***
 * The problem is that when we search keys for coincidences with "instagram", it returns
 * also the keys for reels and stories. This function is useful as a filter to avoid this.
 */
export function keyOnlyIncludesPlatform(key, platform) {
  if (platform === 'instagram') {
    return (
      key.includes('instagram_') &&
      !key.includes('instagram_reels_') &&
      !key.includes('instagram_stories_')
    )
  }
  return key.includes(`${platform}_`)
}
