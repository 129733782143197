import React, { useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { selectVariantStyles } from 'shared/reactSelectStyles'
import { InputFeedback } from './InputFeedback'
import styled from 'styled-components'

const OneLineValueContainer = ({ children, ...props }) => {
  const {
    hasValue,
    getValue,
    selectProps: { valueContainerLabel },
  } = props

  if (hasValue) {
    const input = children[1]
    const values = getValue()
    const newValue =
      values.length === 1 ? values[0].label : valueContainerLabel(values.length)

    return (
      <components.ValueContainer {...props}>
        <EllipsisWrapper>{newValue}</EllipsisWrapper>
        {input}
      </components.ValueContainer>
    )
  }

  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  )
}

const EllipsisWrapper = styled.div`
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

// TODO: Handle multiple values
function ControlledSelectInput({
  className,
  id,
  name,
  isMulti = false,
  isSearchable = false,
  openMenuOnFocus = true,
  error,
  disabled,
  innerRef,
  variant,
  hasOneLineValue,
  ...props
}) {
  const Component = props.loadOptions ? AsyncSelect : Select
  const variantStyles = useMemo(() => selectVariantStyles(variant), [variant])
  return (
    <div className={className}>
      <Component
        openMenuOnFocus={openMenuOnFocus}
        ref={innerRef}
        id={id || name}
        name={name || id}
        aria-label={name || id}
        aria-labelledby={name || id}
        isMulti={isMulti}
        isSearchable={isSearchable}
        styles={variantStyles}
        isDisabled={disabled}
        error={error}
        classNamePrefix="react-select"
        {...(hasOneLineValue && {
          components: { ValueContainer: OneLineValueContainer },
        })}
        {...props}
      />
      {error && <InputFeedback type="error" message={error} />}
    </div>
  )
}

function UncontrolledSelectInput({ defaultValue, ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledSelectInput
      value={value}
      onChange={value => setValue(value)}
      {...props}
    />
  )
}

export const SelectInput = React.forwardRef((props, ref) => {
  if (props.onChange) return <ControlledSelectInput innerRef={ref} {...props} />
  return <UncontrolledSelectInput innerRef={ref} {...props} />
})
