import React, { useState, useEffect, useContext } from 'react'
import { CampaignContext } from '../..'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import useMe from 'creators/useMe'
import OrderButton from './OrderButton'
import RulesButton from './RulesButton'
import Contracts from './Contracts'
import StatusText from './StatusText'
import CampaignOptions from './CampaignOptions'
import {
  DigitalProductInfo,
  PromoCodeInfo,
  PersonalizedAffiliateLinksInfo,
  PaidCampaignInfo,
  PreApprovedInfo,
} from './campaignFeatures'

const MainBlock = () => {
  const {
    campaign: { closed, sold_out, products },
    order,
    proPreview,
    landing,
    selectedOptionIds,
  } = useContext(CampaignContext)
  const me = useMe()
  const [showSelectOptionError, setShowSelectOptionError] = useState(false)

  useEffect(() => {
    if (selectedOptionIds) {
      setShowSelectOptionError(false)
    }
  }, [selectedOptionIds])

  const canOrder =
    !closed &&
    !sold_out &&
    !!me &&
    me.credits > 0 &&
    (!order || order.status === 'canceled_before_ship')

  const atLeastOneProductWithOption =
    products.filter(
      product => product.options.filter(option => option.cpo).length > 1
    ).length > 0

  if (landing)
    return (
      <Box>
        <DigitalProductInfo.IfAny />
        <div
          className="bottom-20"
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              'js.landing_pages.campaign.apply_now_block_highlight_html'
            ),
          }}
        />
        <Button
          href="/creators/sign_up"
          bsStyle="primary"
          block
          className="order-campaign"
        >
          {I18n.t('js.creators.campaigns.show.register_and_order')}
        </Button>
        <StatusText.IfAny />
      </Box>
    )

  return (
    <Box>
      <DigitalProductInfo.IfAny />
      <PromoCodeInfo.IfAny />
      <PersonalizedAffiliateLinksInfo.IfAny />
      <PaidCampaignInfo.IfAny />

      <StatusText.IfAny />

      {(canOrder || proPreview) && (
        <>
          <Contracts />
          <OrderContainer>
            {atLeastOneProductWithOption &&
              products.map(product => (
                <CampaignOptions
                  key={product.id}
                  productId={product.id}
                  showSelectOptionError={
                    showSelectOptionError && !selectedOptionIds[product.id]
                  }
                />
              ))}
            <div className="ptop-5">
              {proPreview ? (
                <RulesButton />
              ) : (
                <OrderButton
                  onMissingOptionError={() => {
                    setShowSelectOptionError(true)
                  }}
                />
              )}
            </div>
          </OrderContainer>
        </>
      )}

      <PreApprovedInfo.IfAny />
    </Box>
  )
}

const Box = styled.div.attrs(() => ({ className: 'box' }))`
  > *:first-child {
    margin-top: 0;
  }
`

const OrderContainer = styled.div`
  margin-top: 15px;
`

export default MainBlock
