// error is an error object as of react-hook-form library, shape: { type, message }
export default function getErrorMessage(error) {
  if (error.message) return error.message
  if (error.type === 'required')
    return I18n.t('js.forms.validation_errors.field_required')
  if (error.type === 'invalidFormat')
    return I18n.t('js.forms.validation_errors.invalid_format')
  if (error.type === 'minLength')
    return I18n.t('js.forms.validation_errors.too_short')
  return I18n.t('js.forms.validation_errors.other')
}
