import React, { useContext } from 'react'
import { imagePath } from 'shared/images'
import { CampaignContext } from '../..'
import PercentIcon from 'assets/images/percent.svg'
import LinkIcon from 'assets/images/link.svg'
import styled from 'styled-components'

function DigitalProductInfo() {
  return (
    <SmallInfo color="orange" icon={PercentIcon}>
      {I18n.t('js.creators.campaigns.show.digital_product_reminder')}
    </SmallInfo>
  )
}
DigitalProductInfo.IfAny = function () {
  const {
    campaign: { products },
  } = useContext(CampaignContext)
  if (products?.[0]?.delivery_type === 'digital') {
    return <DigitalProductInfo />
  }
  return null
}
DigitalProductInfo.IfAny.displayName = 'DigitalProductInfo.IfAny'

function PromoCodeInfo() {
  return (
    <SmallInfo color="tea" icon={PercentIcon}>
      {I18n.t('js.creators.campaigns.show.promo_code_reminder')}
    </SmallInfo>
  )
}
PromoCodeInfo.IfAny = function () {
  const {
    campaign: { has_promo_code },
  } = useContext(CampaignContext)
  if (has_promo_code) {
    return <PromoCodeInfo />
  }
  return null
}
PromoCodeInfo.IfAny.displayName = 'PromoCodeInfo.IfAny'

function PersonalizedAffiliateLinksInfo() {
  return (
    <SmallInfo color="tea" icon={LinkIcon}>
      {I18n.t('js.creators.campaigns.show.affiliate_link_reminder')}
    </SmallInfo>
  )
}
PersonalizedAffiliateLinksInfo.IfAny = function () {
  const {
    campaign: { has_personalized_affiliate_links },
  } = useContext(CampaignContext)
  if (has_personalized_affiliate_links) {
    return <PersonalizedAffiliateLinksInfo />
  }
  return null
}
PersonalizedAffiliateLinksInfo.IfAny.displayName =
  'PersonalizedAffiliateLinksInfo.IfAny'

function PaidCampaignInfo() {
  return (
    <SmallInfoContainer>
      <PaidCampaign>
        {I18n.t(`js.creators.free_store.campaign_card.labels.is_paid`)}
      </PaidCampaign>
    </SmallInfoContainer>
  )
}
PaidCampaignInfo.IfAny = function () {
  const {
    campaign: { is_paid },
  } = useContext(CampaignContext)
  if (is_paid) {
    return <PaidCampaignInfo />
  }
  return null
}
PaidCampaignInfo.IfAny.displayName = 'PaidCampaignInfo.IfAny'

function PreApprovedInfo() {
  return (
    <div>
      <hr />
      <img
        className="pull-right top-10"
        src={imagePath('bolt.svg')}
        width="42"
        height="42"
      />
      <strong>{I18n.t('js.creators.campaigns.show.pre_approved_title')}</strong>
      <br />
      {I18n.t('js.creators.campaigns.show.pre_approved_text')}
    </div>
  )
}
PreApprovedInfo.IfAny = function () {
  const {
    campaign: { applicability: { pre_approved } = {} },
  } = useContext(CampaignContext)
  if (pre_approved) {
    return <PreApprovedInfo />
  }
  return null
}
PreApprovedInfo.IfAny.displayName = 'PreApprovedInfo.IfAny'

function SmallInfo({ color, icon: Icon, children }) {
  return (
    <SmallInfoContainer className={`color-${color} d-flex align-items-center`}>
      <Icon className="right-5 flex-shrink-0" width={20} height={20} />
      {children}
    </SmallInfoContainer>
  )
}

const SmallInfoContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

const PaidCampaign = styled.div`
  display: inline-block;
  background-color: #fe504c;
  color: #fff;
  padding: 0.1rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9em;
`

export {
  DigitalProductInfo,
  PromoCodeInfo,
  PersonalizedAffiliateLinksInfo,
  PaidCampaignInfo,
  PreApprovedInfo,
}
