import React, { useContext } from 'react'
import styled from 'styled-components'
import FollowButton from 'creators/common/components/FollowButton'
import { CampaignContext } from '..'
import { useQuery } from 'react-apollo-hooks'
import gql from 'graphql-tag'
import useMe from 'creators/useMe'

const StoreCard = () => {
  const {
    campaign: {
      store: { id, title, logo_urls },
    },
  } = useContext(CampaignContext)

  const logged = !!useMe()

  return (
    <div className="box d-flex flex-column">
      <div className="d-flex align-items-center">
        <Img
          className="img-circle img-circle-bordered"
          src={logo_urls.medium}
          alt={title}
          width="40"
          height="40"
        />
        <a className="flex-grow-1" href={`/creators/stores/${id}`}>
          {title}
        </a>
        {logged && <EnhancedFollowButton storeId={id} />}
      </div>
      {logged && (
        <Legend className="color-gray-text-light font-light">
          {I18n.t('js.creators.campaigns.show.store.legend')}
        </Legend>
      )}
    </div>
  )
}

function EnhancedFollowButton({ storeId }) {
  const { data, loading, error } = useQuery(
    gql`
      query StoreFollow($storeId: Id!) {
        me {
          id
          store_follow(store_id: $storeId) {
            id
          }
        }
      }
    `,
    { variables: { storeId } }
  )
  if (loading || error) return null
  return (
    <FollowButton
      storeId={storeId}
      storeFollowed={!!data.me.store_follow}
      source="product_page"
    />
  )
}

/**
 * STYLED COMPONENTS
 */
const Img = styled.img`
  flex: none;
  margin-right: 10px;
`

const Legend = styled.div`
  margin-top: 20px;
  background-color: white;
  padding: 14px;
  border: 1px solid #e8ebed;
  border-radius: 4px;
  position: relative;
  filter: drop-shadow(0px 0px 10px #e6e6e6);

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:after {
    top: -31px;
    right: 25px;
    border-color: transparent transparent white transparent;
    border-width: 16px;
  }

  &:before {
    top: -32.5px;
    right: 25px;
    border-color: transparent transparent #e8ebed transparent;
    border-width: 16px;
  }
`

export default StoreCard
