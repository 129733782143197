import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router'

import { imagePath } from 'shared/images'

export default function AddSocialAccountModal({ show, onHide }) {
  const history = useHistory()
  const onClick = () => {
    history.push(`/creators/settings/accounts`)
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} bsSize="sm">
      <Modal.Header onHide={onHide} closeButton />
      <Modal.Body className="align-items-center flex flex-column ptop-20">
        <img
          src={imagePath('creators/social_account.png')}
          className="block bottom-20"
          style={{ maxWidth: '120px' }}
        />
        <h3 className="text-muted text-center font-light">
          {I18n.t('js.creators.alerts.add_social_account.description')}
        </h3>
      </Modal.Body>
      <Modal.Footer className="align-items-center flex flex-column">
        <Button bsStyle="primary" onClick={onClick}>
          {I18n.t('js.creators.alerts.add_social_account.cta')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
