import React from 'react'
import styled, { css } from 'styled-components'

import { InputFeedback } from './InputFeedback'
import { COLORS } from 'shared/scss'

export function _Radio(
  {
    id,
    name,
    className,
    value,
    checked,
    onChange,
    error,
    label,
    disabled,
    ...props
  },
  ref
) {
  return (
    <div className={className}>
      <Input
        id={id}
        name={name}
        type="radio"
        ref={ref}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        label={label}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
      {error && <InputFeedback type="error" message={error} />}
    </div>
  )
}

export const Radio = React.forwardRef(_Radio)

const Input = styled.input`
  ${({ theme, disabled, label, labelColor, labelFont, labelMargin = 8 }) => css`
    position: absolute;
    opacity: 0;

    &:checked + label {
      &:before {
        background-color: ${COLORS.primary};
        border: ${`1px solid ${COLORS.primary}`};
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        vertical-align: top;
        width: 8px;
        height: 8px;
        background-color: ${COLORS.white};
        left: 4px;
        top: 4px;
        border-radius: 50%;
      }
    }

    & + label {
      display: flex;
      position: relative;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      color: ${labelColor ? COLORS[labelColor] : COLORS.grayDarker};
      font-family: ${labelFont
        ? theme.global[`fontFamily${labelFont}`]
        : theme.global.fontFamilyRegular};
      opacity: ${disabled ? 0.4 : 1};

      &:before {
        content: '';
        margin-right: ${label ? `${labelMargin}px` : '0'};
        display: inline-block;
        vertical-align: top;
        min-width: 16px;
        max-width: 16px;
        height: 16px;
        background-color: ${COLORS.white};
        border: ${`1px solid ${COLORS.grayTextLight}`};
        border-radius: 50%;
        transition: ${theme.global.transition};
        opacity: ${disabled ? 0.4 : 1};
      }

      &:hover {
        &:before {
          border-color: ${disabled
            ? `1px solid ${COLORS.grayTextLight}`
            : COLORS.primary};
        }
      }
    }
  `}
`
