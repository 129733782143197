import { useEffect, useRef } from 'react'

function useComponentWillMount(callback) {
  const mounted = useRef(false)

  if (!mounted.current) callback()

  useEffect(() => {
    mounted.current = true
  }, [])
}

export default useComponentWillMount
