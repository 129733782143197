export const isSymbolBefore = currencyIsoCode => {
  const formatted = new Intl.NumberFormat(I18n.locale, {
    style: 'currency',
    currency: currencyIsoCode,
    minimumFractionDigits: 0,
    currencyDisplay: 'code',
  }).format(1)
  return formatted.indexOf(currencyIsoCode) < formatted.indexOf('1')
}

export const toCurrency = (value, locale) =>
  value?.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'currency',
    currency: locale == 'en-US' ? 'USD' : 'EUR',
  })
