import React, { useState, useContext, useRef, useCallback } from 'react'
import get from 'lodash/fp/get'
import flatMap from 'lodash/fp/flatMap'
import byId from 'shared/fp/byId'
import byTypedId from 'shared/fp/byTypedId'
import Conditions from './Conditions'
import { ControlledButtonWithLoader as ButtonWithLoader } from 'shared/components/ButtonWithLoader'
import { CampaignContext } from '../..'
import PriceBlock from './PriceBlock'
import MessageBlock from './MessageBlock'
import ShippingSummary from './ShippingSummary'
import { OrderContext } from '.'
import { SelfFetch as CampaignRulesList } from 'creators/common/components/CampaignRulesList'

function OrderSummary() {
  const {
    campaign,
    campaign: {
      is_paid,
      contracts,
      applicability: { applicable_contracts },
      eligible_mondial_relay: isEligibleMondialRelay,
    },
  } = useContext(CampaignContext)
  const { contractId, platformAccountIds, submitOrder } =
    useContext(OrderContext)
  const [deliveryType, setDeliveryType] = useState(
    isEligibleMondialRelay ? 'mondial_relay' : 'home'
  )
  const memoizedSetDeliveryType = useCallback(deliveryTypeValue => {
    setDeliveryType(deliveryTypeValue)
  }, [])
  const [mondialRelayChoice, setMondialRelayChoice] = useState(undefined)
  const memoizedSetMondialRelayChoice = useCallback(mondialRelayChoiceValue => {
    setMondialRelayChoice(mondialRelayChoiceValue)
  }, [])
  const [loading, setLoading] = useState(false)
  const [addressChecked, setAddressChecked] = useState(false)
  const [error, setError] = useState({ address: false })
  const conditionsRef = useRef()

  const fullyPreApproved = !platformAccountIds.some(
    ([optionId, typedId]) =>
      !(
        applicable_contracts
        |> flatMap('applicable_options')
        |> byId(optionId)
        |> get('applicable_platform_accounts')
        |> byTypedId(typedId)
        |> get('pre_approved')
      )
  )

  const messageRelevant =
    !fullyPreApproved &&
    (contracts |> byId(contractId)).options.some(
      option => option.platform !== 'consumer'
    )

  const orderCampaign = () => {
    let valid = true

    if (!addressChecked && deliveryType === 'home') {
      setError({ address: true })
      valid = false
    }

    if (!conditionsRef.current.allChecked()) {
      conditionsRef.current.showMissingConditionsError()
      valid = false
    }

    if (valid) {
      setLoading(true)
      return submitOrder(mondialRelayChoice)
    }
  }

  return (
    <>
      {messageRelevant && <MessageBlock />}
      <CampaignRulesList
        campaign={campaign}
        platforms={(contracts |> byId(contractId)).options.map(
          ({ platform }) => platform
        )}
        noRules
      />
      <ShippingSummary
        addressChecked={addressChecked}
        setAddressChecked={setAddressChecked}
        error={error}
        setError={setError}
        setDeliveryType={memoizedSetDeliveryType}
        deliveryType={deliveryType}
        setMondialRelayChoice={memoizedSetMondialRelayChoice}
        mondialRelayChoice={mondialRelayChoice}
        isEligibleMondialRelay={isEligibleMondialRelay}
      />
      {is_paid && <PriceBlock />}
      <Conditions conditionsRef={conditionsRef} />
      <div className="top-20">
        <ButtonWithLoader
          bsStyle="primary"
          className="btn-responsive btn-order-campaign"
          onClick={orderCampaign}
          loading={loading}
          disabled={
            deliveryType === 'mondial_relay' && mondialRelayChoice === undefined
          }
        >
          {is_paid
            ? I18n.t(
                'js.creators.campaigns.order_modal.order_paid_product_no_amount'
              )
            : I18n.t('js.creators.campaigns.order_modal.order_product')}
        </ButtonWithLoader>
      </div>
    </>
  )
}

export default OrderSummary
