import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap'

const Group = ({ label, children, error, ...rest }) => (
  <FormGroup
    style={{ width: '100%' }}
    validationState={error ? 'error' : null}
    {...rest}
  >
    <ControlLabel>{label}</ControlLabel>
    {children}
    {error ? (
      <HelpBlock className="has-error">{error.message}</HelpBlock>
    ) : undefined}
  </FormGroup>
)

Group.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  error: PropTypes.object,
}

export default Group
