import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useForm, Controller, FormContext } from 'react-hook-form'
import { Modal, Form } from 'react-bootstrap'
import fetchAPI, { APIError, FetchError } from 'shared/fetchAPI'
import { Button } from 'shared/components/UIkit'
import showFlashMessage from 'creators/common/showFlashMessage'
import redirectWithFlashMessage from 'shared/redirectWithFlashMessage'
import OSelect from 'shared/components/OSelect'
import Group from 'shared/components/Form/Group'
import SimpleDateSelector from 'shared/components/SimpleDateSelector'
import { useFacebookSdk } from 'shared/useFacebookSdk'
import Loader from 'shared/components/Loader'
import { COLORS } from 'shared/scss'
import Facebook from 'assets/images/social-platforms/facebook.svg'
import { useFlag } from '@unleash/proxy-client-react'
import { handleSignupBypassSearchParams } from './handlers/SearchParamsHandler'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

export default function FacebookButton({ availableCountries, onBusy }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [userData, setUserData] = useState()
  const form = useForm()
  const { register, handleSubmit } = form
  const [isFacebookSdkReady] = useFacebookSdk()
  const { FB } = window
  const isFunnelByPassFeatureFlagEnabled = useFlag('ff_funnel_bypass')
  const { search } = useLocation()
  const queries = qs.parse(search, { ignoreQueryPrefix: true })

  const handleFailOrCancel = () => {
    FB.logout()
    setIsLoading(false)
    setIsOpen(false)
    setIsSubmitting(false)
  }

  const onClick = () => {
    setIsLoading(true)
    const bypass = {
      bypass: handleSignupBypassSearchParams(queries),
    }
    FB.login(
      response => {
        if (response.status === 'connected') {
          FB.api('/me/permissions', permissions => {
            const allGranted = permissions.data
              .filter(({ permission }) =>
                ['email', 'public_profile'].includes(permission)
              )
              .every(({ status }) => status === 'granted')

            if (allGranted) {
              // NOTE: Just in case if one day we have full access to the API: { fields: 'email,first_name,last_name,birthday,location{location}' }
              FB.api(
                '/me',
                { fields: 'email,first_name,last_name' },
                async data => {
                  const { email, first_name, last_name } = data

                  const dataToCheck = [email, first_name, last_name]
                  const hasRequiredData = dataToCheck.every(item => !!item)

                  if (hasRequiredData) {
                    // By sending user's Facebook account data, we check if the user is already registered in our platform. If yes, we just log them in.
                    try {
                      let payload = {
                        ...data,
                        access_token: response.authResponse.accessToken,
                      }

                      if (isFunnelByPassFeatureFlagEnabled) {
                        payload = {
                          ...payload,
                          ...bypass,
                        }
                      }

                      await fetchAPI('/api/v3/creators/facebook_login', {
                        method: 'POST',
                        body: payload,
                      })
                      window.location.assign('/creators/campaigns')
                    } catch (e) {
                      // If the id of the user doesnt match the access token
                      if (
                        e.status === 400 &&
                        e.message === 'verification_failed'
                      ) {
                        showFlashMessage('error', I18n.t('js.global.error'))
                        handleFailOrCancel()
                        return
                      }
                      // If the user is not registered, we open the modal to get birthday and country since we don't have the complete Facebook API to do it automatically.
                      if (
                        (e instanceof FetchError &&
                          [400, 401].includes(e.response.status)) ||
                        (e instanceof APIError && [400, 401].includes(e.status))
                      ) {
                        setUserData({
                          ...data,
                          access_token: response.authResponse.accessToken,
                        })
                        setIsOpen(true)
                      } else {
                        showFlashMessage('error', I18n.t('js.global.error'))
                        handleFailOrCancel()
                      }
                    }
                  } else {
                    showFlashMessage(
                      'notice',
                      I18n.t(
                        'js.creators_registration.facebook_connect.error.missing_data'
                      )
                    )
                    handleFailOrCancel()
                  }
                }
              )
            } else {
              showFlashMessage(
                'notice',
                I18n.t(
                  'js.creators_registration.facebook_connect.error.missing_permissions'
                )
              )
              handleFailOrCancel()
            }
          })
        } else if (response.status === 'not_authorized') {
          handleFailOrCancel()
        } else {
          setIsLoading(false)
        }
        // NOTE: Just in case if one day we have full access to the API: { scope: 'public_profile,email,user_birthday,user_location' }
      },
      { scope: 'public_profile,email', auth_type: 'rerequest' }
    )
  }

  const onSubmit = async fields => {
    setIsSubmitting(true)
    let data = {
      ...userData,
      ...fields,
      birth_date: fields.birth_date.format('YYYY-MM-DD'),
    }

    if (isFunnelByPassFeatureFlagEnabled) {
      data = {
        ...data,
        bypass: handleSignupBypassSearchParams(queries),
      }
    }

    try {
      await fetchAPI('/api/v3/creators/facebook_login', {
        method: 'POST',
        body: data,
      })
      window.location.assign('/creators/campaigns')
    } catch (e) {
      handleFailOrCancel()

      if (e instanceof APIError) {
        const apiErrors = e.asObject()
        if ('email_taken' in apiErrors) {
          return redirectWithFlashMessage(`/login`, {
            type: 'notice',
            key: 'js.signin.already_have_an_account_please_login',
          })
        }
        if ('already_has_an_account' in apiErrors) {
          return redirectWithFlashMessage(`/login`, {
            type: 'error',
            key: 'js.signin.suspected_duplicated_creator',
          })
        }
        if ('birth_date_too_young' in apiErrors) {
          return showFlashMessage(
            'notice',
            I18n.t('js.creators_registration.form.validation_errors.too_young')
          )
        }
        if ('invalid_country' in apiErrors) {
          return showFlashMessage(
            'notice',
            I18n.t('js.creators.alerts.country_not_open.title')
          )
        }
        showFlashMessage('error', I18n.t('js.global.error'))
      }
    }
  }

  useEffect(() => {
    if (FB) setIsLoading(false)
  }, [FB])

  useEffect(() => {
    onBusy(isSubmitting)
  }, [isSubmitting])

  return (
    <>
      <FbButton onClick={onClick} disabled={!isFacebookSdkReady}>
        {!isFacebookSdkReady || isLoading ? (
          <Loader inline />
        ) : (
          <>
            <Facebook />
            {I18n.t('js.creators_registration.facebook_connect.cta')}
          </>
        )}
      </FbButton>
      <Modal show={isOpen} onHide={handleFailOrCancel} bsSize="sm">
        <Modal.Body className="align-items-center flex flex-column">
          <h1 className="fs-28 lh-28 ff-bold mt-24 mb-8 text-center tc-grayDarker">
            {I18n.t('js.creators_registration.facebook_connect.modal.title')}
          </h1>
          <h4 className="fs-16 lh-19 ff-regular m-0 text-center tc-grayDarker">
            {I18n.t('js.creators_registration.facebook_connect.modal.subtitle')}
          </h4>
          <div className="mt-36">
            <FormContext {...form}>
              <Form
                id="facebook-login-flow-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Group label={I18n.t('js.creators.settings.edit.country')}>
                  <Controller
                    name="country_code"
                    defaultValue={window.navigator.language.split('-')[1]}
                    as={
                      <OSelect
                        options={availableCountries.map(country => ({
                          value: country.alpha2,
                          label: country.name,
                        }))}
                        innerRef={() => register({ required: true })}
                        name="country_code"
                      />
                    }
                  />
                </Group>
                <Group label={I18n.t('js.creators.settings.edit.birth_date')}>
                  <div className="form-inline">
                    <Controller
                      as={
                        <SimpleDateSelector
                          name="birth_date"
                          dateFormatL={
                            window.navigator.language === 'en-US'
                              ? 'MM/DD/YYYY'
                              : 'DD/MM/YYYY'
                          }
                        />
                      }
                      name="birth_date"
                      defaultValue={moment()}
                      required
                    />
                  </div>
                </Group>
                <Button
                  block
                  type="submit"
                  className="mt-24"
                  loading={isSubmitting}
                >
                  {I18n.t(
                    'js.creators_registration.facebook_connect.modal.cta'
                  )}
                </Button>
              </Form>
            </FormContext>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const FbButton = styled.button`
  align-items: center;
  background-color: #4267b2;
  border-radius: 4px;
  border: none;
  color: white;
  display: flex;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  height: 42px;
  justify-content: center;
  line-height: normal;
  padding: 0;
  width: 100%;

  svg {
    margin-right: 8px;
  }
`

export const ContinueWithFacebook = ({ availableCountries, onBusy }) => (
  <div css="width: 320px; margin: 16px auto 0;">
    <FacebookButton availableCountries={availableCountries} onBusy={onBusy} />
    <Separator>
      {I18n.t('js.creators_registration.facebook_connect.or')}
    </Separator>
  </div>
)

const Separator = styled.div`
  align-items: center;
  color: ${COLORS.neutral900};
  display: flex;
  font-size: 10px;
  line-height: 12px;
  margin-top: 16px;
  text-transform: uppercase;

  &:before,
  &:after {
    background-color: ${COLORS.neutral100};
    content: '';
    display: block;
    height: 1px;
    width: 100%;
  }

  &:before {
    margin-right: 24px;
  }

  &:after {
    margin-left: 24px;
  }
`
