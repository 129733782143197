import React, { useState, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import {
  SkpButton,
  SkpInputText,
  SkpInputNumber,
} from '@skeepers/skeepers-ui-react'
import { faFolderArrowUp } from '@skeepers/skeepers-icons/solid/faFolderArrowUp'

const MediaModal = ({ mediaType, isOpen, onSave, onClose }) => {
  const [file, setFile] = useState(null)
  const [source, setSource] = useState(null)
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)
  const [aspectRatio, setAspectRatio] = useState(null)
  const [sourceError, setSourceError] = useState(null)
  const [isMediaOk, setIsMediaOk] = useState(false)
  const [isEditingWidth, setIsEditingWidth] = useState(false)
  const [isEditingHeight, setIsEditingHeight] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fileInputRef = useRef(null)

  const clearInputs = () => {
    setFile(null)
    setSource(null)
    setWidth(null)
    setHeight(null)
    setAspectRatio(null)
    setIsMediaOk(false)
    setSourceError(null)
  }

  const onMediaLoad = ({ width, height, file, url }) => {
    if (width && height) {
      setAspectRatio(width / height)
      setWidth(width)
      setHeight(height)
    }
    if (file) setFile(file)
    if (url) setSource(url)

    setIsMediaOk(true)
    setSourceError(null)
  }

  const onMediaLoadError = () => {
    setAspectRatio(null)
    setWidth(null)
    setHeight(null)
    setIsMediaOk(false)
    setSourceError(
      I18n.t(
        'js.pro.campaigns.form.descriptions.quill_media_modal.link_not_valid'
      )
    )
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    const url = URL.createObjectURL(file)
    fileInputRef.current.value = ''

    if (mediaType === 'image') {
      const img = new Image()
      img.src = url
      img.onerror = onMediaLoadError
      img.onload = () =>
        onMediaLoad({ width: img.width, height: img.height, file, url })
    }

    if (mediaType === 'video') {
      // if video size is greater than 30MB, alert the user and return
      if (file && file.size > 31457280) {
        alert(I18n.t('js.pro.campaigns.form.video_too_large'))
        return
      }

      const video = document.createElement('video')
      video.src = url
      video.onerror = onMediaLoadError
      video.onloadedmetadata = () =>
        onMediaLoad({
          width: video.videoWidth,
          height: video.videoHeight,
          file,
          url,
        })
    }
  }

  const handleSourceChange = event => {
    const url = event.target.value
    setSource(url)

    if (mediaType === 'image') {
      const img = new Image()
      img.src = url
      img.onerror = onMediaLoadError
      img.onload = () => onMediaLoad({ width: img.width, height: img.height })
    } else if (mediaType === 'video') {
      const video = document.createElement('video')
      video.src = url
      video.preload = 'metadata'
      video.onerror = onMediaLoadError
      video.onloadedmetadata = () =>
        onMediaLoad({
          width: video.videoWidth,
          height: video.videoHeight,
        })
    }
  }

  const handleWidthChange = event => {
    if (!isEditingHeight) {
      const newWidth = event.target.value
      if (!newWidth) return
      setWidth(newWidth)
      setHeight(Math.round(newWidth / aspectRatio))
    }
  }

  const handleHeightChange = event => {
    if (!isEditingWidth) {
      const newHeight = event.target.value
      if (!newHeight) return
      setHeight(newHeight)
      setWidth(Math.round(newHeight * aspectRatio))
    }
  }

  const handleCloseModal = () => {
    onClose()
    clearInputs()
  }

  const handleSaveClicked = async () => {
    setIsLoading(true)
    await onSave({ file, source, width, height })
    handleCloseModal()
    setIsLoading(false)
  }

  return (
    <Modal className="media-modal" show={isOpen} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title componentClass="h3">
          {I18n.t(
            `js.pro.campaigns.form.descriptions.quill_media_modal.${mediaType}_title`
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="full-width">
          <div className="flex align-items-center full-width mb-20">
            <label className="full-width mr-10">
              {I18n.t(
                'js.pro.campaigns.form.descriptions.quill_media_modal.source'
              )}
              <SkpInputText
                placeholder={I18n.t(
                  `js.pro.campaigns.form.descriptions.quill_media_modal.source_${mediaType}_placeholder`
                )}
                value={source}
                onSkpChange={handleSourceChange}
                error={sourceError}
                disabled={isLoading}
              />
            </label>
            <SkpButton
              className="mt-14"
              onClick={() => fileInputRef.current.click()}
              icon={faFolderArrowUp}
              type="icon"
              disabled={isLoading}
            />
            <input
              type="file"
              accept={`${mediaType}/*`}
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="flex align-items-center full-width">
            <label className="full-width mr-10">
              {I18n.t(
                'js.pro.campaigns.form.descriptions.quill_media_modal.width'
              )}
              <SkpInputNumber
                value={width}
                onSkpChange={handleWidthChange}
                onFocus={() => setIsEditingWidth(true)}
                onBlur={() => setIsEditingWidth(false)}
                disabled={isLoading || !width}
              />
            </label>
            <label className="full-width">
              {I18n.t(
                'js.pro.campaigns.form.descriptions.quill_media_modal.height'
              )}
              <SkpInputNumber
                value={height}
                onSkpChange={handleHeightChange}
                onFocus={() => setIsEditingHeight(true)}
                onBlur={() => setIsEditingHeight(false)}
                disabled={isLoading || !height}
              />
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-content-end">
          <SkpButton
            className="mr-20"
            onClick={handleCloseModal}
            size="medium"
            type="neutral"
            text={I18n.t(
              'js.pro.campaigns.form.descriptions.quill_media_modal.cancel'
            )}
          />
          <SkpButton
            onClick={handleSaveClicked}
            loading={isLoading}
            size="medium"
            type="primary"
            text={I18n.t(
              'js.pro.campaigns.form.descriptions.quill_media_modal.save'
            )}
            disabled={!isMediaOk}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default MediaModal
