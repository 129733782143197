import React, { useState, useEffect, useRef, useMemo } from 'react'
import 'creators/common/application'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import {
  Form,
  FormGroup,
  ControlLabel,
  HelpBlock,
  Overlay,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap'
import moment from 'moment'
import {
  useForm,
  FormContext,
  useFormContext,
  Controller,
} from 'react-hook-form'
import SimpleDateSelector from 'shared/components/SimpleDateSelector'
import { withoutToken as fetchAPI, APIError } from 'shared/fetchAPI'
import redirectWithFlashMessage from 'shared/redirectWithFlashMessage'
import { showFlashMessage } from 'shared/components/utils/FlashMessages'
import MaterialInput from 'shared/components/MaterialInput'
import APP_ISO_CONF from 'shared/appIsoConf'
import { Button } from 'shared/components/UIkit/Buttons'
import getErrorMessage from 'shared/getErrorMessage'
import PasswordPopover from './PasswordPopover'
import CountryNotOpenModal from './CountryNotOpenModal'
import OSelect from 'shared/components/OSelect'
import { imagePath } from 'shared/images'
import fbq from 'shared/fbq'
import { ContinueWithFacebook } from './FacebookButton'
import { Checkbox } from 'shared/components/UIkit'
import { useFacebookSdk } from 'shared/useFacebookSdk'
import { useFlag } from '@unleash/proxy-client-react'
import { handleSignupBypassSearchParams } from './handlers/SearchParamsHandler'

function SignUpForm({ availableCountries, noTitle = false, landing = false }) {
  const { search } = useLocation()
  const queries = qs.parse(search, { ignoreQueryPrefix: true })
  const [countryNotOpen, setCountryNotOpen] = useState(false)
  const [emailConsent, setEmailConsent] = useState(false)
  const [isFacebookSdkReady] = useFacebookSdk()

  const { form, passwordCheck, onSubmit, defaultCountryCode } = useLogic({
    availableCountries,
    setCountryNotOpen,
  })
  const { register, handleSubmit, watch, clearError } = form
  const password = watch('password', '')
  const countryCode = watch('country_code', defaultCountryCode)
  const [passwordFocused, setPasswordFocused] = useState(false)
  const passwordInputRef = useRef()
  const [submitting, setSubmitting] = useState(false)
  const defaultBirthDate = useMemo(() => moment().subtract(20, 'years'), [])
  const defaultEmail = queries?.email || ''

  async function onLockedSubmit(...args) {
    setSubmitting(true)
    try {
      return await onSubmit({ ...args[0], emailConsent })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <CountryNotOpenModal
        openModal={countryNotOpen}
        setOpenModal={setCountryNotOpen}
      />
      {(!noTitle || !landing) && (
        <>
          <h1 className="font-light size-h1 uppercase text-center px-5">
            {I18n.t('js.creators_registration.form.title')}
          </h1>
          <h3 className="font-light bottom-0 top-8 text-center pleft-16 pright-16 fs-16">
            {I18n.t('js.creators_registration.form.subtitle')}
          </h3>
          {isFacebookSdkReady && (
            <ContinueWithFacebook
              availableCountries={availableCountries}
              onBusy={isBusy => setSubmitting(isBusy)}
            />
          )}
        </>
      )}
      <FormContext {...form}>
        <Form
          onSubmit={handleSubmit(onLockedSubmit)}
          style={landing ? { width: '100%' } : { maxWidth: '320px' }}
        >
          <Group name="email">
            <Controller
              rules={{ required: true }}
              name="email"
              defaultValue={defaultEmail}
              noValidate
              as={
                <MaterialInput
                  label={I18n.t('js.creators_registration.form.email.label')}
                  name="email"
                  type="email"
                />
              }
            />
          </Group>

          <Group
            name="password"
            style={landing ? { marginBottom: '25px' } : null}
          >
            <Controller
              as={
                <MaterialInput
                  label={I18n.t('js.creators_registration.form.password.label')}
                  type="password"
                  onFocus={e => {
                    setPasswordFocused(true)
                  }}
                  onBlur={e => {
                    setPasswordFocused(false)
                  }}
                  ref={passwordInputRef}
                />
              }
              onChange={e => {
                clearError(['password'])
                return e[0].target.value
              }}
              rules={{ required: true }}
              name="password"
            />
            {passwordInputRef.current && (
              <Overlay
                show={
                  passwordFocused && !!password.length && !passwordCheck.valid
                }
                placement="bottom"
                target={passwordInputRef.current}
              >
                <PasswordPopover
                  id="popover-positioned-bottom"
                  passwordCheck={passwordCheck}
                />
              </Overlay>
            )}
          </Group>
          <Group
            name="country_code"
            label={I18n.t('js.creators_registration.form.country.label')}
          >
            <Controller
              name="country_code"
              as={
                <OSelect
                  options={availableCountries.map(country => ({
                    value: country.alpha2,
                    label: country.name,
                  }))}
                  innerRef={register({ required: true })}
                  name="country_code"
                  width={landing ? null : 320}
                />
              }
              defaultValue={defaultCountryCode}
            />
          </Group>

          <Group
            name="birth_date"
            label={I18n.t('js.creators_registration.form.birth_date.label')}
          >
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="bottom"
              overlay={
                <Popover id="country-code-info" style={{ maxWidth: 600 }}>
                  <div>
                    {I18n.t('js.creators_registration.form.birth_date.info_1')}
                    <br />
                    {I18n.t('js.creators_registration.form.birth_date.info_2')}
                  </div>
                </Popover>
              }
            >
              <img
                src={imagePath('icons/info-24px.svg')}
                className="left-5"
                width="14px"
              />
            </OverlayTrigger>
            <div className="form-inline">
              <Controller
                as={
                  <SimpleDateSelector
                    name="birth_date"
                    dateFormatL={
                      countryCode === 'US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
                    }
                    className={landing ? 'landing' : null}
                  />
                }
                name="birth_date"
                defaultValue={defaultBirthDate}
              />
            </div>
          </Group>

          <Checkbox
            id="emailConsent"
            className="form-group"
            checked={emailConsent}
            label={
              <div className="text-muted font-light s12">
                {I18n.t('js.creators_registration.form.email_consent')}
              </div>
            }
            onChange={() => setEmailConsent(!emailConsent)}
          />

          {!landing && (
            <div
              className="form-group text-muted font-light s12"
              dangerouslySetInnerHTML={{
                __html: I18n.t(
                  'js.creators_registration.form.accept_conditions'
                ),
              }}
            />
          )}

          <Button
            block
            size="sm"
            type="submit"
            loading={submitting}
            disabled={submitting}
          >
            {I18n.t('js.creators_registration.form.submit')}
          </Button>
        </Form>
      </FormContext>
    </React.Fragment>
  )
}

function useLogic({ availableCountries, setCountryNotOpen }) {
  const defaultCountryCode = useMemo(() => {
    const countryCodeInLocale = I18n.locale.split('-')[1]
    return availableCountries
      .map(country => country.alpha2)
      .indexOf(countryCodeInLocale) !== -1
      ? countryCodeInLocale
      : availableCountries[0].alpha2
  }, [])

  const form = useForm({
    defaultValues: {
      country_code: defaultCountryCode,
    },
  })
  const { setError, watch } = form
  const countryCode = watch('country_code', defaultCountryCode)
  const password = watch('password', '')
  const minimumAge = useMinimumAge(countryCode)
  const passwordCheck = challengePassword(password)
  const { search } = useLocation()
  const queries = qs.parse(search, { ignoreQueryPrefix: true })
  const referrer_id = queries['cref'] || ''
  const isFunnelByPassFeatureFlagEnabled = useFlag('ff_funnel_bypass')

  async function onSubmit({
    email,
    password,
    birth_date,
    country_code,
    emailConsent,
  }) {
    let payload = {
      referrer_id,
      email,
      password,
      birth_date: birth_date.format('YYYY-MM-DD'),
      country_code: country_code,
      locale: I18n.locale,
      'creator:marketing_communication_consent': emailConsent,
      utm: {
        source: Cookies.get('utm_source'),
        medium: Cookies.get('utm_medium'),
        campaign: Cookies.get('utm_campaign'),
        content: Cookies.get('utm_content'),
      },
    }

    if (isFunnelByPassFeatureFlagEnabled) {
      payload = {
        ...payload,
        bypass: handleSignupBypassSearchParams(queries),
      }
    }

    try {
      await fetchAPI(`/api/v3/creators`, {
        method: 'POST',
        body: payload,
      })
      fbq('track', 'CompleteRegistration')
      window.location = `/creators/`
    } catch (error) {
      handleError(error)
    }
  }

  function handleError(error) {
    const validationErrors = {}
    let modalErrorOpen = false

    if (error instanceof APIError) {
      const apiErrors = error.asObject()
      if ('email_taken' in apiErrors) {
        return redirectWithFlashMessage(`/login`, {
          type: 'notice',
          key: 'js.signin.already_have_an_account_please_login',
        })
      }

      if ('email_blank' in apiErrors) {
        validationErrors.email = { type: 'required' }
      } else if ('email_invalid' in apiErrors) {
        validationErrors.email = { type: 'invalidFormat' }
      }

      // using password_policy_20191122
      if ('password_blank' in apiErrors) {
        validationErrors.password = { type: 'required' }
      } else if (
        'password_too_short' in apiErrors ||
        'password_not_strong_enough' in apiErrors
      ) {
        validationErrors.password = {
          type: 'invalidFormat',
          message: `html:${I18n.t(
            `js.creators_registration.form.validation_errors.password_policy_20191122_html`,
            { min_length: APP_ISO_CONF.password_policy.min_length }
          )}`,
        }
      }

      if ('birth_date_too_young' in apiErrors) {
        if (minimumAge) {
          validationErrors.birth_date = {
            type: 'other',
            message: I18n.t(
              'js.creators_registration.form.validation_errors.too_young_at_least',
              { minimum_age: minimumAge }
            ),
          }
        } else {
          validationErrors.birth_date = {
            type: 'other',
            message: I18n.t(
              'js.creators_registration.form.validation_errors.too_young'
            ),
          }
        }
      }

      if ('invalid_country' in apiErrors) {
        setCountryNotOpen(true)
        modalErrorOpen = true
      }

      if ('already_has_an_account' in apiErrors) {
        return redirectWithFlashMessage(`/login`, {
          type: 'error',
          key: 'js.signin.suspected_duplicated_creator',
        })
      }
    }

    for (let field in validationErrors) {
      const { type, message } = validationErrors[field]
      setError(field, type, message)
    }

    if (Object.entries(validationErrors).length === 0 && !modalErrorOpen) {
      showFlashMessage('error', I18n.t('js.global.error'))
    }
  }

  return {
    form,
    passwordCheck,
    onSubmit,
    defaultCountryCode,
  }
}

export function challengePassword(password) {
  // sometimes noob code is the best
  const details = {}
  const complexityCriterias = {
    lowercase: /[a-z]/,
    uppercase: /[A-Z]/,
    number: /[0-9]/,
    symbol: /[^a-zA-Z0-9]/,
  }
  for (let criteriaKey in complexityCriterias) {
    details[criteriaKey] = password.match(complexityCriterias[criteriaKey])
  }
  const enoughCriteriasMet =
    Object.values(details).filter(value => value).length >= 3

  details.minLength = password.length >= APP_ISO_CONF.password_policy.min_length

  return {
    valid: enoughCriteriasMet && details.minLength,
    details,
  }
}

function useMinimumAge(countryCode) {
  const [minimumAge, setMinimumAge] = useState(null)
  useEffect(() => {
    setMinimumAge(null)
    if (countryCode)
      fetchAPI(`/api/v3/creators/minimum_age?country_code=${countryCode}`, {
        useToken: false,
        useFormatAttributes: false,
      }).then(({ minimum_age }) => {
        setMinimumAge(minimum_age)
      })
  }, [countryCode])
  return minimumAge
}

const Group = ({ name, label, children, ...props }) => {
  const { errors } = useFormContext()
  const error = errors[name]
  return (
    <FormGroup {...props} validationState={error ? 'error' : null}>
      {label && (
        <ControlLabel className="color-gray-text-light">{label}</ControlLabel>
      )}
      {children}
      {error ? <ErrorBlock error={error} /> : undefined}
    </FormGroup>
  )
}

function ErrorBlock({ error }) {
  let message = getErrorMessage(error)
  const isHtmlMessage = message.indexOf('html:') === 0
  if (isHtmlMessage) message = message.replace('html:', '')
  const helpBlockProps = isHtmlMessage
    ? { dangerouslySetInnerHTML: { __html: message } }
    : { children: message }
  return <HelpBlock className="has-error" {...helpBlockProps} />
}

// We now use the signup from the creator webapp
function RedirectSignUp({ children }) {
  // Redirect only if user is on sign up panel. Login panel is still up.
  const isOnSignUpPanel = location.pathname.includes('/creators/sign_up')
  const lang = window.navigator.language.split('-')[0]

  // Creator webapp has public URL and private URL for testing purposes
  const linkEnv = process.env.RAILS_ENV

  const redirectURL =
    APP_ISO_CONF.creator_webapp_url[linkEnv][lang] ||
    APP_ISO_CONF.creator_webapp_url[linkEnv].default

  // If querystrings (e.g. bypass, referrer, etc), add them to redirect link
  const { search } = window.location

  if (isOnSignUpPanel) {
    window.location.replace(`${redirectURL}${search}`)
    return null
  } else {
    return children
  }
}

export default props => (
  <RedirectSignUp>
    <SignUpForm {...props} />
  </RedirectSignUp>
)
