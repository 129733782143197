import React, { useContext, createContext } from 'react'
import { Modal } from 'react-bootstrap'
import CampaignSummary from './CampaignSummary'
import SelectContractAndPlatformAccounts from './SelectContractAndPlatformAccounts'
import withHOC from 'shared/fp/withHOC'
import OrderProvider from './OrderProvider'
import OrderSummary from './OrderSummary'

export const OrderContext = createContext()

const OrderModalContent =
  function () {
    const { contractId, platformAccountIds } = useContext(OrderContext)

    return (
      <>
        <Modal.Header closeButton />
        <Modal.Body className="ptop-0">
          <CampaignSummary className="bottom-20" />
          {contractId && platformAccountIds ? (
            <OrderSummary />
          ) : (
            <SelectContractAndPlatformAccounts />
          )}
        </Modal.Body>
      </>
    )
  } |> withHOC(OrderProvider)

export default OrderModalContent
