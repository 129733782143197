import React, { Fragment, useState } from 'react'
import {
  Modal,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
} from 'react-bootstrap'
import styled from 'styled-components'

const ConfirmPasswordModal = ({ onClick }) => {
  const [password, setPassword] = useState('')

  return (
    <Fragment>
      <Modal.Header>
        <Title componentClass="div">
          <h1 className="font-light text-uppercase text-center">
            {I18n.t('js.creators.settings.edit.password_required')}
          </h1>
          <h4 className="text-muted font-light text-center">
            {I18n.t('js.creators.settings.edit.password_required_subtitle')}
          </h4>
        </Title>
      </Modal.Header>
      <Modal.Body className="align-items-center flex flex-column ptop-20 pbottom-0">
        <FormGroup className="top-0" style={{ width: '100%' }}>
          <ControlLabel>
            {I18n.t('js.creators.settings.edit.current_password')}
          </ControlLabel>
          <FormControl
            type="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="align-items-center flex flex-column pbottom-45">
        <Button bsStyle="primary" onClick={() => onClick(password)}>
          {I18n.t('js.creators.campaigns.missing_fields_modal.update')}
        </Button>
      </Modal.Footer>
    </Fragment>
  )
}

/**
 * STYLED COMPONENTS
 */
const Title = styled(Modal.Title)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0 27.45px;

  h1 {
    font-size: 36px;
    line-height: 40px;
    margin: 30px 0 8px 0;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    padding: 0 40px;
    margin: 0;
  }
`

export default ConfirmPasswordModal
