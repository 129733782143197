import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react'
import BACKEND_VALUES from 'creators/common/backendValues'
import fetchAPI from 'shared/fetchAPI'
import { useHistory, useLocation } from 'react-router'

export const CreatorContext = createContext()

function CreatorProvider({ children }) {
  const [creator, setCreator] = useState(BACKEND_VALUES.me)
  const { alerts, refreshBadge, refreshBanner } = useAlerts()

  const refreshMe = async () => {
    const data = await fetchAPI(`/api/v3/creators/me`)
    setCreator(data)
  }

  const shouldDisplayFunnelByPassLandingPage = useMemo(
    () =>
      creator.funnel_bypass &&
      !creator.is_influencer &&
      creator.funnel_bypass_to_be_consumed,
    [
      creator.funnel_bypass,
      creator.is_influencer,
      creator.funnel_bypass_to_be_consumed,
    ]
  )

  const contextValue = useMemo(
    () => ({
      creator,
      alerts,
      refreshBadge,
      refreshBanner,
      setCreator,
      refreshMe,
      shouldDisplayFunnelByPassLandingPage,
    }),
    [creator, alerts]
  )

  return (
    <CreatorContext.Provider value={contextValue}>
      {children}
    </CreatorContext.Provider>
  )
}

CreatorContext.displayName = 'CreatorContext'

function useAlerts() {
  const [alerts, setAlerts] = useState(BACKEND_VALUES.alerts)
  const { execOnNextNavigationChange } = useNavigationCallback()
  const refreshBadge = async () => {
    if (!alerts.badges) return
    const {
      alerts: { badges },
    } = await fetchAPI(`/api/v3/creators/alerts`)
    setAlerts({
      ...alerts,
      badges,
    })
  }
  const refreshBanner = async () => {
    if (!alerts.banners) return
    const { alerts: newAlerts } = await fetchAPI(`/api/v3/creators/alerts`)
    if (newAlerts.banners?.key !== alerts.banners.key) {
      setAlerts({
        ...alerts,
        banners: null,
      })
      execOnNextNavigationChange(() => {
        setAlerts(alerts => ({
          ...alerts,
          banners: newAlerts.banners,
        }))
      })
    }
  }
  return {
    alerts,
    refreshBadge,
    refreshBanner,
  }
}

function useNavigationCallback() {
  const history = useHistory()
  const { pathname } = useLocation()
  const [previousPathname, setPreviousPathname] = useState(pathname)
  const { current: callbacks } = useRef([])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH' && previousPathname !== location.pathname) {
        while (callbacks.length > 0) {
          callbacks.shift()()
        }
      }
      setPreviousPathname(location.pathname)
    })
    return unlisten
  }, [])

  const execOnNextNavigationChange = callback => {
    callbacks.push(callback)
  }

  return {
    execOnNextNavigationChange,
  }
}

export default CreatorProvider
