import styled from 'styled-components'

export const color = ({ color }) => {
  switch (color) {
    case 'default':
      return '#FFFFFF'
    case 'primary':
      return '#1A2C79'
    case 'danger':
      return '#FF6179'
  }
}

export const colors = {
  grayLightPlus: '#E8EBED',
}

export const inverseColor = ({ color }) => {
  return color === 'default' ? '#333333' : '#FFFFFF'
}

export const Badge = styled.div`
  ${props => `
    background-color: ${props.color === 'default' ? '#eee' : color(props)};
    color: ${inverseColor(props)};
    padding: 4px 12px;
    border-radius: 18px;
  `}
`

Badge.defaultProps = {
  color: 'default',
}

export const Box = styled.div`
  padding: ${props => props.p};
  border: 1px ${colors.grayLightPlus} solid;
`

Box.defaultProps = {
  p: '6px 8px',
}
