import 'shared/application'

$(document).ready(() => {
  $('#flash-message.from-html').on('click', () => {
    $(this).hide()
  })

  $('#flash-message.from-html').delay(4000).fadeOut()

  var $languageBanner = $('#language-banner')

  if ($languageBanner.count == 0) {
    return
  }

  $languageBanner.find('.cancel-button').on('click', event => {
    document.cookie = 'do_not_show_language_banner=true'
    $languageBanner.alert('close')
  })

  if ($('.modal.show-on-load').length > 0) {
    $('.show-on-load').modal('show')
  }
})

// some zendesk config
window.zESettings = {
  webWidget: {
    zIndex: 300,
    contactForm: { ticketForms: [{ id: 5486073315740 }] },
  },
}
