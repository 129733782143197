import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { COLORS } from 'shared/scss'
import StarIcon from 'assets/images/star.svg'
import RatingStarsLegacy from './RatingStarsLegacy'

const DEFAULT_RATINGS = [1, 2, 3, 4, 5]

const RatingStarsComponent = ({ shouldDisplayOldUI, ...props }) =>
  shouldDisplayOldUI ? (
    <RatingStarsLegacy {...props} />
  ) : (
    <RatingStars {...props} />
  )

const RatingStars = ({
  scale = DEFAULT_RATINGS,
  rating = 0,
  color = COLORS.warning300,
  width = 24,
  height = 24,
  disabled = false,
  readOnly = false,
  dataTest,
  onChange,
}) => {
  const [initialRating, setInitialRating] = useState(rating)
  const [tempRating, setTempRating] = useState(rating)

  useEffect(() => {
    if (!isNaN(rating)) {
      setInitialRating(rating)
      setTempRating(rating)
    }
  }, [rating])

  const handleClick = star => {
    setInitialRating(star)
    if (typeof onChange === 'function') onChange(star)
  }

  return (
    <div
      data-test={dataTest}
      className="d-flex"
      onMouseLeave={() => setTempRating(initialRating)}
    >
      {scale.map(star => (
        <Star
          key={`RatingStars-${star}`}
          fill={color}
          width={width}
          height={height}
          className={cn('mr-5', { readOnly, disabled })}
          {...(tempRating < star && { opacity: 0.2 })}
          onMouseOver={() => {
            if (!disabled && !readOnly) {
              setTempRating(star)
            }
          }}
          onClick={() => {
            if (!disabled && !readOnly) {
              handleClick(star)
            }
          }}
        />
      ))}
    </div>
  )
}

const Star = styled(StarIcon)`
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.readOnly {
    cursor: default;
  }
`

export default RatingStarsComponent
