import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { imagePath } from 'shared/images'
import cn from 'classnames'

const DEFAULT_RATINGS = [1, 2, 3, 4, 5]

const RatingStarsLegacy = ({
  scale,
  rating,
  onChange,
  disabled,
  readOnly,
  dataTest,
}) => {
  const [initialRating, setInitialRating] = useState(rating)
  const [tempRating, setTempRating] = useState(rating)

  useEffect(() => {
    if (!isNaN(rating)) {
      setInitialRating(rating)
      setTempRating(rating)
    }
  }, [rating])

  const handleClick = star => {
    setInitialRating(star)
    if (typeof onChange === 'function') onChange(star)
  }

  const stars = {
    full: imagePath('star_full.png'),
    empty: imagePath('star_empty.png'),
  }

  return (
    <div
      className="d-block"
      onMouseLeave={() => setTempRating(initialRating)}
      data-test={dataTest}
    >
      {scale.map(star => (
        <Star
          key={`RatingStars-${star}`}
          image={tempRating >= star ? stars.full : stars.empty}
          onMouseOver={
            disabled || readOnly ? undefined : () => setTempRating(star)
          }
          onClick={disabled || readOnly ? undefined : () => handleClick(star)}
          className={cn({ readOnly, disabled })}
        />
      ))}
    </div>
  )
}

RatingStarsLegacy.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.number),
  rating: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnly: PropTypes.bool,
}

RatingStarsLegacy.defaultProps = {
  scale: DEFAULT_RATINGS,
  rating: 0,
  disabled: false,
  readOnly: false,
}

export default RatingStarsLegacy

/**
 * Styled Components
 */
const Star = styled.div`
  ${({ image }) => css`
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    &.readOnly {
      cursor: default;
    }
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    background-image: ${`url(${image})`};
    & + & {
      margin-left: 5px;
    }
  `}
`
