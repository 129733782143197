/* eslint-disable indent */
import { css } from 'styled-components'
import { COLORS } from 'shared/scss'

export function TextColors() {
  return Object.keys(COLORS).map(
    color => css`
      .tc-${color} {
        color: ${COLORS[color]};
      }
    `
  )
}

export function BackgroundColors() {
  return Object.keys(COLORS).map(
    color => css`
      .bgc-${color} {
        background-color: ${COLORS[color]};
      }
    `
  )
}
