import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import cn from 'classnames'

export const BaseSimpleToolTip = ({
  children,
  text,
  placement = 'top',
  tooltipClassName,
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      <Tooltip id="tooltip" className={tooltipClassName}>
        {text}
      </Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
)

const SimpleToolTipWithDisabledFix = ({
  children,
  style,
  className,
  display = 'inline-block',
  ...otherProps
}) => {
  if (!children) return children
  if (otherProps.text && children.props && children.props.disabled) {
    return (
      <BaseSimpleToolTip {...otherProps}>
        {/* wrapping span + styles = because otherwise tooltip wouldn't work with disabled button
      see https://github.com/react-bootstrap/react-bootstrap/issues/2428 */}
        <span
          style={{ display, cursor: 'not-allowed', ...style }}
          className={className}
        >
          {React.cloneElement(children, {
            style: {
              ...((children.props && children.props.style) || {}),
              pointerEvents: 'none',
            },
          })}
        </span>
      </BaseSimpleToolTip>
    )
  }

  children = React.cloneElement(children, {
    style: {
      ...((children.props && children.props.style) || {}),
      ...style,
    },
    className: cn(children.props.className, className),
  })
  if (!otherProps.text) return children

  return <BaseSimpleToolTip {...otherProps}>{children}</BaseSimpleToolTip>
}

export default SimpleToolTipWithDisabledFix
