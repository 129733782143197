import React, { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import SimpleToolTip from 'shared/components/SimpleToolTip'
import { DebugContext } from './DebugProvider'
import { createPortal } from 'react-dom'

export default function Launcher() {
  const {
    open,
    setOpen,
    activeTool,
    setActiveTool,
    registeredTools,
    callbackRef,
  } = useContext(DebugContext)
  const modalIsShowing = useModalIsShowing()
  useEffect(() => {
    document.body.classList.toggle('devtool-launcher-opened', open)
    return () => {
      document.body.classList.remove('devtool-launcher-opened')
    }
  }, [open])
  if (!open) {
    return (
      <Collapsed
        onClick={() => {
          setOpen(true)
        }}
      />
    )
  }
  if (activeTool) {
    return null
  }
  return createPortal(
    <TabsContainer>
      <Tab
        onClick={() => {
          setOpen(false)
        }}
        notGreyPlz={modalIsShowing}
      >
        Hide debug tools
      </Tab>
      {registeredTools.map(({ key, label, color }) => (
        <Tab
          key={key}
          onClick={e => {
            if (typeof callbackRef.current[key] === 'function') {
              callbackRef.current[key](e)
            } else {
              setActiveTool(key)
            }
          }}
          notGreyPlz={modalIsShowing}
          color={color}
        >
          {label}
        </Tab>
      ))}
    </TabsContainer>,
    document.body
  )
}

function useModalIsShowing() {
  const isShowingNow = () => document.body.classList.contains('modal-open')
  const [modalIsShowing, setModalIsShowing] = useState(isShowingNow())
  useEffect(() => {
    const observer = new MutationObserver(function () {
      setModalIsShowing(isShowingNow())
    })
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false,
    })
    return () => {
      observer.disconnect()
    }
  }, [])
  return modalIsShowing
}

function Tab({ children, ...props }) {
  return (
    <SimpleToolTip placement="left" text={children}>
      <TabContainer {...props} />
    </SimpleToolTip>
  )
}

const TabContainer = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: ${resolveColor};
  margin: 6px 10px;
`

function resolveColor({ color, notGreyPlz }) {
  if (color === 'green') return '#377102'
  return color || (notGreyPlz ? '#0000001f' : '#82828226')
}

const Collapsed = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
`

const TabsContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 90px;
`
