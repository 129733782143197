import React, { useState } from 'react'
import { imgixURL } from 'shared/imgix'
import { imagePath } from 'shared/images'
import WithTooltip from 'shared/components/WithTooltip'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from 'shared/scss'
import PlatformIcon from 'shared/components/PlatformIcon'

import AddSocialAccountModal from './AddSocialAccountModal'

function CampaignCard({ campaign, onClick, hideTooltips }) {
  const [show, setShow] = useState(false)

  // Creator can have several accounts on the same platform. No need to display
  // several times that the campaign is on Tiktok, once is enough.
  const possiblePlatforms = [
    ...new Set(
      campaign.is_accessible
        ? campaign.creator_platforms.map(
            creatorPlatform => creatorPlatform.platform_entity.platform
          )
        : campaign.platforms
    ),
  ]

  return (
    <React.Fragment>
      <AddSocialAccountModal show={show} onHide={() => setShow(false)} />
      <Campaign
        to={campaign.is_accessible ? `/creators/campaigns/${campaign.id}` : ''}
        onClick={e => {
          onClick?.(e)
          if (e.defaultPrevented) return
          if (!campaign.is_accessible) {
            e.preventDefault()
            setShow(true)
          }
        }}
      >
        <div className="position-relative d-flex align-items-center justify-content-center">
          <ImageCampaign
            src={imgixURL(campaign.photo, {
              fm: 'jpg',
              q: 90,
              fit: 'crop',
              w: 400,
              h: 300,
            })}
            srcSet={`${imgixURL(campaign.photo, {
              fm: 'jpg',
              q: 90,
              fit: 'crop',
              w: 800,
              h: 600,
            })} 2x`}
            sizes="350px" //NOTE: If there is only one size and no condition, what the srcSet is used for?
          />
          <CampaignImageOverlay campaign={campaign} />
        </div>
        <InfoContainer>
          <Title>{campaign.title}</Title>
          <StoreTitle>
            <span>
              {campaign.store.title}
              &nbsp;
            </span>
            {!hideTooltips && (
              <PlatformContainer>
                {possiblePlatforms.map((platform, i) => (
                  <PlatformIcon
                    key={`platform-${platform}-${i}`}
                    width={20}
                    height={20}
                    platform={platform}
                    css="&:not(:last-child) { margin-right: 5px;}"
                  />
                ))}
                {campaign.pre_approved && (
                  <WithTooltip
                    title={I18n.t('js.creators.campaign_card.instant_order')}
                  >
                    <Platform
                      src={imagePath('bolt.svg')}
                      width="20"
                      height="20"
                    />
                  </WithTooltip>
                )}
                {campaign.has_promo_code && (
                  <WithTooltip
                    title={I18n.t('js.creators.campaign_card.promocode')}
                  >
                    <Platform
                      src={imagePath('promocode.svg')}
                      width="20"
                      height="20"
                    />
                  </WithTooltip>
                )}
                {campaign.has_personalized_affiliate_links && (
                  <WithTooltip
                    title={I18n.t('js.creators.campaign_card.affiliate_link')}
                  >
                    <Platform
                      src={imagePath('affiliate_link.svg')}
                      width="20"
                      height="20"
                    />
                  </WithTooltip>
                )}
                {campaign.is_paid && (
                  <WithTooltip
                    title={I18n.t('js.creators.campaign_card.is_paid')}
                  >
                    <Platform
                      src={imagePath('paid.svg')}
                      width="20"
                      height="20"
                    />
                  </WithTooltip>
                )}
              </PlatformContainer>
            )}
          </StoreTitle>
        </InfoContainer>
      </Campaign>
    </React.Fragment>
  )
}

function CampaignImageOverlay({ campaign }) {
  if (campaign.ordered) {
    return (
      <React.Fragment>
        <ImageOverlay opacity="light" />
        <img
          style={{ position: 'absolute' }}
          src={imagePath('creators/check_white.svg')}
          width="48px"
        />
        <Label backgroundColor="greenPositive">
          {I18n.t('js.creators.free_store.campaign_card.labels.ordered')}
        </Label>
      </React.Fragment>
    )
  }
  if (campaign.sold_out || campaign.closed) {
    return (
      <React.Fragment>
        <ImageOverlay opacity="dark" />
        <OutOfStock className="uppercase s16 bold text-center">
          {I18n.t('js.creators.free_store.campaign_card.labels.out_of_stock')}
        </OutOfStock>
      </React.Fragment>
    )
  }
  if (campaign.label) {
    const defaultColors = { backgroundColor: 'primary', color: 'white' }
    const colorSet = {
      new: defaultColors,
      last_chance: defaultColors,
      coming_soon: { backgroundColor: 'white', color: 'grayDarker' },
    }
    const props = colorSet[campaign.label] || defaultColors

    return (
      <Label {...props}>
        {I18n.t(
          `js.creators.free_store.campaign_card.labels.${campaign.label}`
        )}
      </Label>
    )
  }

  return null
}

/**
 * STYLED COMPONENTS
 */
const theme = {
  opacity: {
    light: 'rgba(0, 135, 128, 0.6)',
    dark: 'rgba(47, 46, 46, 0.5)',
  },
}

const OutOfStock = styled.div`
  position: absolute;
  color: white;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
`

const Campaign = styled(Link)`
  text-decoration: none !important;
  color: inherit !important;
`

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${props => theme.opacity[props.opacity]};
  display: block;
  border-radius: 4px;
`

const Label = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: ${props => COLORS[props.backgroundColor]};
  color: ${props => COLORS[props.color]};
  padding: 0.1rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9em;
  text-transform: uppercase;
`

const ImageCampaign = styled.img`
  width: 100%;
  border-radius: 4px;
`
const InfoContainer = styled.div`
  margin: 10px 0 30px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Title = styled.strong`
  font-size: 18px;
  color: #333;
`

const StoreTitle = styled.div`
  font-size: 16px;
  color: #666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & > span {
    vertical-align: middle;
  }
`

const PlatformContainer = styled.div`
  margin-top: 5px;
  height: 22px;
  display: flex;
`

const Platform = styled.img`
  &:not(:last-child) {
    margin-right: 5px;
  }
`

export default CampaignCard
