import React, { useState, useEffect, createContext, useMemo } from 'react'
import cn from 'classnames'
import Assets from './Assets'
import fetchAPI from 'shared/fetchAPI'
import Aside from './Aside'
import Body from './Body'
import sortBy from 'lodash/fp/sortBy'
import useMe from 'creators/useMe'
import Debug from './Debug'
import { useDebug } from 'shared/debug'
import { imgixURL } from 'shared/imgix'
import { addEvent } from 'shared/addEvent'
import useBreakpoint from 'shared/useBreakpoint'
import ServerOverLoaded from 'creators/common/components/ServerOverLoaded'
import fromPairs from 'lodash/fromPairs'
import Loader from 'shared/components/Loader'
import { useHistory } from 'react-router'
import useAccountToMigrate from 'creators/useAccountToMigrate'
import useHasDisconnectedAccount from 'creators/useHasDisconnectedAccount'

export const CampaignContext = createContext()

const CreatorsCampaign = ({
  proPreview,
  landing,
  campaign,
  orders,
  refreshCampaign,
  isRefreshing = false,
}) => {
  useMemo(() => {
    for (const product of campaign.products) {
      for (const option of product.options) {
        const cpo = campaign.campaign_product_options.find(
          cpo => option.id === cpo.product_option_id
        )
        // let's try some new pattern babyyyyy
        if (!option.hasOwnProperty('cpo')) {
          Object.defineProperty(option, 'cpo', {
            get: function () {
              return cpo
            },
          })
        }
      }
    }
  }, [campaign.products])
  const { title, subtitle } = campaign

  const [selectedOptionIds, setSelectedOptionIds] = useState(
    fromPairs(
      campaign.products.map(product => {
        const activeOptions = product.options.filter(option => option.cpo)
        return [
          product.id,
          activeOptions.length === 1 ? activeOptions[0].id : null,
        ]
      })
    )
  )
  const fixedCampaign = useMemo(
    () => (proPreview ? fillCampaignDraftForPreview(campaign) : campaign),
    [campaign]
  )

  const order = orders.length
    ? (orders |> sortBy('updated_at'))[orders.length - 1] // latest
    : undefined

  const md = useBreakpoint('md')

  return (
    <CampaignContext.Provider
      value={{
        campaign: fixedCampaign,
        selectedOptionIds,
        setSelectedOptionIds,
        landing,
        proPreview,
        order,
        refreshCampaign,
        isRefreshing,
      }}
    >
      <div
        id="view-campaign-content"
        className={cn('color-bg-white border-radius-4', {
          'top-50 padding-16 bottom-50': !proPreview,
        })}
      >
        <h1 className="margin-0 bottom-5">{title}</h1>
        {subtitle && <h4 className="margin-0 bottom-20">{subtitle}</h4>}
        {md ? (
          <div css="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); grid-gap: 16px;">
            <div css="grid-column: 1/3">
              <Assets />
              <Body />
            </div>
            <div css="grid-column: 3/3">
              <Aside />
            </div>
          </div>
        ) : (
          <>
            <Assets />
            <Aside />
            <Body />
          </>
        )}
      </div>
    </CampaignContext.Provider>
  )
}

function fillCampaignDraftForPreview(campaignDraft) {
  if (!campaignDraft.photo_urls) {
    campaignDraft = {
      ...campaignDraft,
      photo_urls: {
        small: imgixURL(`/fallback/campaign/photo/default.png`),
        medium: imgixURL(`/fallback/campaign/photo/default.png`),
        large: imgixURL(`/fallback/campaign/photo/default.png`),
      },
    }
  }
  return campaignDraft
}

export const SelfFetched = ({ campaignId, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [error, setError] = useState(false)
  const [campaign, setCampaign] = useState()
  const [orders, setOrders] = useState()
  const me = useMe()
  const history = useHistory()
  const [hasToMigrate] = useAccountToMigrate()
  const [hasToReconnect] = useHasDisconnectedAccount()

  const refreshCampaign = async () => {
    try {
      setError(null)
      setIsRefreshing(true)
      setCampaign(
        await fetchAPI(
          `/api/v3/campaigns/${campaignId}?include=contracts.options,products.options,campaign_product_options,store,store.brand_compliancy_links`
        )
      )
    } catch (err) {
      setError(err)
    } finally {
      setIsRefreshing(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (!!me) {
          if (hasToMigrate || hasToReconnect) {
            history.replace('/creators/campaigns/search')
          }
        }

        const [camp, ord] = await Promise.all([
          fetchAPI(
            `/api/v3/campaigns/${campaignId}?include=contracts.options,products.options,campaign_product_options,store,store.brand_compliancy_links`
          ),
          !!me
            ? fetchAPI(`/api/v3/orders?filter[campaign_id]=${campaignId}`)
            : [],
        ])
        setCampaign(camp)
        setOrders(ord)

        if (!!me) {
          addEvent('creator_campaign_details', eventPropsMapping(me, camp))
        }
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [hasToMigrate, hasToReconnect])

  const { active } = useDebug({
    key: 'campaign',
    label: 'Debug campaign UI/UX',
  })
  if (active) return <Debug />

  if (error) {
    const isServerOverLoad = error?.message.includes('503')
    if (isServerOverLoad) return <ServerOverLoaded />

    return (
      <div className="full-page-loader">
        <h2>{I18n.t('js.creators.campaigns.error.title')}</h2>
        <p>{I18n.t('js.creators.campaigns.error.subtitle')}</p>
      </div>
    )
  }

  if (isLoading)
    return (
      <Loader
        {...(props.proPreview
          ? { lg: true, containerHeight: 700 }
          : { fullHeight: true })}
      />
    )

  return (
    <CreatorsCampaign
      campaign={campaign}
      orders={orders}
      refreshCampaign={refreshCampaign}
      isRefreshing={isRefreshing}
      {...props}
    />
  )
}

function eventPropsMapping(creator, campaign) {
  return {
    credit_count: creator.credits,
    campaign_id: campaign.id,
    campaign_name: campaign.title,
    campaign_platforms: campaign.platforms,
    store_name: campaign.store.title,
    store_country: campaign.store.country_code,
  }
}

export default CreatorsCampaign
