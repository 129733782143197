import React from 'react'
import styled from 'styled-components'
import { Image, OverlayTrigger, Popover } from 'react-bootstrap'
import { imgixURL } from 'shared/imgix'
import { imagePath } from 'shared/images'

import FollowButton from 'creators/common/components/FollowButton'

export default function Card({
  store,
  averageResponseTime,
  isInfluencer,
  followedStoreIds,
}) {
  const storeFollowed = !!followedStoreIds.find(id => id === store.id)
  const { id, banner, logo, logo_urls, title, response_time, description } =
    store

  const hasCover = !!banner
  let image = imagePath('blank_logo.jpg')
  if (logo) image = imgixURL(logo)
  if (logo_urls && logo_urls.medium) image = logo_urls.medium
  const responseTime = Math.ceil(response_time / (60 * 60))

  return (
    <Container
      hasCover={hasCover}
      className={`
        d-flex
        border-radius-4
        border-color-gray-light-plus
        color-bg-white
      `}
    >
      <div className="padding-20">
        <Logo src={image} circle />
      </div>
      <div className="d-flex flex-column ptop-20 pright-20 pbottom-20 full-width">
        <Header className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <h2 className="top-0 right-8 bottom-0">{title}</h2>
            <FollowButton
              storeFollowed={storeFollowed}
              storeId={id}
              source="store_page"
            />
          </div>
          {isInfluencer && (
            <ResponseTime className="d-flex-inline align-items-center">
              <p className="no-margin">
                <span
                  className={`right-4 ${
                    response_time <= averageResponseTime
                      ? 'color-blue'
                      : 'color-red'
                  }`}
                >
                  {responseTime}h
                </span>
                {I18n.t('creators.stores.show.response_time')}
              </p>
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement="bottom"
                overlay={
                  <Popover id="response-time-popover" className="text-center">
                    {I18n.t(`js.creators.stores.show.tooltip.response_time`)}
                  </Popover>
                }
              >
                <i className="left-4 fa fa-question color-white color-bg-gray help rounded" />
              </OverlayTrigger>
            </ResponseTime>
          )}
        </Header>
        {description && (
          <div
            className="text-justify top-20"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {isInfluencer && (
          <div className="top-10">
            <a href={`/creators/message_threads?store_id=${id}`}>
              {I18n.t('creators.stores.show.contact', { store: title })}
            </a>
          </div>
        )}
      </div>
    </Container>
  )
}

/**
 * STYLED COMPONENTS
 */
const Container = styled.div`
  width: 80vw;
  max-width: 1100px;
  margin: 0 auto;
  border-width: 2px;
  border-style: solid;
  position: relative;
  ${({ hasCover }) => (hasCover ? 'margin-top: -80px' : 'margin-top: 40px')};

  @media (max-width: 768px) {
    border-radius: 0;
    margin-top: 0;
    width: 100%;
  }
`

const Header = styled.div`
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }
  }
`

const Logo = styled(Image)`
  width: 50px;
  height: 50px;
`

const ResponseTime = styled.div`
  i {
    &:hover {
      cursor: pointer;
    }
  }
`
