import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { BaseInput } from './BaseInput'
import { InputFeedback } from './InputFeedback'

function ControlledCurrencyInput({
  min,
  max,
  className,
  name,
  id,
  error,
  currency = '$',
  locale,
  isoCode = 'USD',
  value,
  onChange,
  onBlur,
  required,
  innerRef,
  step = 1,
  ...props
}) {
  const [valueCheck, setValueCheck] = useState()

  const isValid = () => {
    if (required && (!value || isNaN(parseFloat(value)))) {
      return I18n.t('js.errors.blank')
    }
    const floatValue = parseFloat(value)
    const numberFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: isoCode,
    })
    const tooLow = !!((min || min == 0) && floatValue < min)
    const tooBig = !!(max && floatValue > max)
    if ((min || min == 0) && max && (tooLow || tooBig)) {
      return I18n.t('js.components.currencyinput.errors.out_of_range', {
        min_price: numberFormat.format(min),
        max_price: numberFormat.format(max),
      })
    }
    if (tooLow) {
      return I18n.t('js.components.currencyinput.errors.too_low', {
        min_price: numberFormat.format(min),
      })
    }
    if (tooBig) {
      return I18n.t('js.components.currencyinput.errors.too_big', {
        max_price: numberFormat.format(max),
      })
    }

    return null
  }

  const onBlurCheck = e => {
    setValueCheck(isValid())
    if (onBlur) {
      onBlur(e)
    }
  }

  const _onChange = e => {
    setValueCheck(null)
    onChange(e)
  }

  const displayError = error || valueCheck

  return (
    <Container className={className}>
      <Input
        ref={innerRef}
        type="number"
        name={name}
        id={id || name}
        error={displayError}
        onBlur={onBlurCheck}
        value={value}
        onChange={_onChange}
        required={required}
        step={step}
        {...props}
      />
      <Currency className="flex flex-center">{currency}</Currency>
      {displayError && <InputFeedback type="error" message={displayError} />}
    </Container>
  )
}

function UncontrolledCurrencyInput({ defaultValue = '', ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledCurrencyInput
      value={value}
      onChange={e => setValue(e.target.value)}
      {...props}
    />
  )
}

export const CurrencyInput = React.forwardRef((props, ref) => {
  if (props.onChange)
    return <ControlledCurrencyInput innerRef={ref} {...props} />
  return <UncontrolledCurrencyInput innerRef={ref} {...props} />
})

const Container = styled.div`
  position: relative;
`

const Currency = styled.div`
  transition: ${({ theme }) => theme.global.transition};
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ theme }) => theme.global.inputs.height};
  width: 44px;
  border-right: ${({ theme }) => theme.global.inputs.border};
  font-family: ${({ theme }) => theme.global.inputs.fontFamily};
  font-size: ${({ theme }) => theme.global.inputs.fontSize};
  line-height: ${({ theme }) => theme.global.inputs.lineHeight};
  color: ${({ theme }) => theme.global.inputs.color};
`

const Input = styled(BaseInput)`
  padding-left: 52px;

  &:hover {
    & + ${Currency} {
      border-right: ${({ theme, disabled }) =>
        disabled
          ? theme.global.inputs.border
          : theme.global.inputs.hover.border};
    }
  }

  &:focus {
    & + ${Currency} {
      border-right: ${({ theme }) => theme.global.inputs.focus.border};
    }
  }

  &:disabled {
    & + ${Currency} {
      border-right: ${({ theme }) => theme.global.inputs.border};
    }
  }

  ${({ error }) => {
    if (error)
      return css`
        & + ${Currency} {
          border-right: ${({ theme }) => theme.global.inputs.error.border};
        }
      `
  }}
`
