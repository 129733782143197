import React, { useContext, useEffect, useRef, useState } from 'react'
import useDebug from './useDebug'
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { DebugContext } from './DebugProvider.debug'
import CopyToClipboard from 'react-copy-to-clipboard'
import orderBy from 'lodash/fp/orderBy'
import findIndex from 'lodash/fp/findIndex'
import moment from 'moment'
import slice from 'lodash/fp/slice'

export default function QuickConnectDevtool({ currentEmail }) {
  const { active } = useDebug({
    key: 'quickConnect',
    label: 'Connect to account...',
    color: '#cfe0f5',
  })
  const { setActiveTool } = useContext(DebugContext)
  useRecentAccounts(currentEmail)
  const hotkeyRef = useRef()
  hotkeyRef.current = e => {
    if (!active) {
      e.preventDefault()
      setActiveTool('quickConnect')
    }
  }
  useEffect(() => {
    const listener = e => {
      if (e.key === '/' && e.target === document.body) {
        hotkeyRef.current(e)
      }
    }
    document.addEventListener('keypress', listener)
    return () => {
      document.removeEventListener('keypress', listener)
    }
  }, [])
  const cancel = () => {
    setActiveTool(undefined)
  }
  return (
    <>
      <Modal show={active} onHide={cancel}>
        <QuickConnectModalContent onCancel={cancel} />
      </Modal>
    </>
  )
}

function QuickConnectModalContent({ onCancel }) {
  const [email, setEmail] = useState('')
  const submit = () => {
    window.location.href = `/devtool/signin?email=${
      email |> encodeURIComponent
    }`
  }
  const { protocol, host } = window.location
  return (
    <>
      <Modal.Body>
        <div>Quick connect as...</div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            submit()
          }}
          className="top-10"
        >
          <FormControl
            type="text"
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
            placeholder="user@skeepers.io, creator37019@octoly.com..."
            autoFocus
          />
        </Form>
        <div
          style={{
            opacity: email ? 1 : 0,
            transition: 'opacity 0.1s linear',
            pointerEvents: email ? undefined : 'none',
          }}
        >
          <div className="top-20">Quick connect url:</div>
          <InputGroup className="top-10">
            <FormControl
              type="text"
              readOnly
              value={`${protocol}//${host}/devtool/signin?email=${
                email |> encodeURIComponent
              }`}
              className="flex-grow-1 color-neutral500"
            />
            <InputGroup.Button>
              <CopyToClipboard
                text={`${protocol}//${host}/devtool/signin?email=${
                  email |> encodeURIComponent
                }`}
              >
                <Button>Copy</Button>
              </CopyToClipboard>
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button bsStyle="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button bsStyle="primary" onClick={submit}>
          Connect
        </Button>
      </Modal.Footer>
    </>
  )
}

export function useRecentAccounts(
  currentEmail,
  { limit = 3, showMe = false } = {}
) {
  const [recentAccounts, setRecentAccounts] = useState(
    localStorage.getItem('_octolyDebugRecentAccounts')
      ? JSON.parse(localStorage.getItem('_octolyDebugRecentAccounts'))
      : []
  )
  useEffect(() => {
    localStorage.setItem(
      '_octolyDebugRecentAccounts',
      JSON.stringify(recentAccounts)
    )
  }, [recentAccounts])
  useEffect(() => {
    if (!currentEmail) return
    let index = recentAccounts |> findIndex(acc => acc.email === currentEmail)
    if (index === -1) {
      setRecentAccounts([...recentAccounts, { email: currentEmail }])
      index = recentAccounts.length
    }
    setRecentAccounts(recentAccounts => [
      ...recentAccounts.slice(0, index),
      {
        ...recentAccounts[index],
        lastUsedDate: moment().format(),
      },
      ...recentAccounts.slice(index + 1),
    ])
  }, [currentEmail])
  const rejectMe = showMe
    ? noop => noop
    : accounts => accounts.filter(acc => acc.email !== currentEmail)
  return (
    recentAccounts
    |> orderBy(['lastUsedDate'], ['desc'])
    |> rejectMe
    |> slice(0, limit)
  )
}
