import React, { useState, useContext } from 'react'
import Campaign from '..'
import dummyCampaign from './dummyCampaign.json'
import { CreatorContext } from 'creators/CreatorProvider'
import flatten from 'lodash/flatten'
import { DebugBar } from 'shared/debug'
import { isInfluencer } from 'shared/platforms'
import TOGGLES from './toggles'
import fromPairs from 'lodash/fromPairs'

function useDebugToggles({ sourceData, togglesConfig }) {
  const [toggleStore, setToggleStore] = useState(
    togglesConfig.map(({ key }) => [key, 0]) |> fromPairs
  )
  const [data, setData] = useState(
    togglesConfig.reduce(
      (data, toggle) => toggle.proc(toggle.values[0])(data),
      sourceData
    )
  )
  return {
    data,
    toggles: togglesConfig.map(({ proc, ...toggle }) => ({
      ...toggle,
      index: toggleStore[toggle.key],
      onChange: newIndex => {
        setToggleStore({
          ...toggleStore,
          [toggle.key]: newIndex,
        })
        setData(proc(toggle.values[newIndex])(data))
      },
    })),
  }
}

function Debug() {
  const creatorContextValue = useContext(CreatorContext)

  const {
    data: {
      campaign,
      landing,
      proPreview,
      me,
      credits,
      orders,
      pre_approved,
      multiplePlatformAccounts,
    },
    toggles,
  } = useDebugToggles({
    sourceData: {
      campaign: dummyCampaign,
    },
    togglesConfig: TOGGLES,
  })

  const tweakedContextValue = {
    ...creatorContextValue,
    creator: me
      ? {
          ...creatorContextValue.creator,
          platform_accounts: multiplePlatformAccounts
            ? creatorContextValue.creator.platform_accounts.map(pa =>
                pa.platform |> isInfluencer
                  ? [
                      pa,
                      {
                        ...pa,
                        id: `${pa.id}-other`,
                        platform_entity: {
                          ...pa.platform_entity,
                          title: pa.platform_entity.title
                            .split('')
                            .reverse()
                            .join(''),
                        },
                      },
                    ]
                  : [pa]
              ) |> flatten
            : creatorContextValue.creator.platform_accounts,
          credits,
        }
      : undefined,
  }

  let applicability = undefined
  if (tweakedContextValue.creator) {
    applicability = {
      pre_approved,
      applicable_contracts: campaign.contracts.map(contract => ({
        id: contract.id,
        applicable_options: contract.options.map(option => ({
          id: option.id,
          applicable_platform_accounts:
            tweakedContextValue.creator.platform_accounts
              .filter(pa => pa.platform === option.platform)
              .map(pa => ({
                id: pa.id,
                type: pa.type,
                pre_approved,
              })),
        })),
      })),
      can_order: true,
    }
  }

  return (
    <CreatorContext.Provider value={tweakedContextValue}>
      <DebugBar>
        {toggles.map(({ key, name, values, index, onChange }) => (
          <Toggle key={key} values={values} index={index} onChange={onChange}>
            {name}
          </Toggle>
        ))}
      </DebugBar>
      <Campaign
        landing={landing}
        proPreview={proPreview}
        campaign={{ ...campaign, applicability }}
        orders={orders}
        refreshCampaign={() => {}}
        isRefreshing={false}
      />
    </CreatorContext.Provider>
  )
}

function Toggle({ children, values, index, onChange }) {
  const onOff = values.length === 2 && values.some(value => !value)
  return (
    <DebugBar.Button
      bsStyle={onOff && values[index] ? 'primary' : undefined}
      onClick={() => {
        onChange((index + 1) % values.length)
      }}
    >
      {children}
      {!onOff && ` ${index + 1} / ${values.length}`}
    </DebugBar.Button>
  )
}

export default Debug
