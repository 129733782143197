import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  DigitalProductInfo,
  AffiliateLinkInfo,
  PromoCodeInfo,
} from 'creators/common/components/CampaignInfo'
import { CampaignContext } from '.'

const Body = () => {
  const {
    campaign: {
      has_personalized_affiliate_links: hasPersonalizedAffiliateLinks,
      has_promo_code: hasPromoCode,
      has_personalized_promo_codes: hasPersonalizedPromoCodes,
      promo_code: promoCode,
      promo_code_title: promoCodeTitle,
      promo_code_description: promoCodeDescription,
      description,
    },
    order = {},
  } = useContext(CampaignContext)

  const promoCodeToDisplay = hasPersonalizedPromoCodes
    ? order.promo_code
    : promoCode

  return (
    <div>
      {order.digital_asset && (
        <DigitalProductInfo
          digitalAsset={order.digital_asset}
          digitalAssetUsage={order.digital_asset_usage}
        />
      )}
      {hasPersonalizedAffiliateLinks && order.personalized_affiliate_link && (
        <AffiliateLinkInfo link={order.personalized_affiliate_link} />
      )}
      {hasPromoCode && promoCodeToDisplay && (
        <PromoCodeInfo
          promoCode={promoCodeToDisplay}
          promoCodeTitle={promoCodeTitle}
          promoCodeDescription={promoCodeDescription}
        />
      )}
      <Description
        className="campaign-description mt-20 mb-30"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

const Description = styled.div`
  li {
    white-space: normal !important;
  }
`

export default Body
