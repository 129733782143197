import accessToken from '../accessToken'
import APIError from './APIError'
import FetchError from './FetchError'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'

const fetchAPI = async (url, opt = {}) => {
  let {
    useToken = true,
    useFormatAttributes = true,
    getOnlyJSON = true,
    ...fetchOptions
  } = opt

  let finalFetchOptions = { ...fetchOptions }
  const authenticityToken = document.querySelector(
    'meta[name="csrf-token"]'
  )?.content

  if (useToken)
    finalFetchOptions = _.merge(
      { headers: { 'Access-Token': accessToken } },
      finalFetchOptions
    )

  finalFetchOptions = _.merge(
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    finalFetchOptions
  )

  if (authenticityToken)
    finalFetchOptions.headers = {
      ...finalFetchOptions.headers,
      'X-CSRF-Token': authenticityToken,
    }

  if (
    finalFetchOptions.body &&
    finalFetchOptions.headers['Content-Type'] === 'application/json'
  ) {
    if (authenticityToken) {
      finalFetchOptions.body = {
        ...finalFetchOptions.body,
        authenticity_token: authenticityToken,
      }
    }
    finalFetchOptions.body = JSON.stringify(finalFetchOptions.body)
  }

  const urlParamsToAdd = {}
  if (useFormatAttributes) urlParamsToAdd['format'] = 'attributes'

  if (!isEmpty(urlParamsToAdd)) {
    const [beforeQuery, query] = url.split('?')
    const newQuery = qs.stringify({
      ...qs.parse(query),
      ...urlParamsToAdd,
    })
    url = beforeQuery
    if (newQuery) url += `?${newQuery}`
  }

  const response = await fetch(url, finalFetchOptions)
  await checkForErrors(response)
  if (response.status === 204) return
  return getOnlyJSON ? await response.json() : response
}

async function checkForErrors(fetchResponse) {
  if (!fetchResponse.ok) {
    if (
      fetchResponse.headers.has('Content-Type') &&
      fetchResponse.headers.get('Content-Type').includes('application/json')
    )
      throw new APIError(await fetchResponse.json())
    throw new FetchError(fetchResponse)
  }
}

export const withoutToken = (url, opt = {}) =>
  fetchAPI(url, { ...opt, useToken: false })

export function makeCachedFetchAPI(cache = {}) {
  return async (url, options = {}) => {
    const shouldIgnoreCache = options.shouldIgnoreCache || false
    delete options.shouldIgnoreCache

    // If the cache is not ignored and the URL is in the cache,
    // return the cached value
    if (!shouldIgnoreCache && url in cache) {
      return Promise.resolve(cache[url])
    }

    // Otherwise, fetch fresh data and cache it
    const result = await fetchAPI(url, options)
    if (result instanceof Response) {
      const json = await result.json()
      result.json = () => Promise.resolve(json)
    }

    cache[url] = result
    return result
  }
}

export { APIError, FetchError, checkForErrors }
export default fetchAPI
