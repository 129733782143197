import React, { useState } from 'react'
import { ArppBadgeCompact } from './Compact.js'
import { ArppBadgeLarge } from './Large.js'
import SimpleToolTip from 'shared/components/SimpleToolTip'
import moment from 'moment'

const TextCertificationSince = ({ arppCertifiedDate = null }) => {
  const [date] = useState(arppCertifiedDate)
  return (
    <>
      <span>{I18n.t('js.creators.arpp.certificate')}</span>
      <span css={{ display: 'block' }}>
        {I18n.t('js.creators.arpp.since', {
          date: moment(date).format('DD.MM.YY'),
        })}
      </span>
    </>
  )
}

const arppBadgeContent = (
  isCompact = false,
  height = 16,
  arppCertifiedDate = null,
  hasUnderText
) => (
  <div className="d-flex flex-column align-items-start">
    {isCompact ? (
      <ArppBadgeCompact height={height} />
    ) : (
      <>
        <ArppBadgeLarge height={height} />
        {hasUnderText && (
          <div className="mt-10 mb-0 tc-grayTextLight italic">
            <TextCertificationSince arppCertifiedDate={arppCertifiedDate} />
          </div>
        )}
      </>
    )}
  </div>
)

export const ArppBadge = ({
  isCompact = false,
  height = 16,
  tooltipOption = null,
  tooltipPosition = 'bottom',
  hasUnderText = false,
  arppCertifiedDate = null,
}) => (
  <div
    className="d-flex align-items-center"
    data-test={`arpp-badge-${isCompact ? 'compact' : 'large'}`}
  >
    {tooltipOption ? (
      <SimpleToolTip
        placement={tooltipPosition}
        text={
          tooltipOption === 1 && arppCertifiedDate ? (
            <TextCertificationSince arppCertifiedDate={arppCertifiedDate} />
          ) : (
            I18n.t('js.creators.arpp.certified')
          )
        }
      >
        {arppBadgeContent(isCompact, height, arppCertifiedDate, hasUnderText)}
      </SimpleToolTip>
    ) : (
      arppBadgeContent(isCompact, height, arppCertifiedDate, hasUnderText)
    )}
  </div>
)

export const ArppBadgeContainer = ({ children, className }) => (
  <div className={className}>{children}</div>
)
