import React, { useState, useEffect, useContext } from 'react'
import {
  InputGroup,
  DropdownButton,
  MenuItem,
  Modal,
  Button,
} from 'react-bootstrap'
import { SelfFetch as CampaignRulesList } from 'creators/common/components/CampaignRulesList'
import useMe from 'creators/useMe'
import intersection from 'lodash/intersection'
import { CampaignContext } from '../..'
import uniq from 'lodash/uniq'
import map from 'lodash/fp/map'
import flatMap from 'lodash/fp/flatMap'

function RulesButton() {
  const {
    campaign,
    campaign: { contracts, applicability },
  } = useContext(CampaignContext)
  const allCampaignPlatforms =
    contracts |> flatMap('options') |> map('platform') |> uniq
  const me = useMe()
  const selectablePlatforms = me
    ? intersection(
        applicability.applicable_contracts
          |> flatMap('applicable_options')
          |> map('platform')
          |> uniq,
        allCampaignPlatforms
      )
    : allCampaignPlatforms
  const [selectedPlatform, setSelectedPlatform] = useState(undefined)
  const [showRulesModal, setShowRulesModal] = useState(false)
  useEffect(() => {
    if (!showRulesModal) {
      setSelectedPlatform(undefined)
    }
  }, [showRulesModal])

  const platformToShowRulesFor =
    selectablePlatforms.length === 1 ? selectablePlatforms[0] : selectedPlatform

  return (
    <>
      <Button
        onClick={() => {
          setShowRulesModal(true)
        }}
        bsStyle="primary"
        block
      >
        {I18n.t('js.creators.campaigns.show.rules_and_conditions')}
      </Button>
      <Modal
        show={showRulesModal && !platformToShowRulesFor}
        onHide={() => setShowRulesModal(false)}
        className="modal-overlay-index-1"
        backdropClassName="modal-backdrop modal-backdrop-overlay-index-1"
      >
        <SelectPlatformModalContent
          selectablePlatforms={selectablePlatforms}
          onSubmit={setSelectedPlatform}
        />
      </Modal>
      <Modal
        show={showRulesModal && !!platformToShowRulesFor}
        onHide={() => setShowRulesModal(false)}
        className="modal-overlay-index-1"
        backdropClassName="modal-backdrop modal-backdrop-overlay-index-1"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('js.creators.campaigns.show.rules_and_conditions')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CampaignRulesList campaign={campaign} noRules />
        </Modal.Body>
      </Modal>
    </>
  )
}

function SelectPlatformModalContent({ selectablePlatforms, onSubmit }) {
  const [selectedPlatform, setSelectedPlatform] = useState(
    selectablePlatforms[0]
  )
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.t('js.creators.campaigns.show.rules.select_platform')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DropdownButton
          id="campaign-select-platform"
          componentClass={InputGroup.Button}
          title={selectedPlatform}
          className="d-flex justify-content-between align-items-center"
          block={true}
          onClick={e => e.stopPropagation()}
        >
          {selectablePlatforms.map(selectablePlatform => (
            <MenuItem
              key={selectablePlatform}
              onClick={() =>
                setSelectedPlatform(selectablePlatform || undefined)
              }
              active={selectablePlatform === selectedPlatform}
            >
              {I18n.t(
                `js.creators.campaigns.show.choose_platform.${selectablePlatform}`
              )}
            </MenuItem>
          ))}
        </DropdownButton>
        <Button
          onClick={() => {
            onSubmit(selectedPlatform)
          }}
          bsStyle="primary"
          block
          className="top-10"
          disabled={!selectedPlatform}
        >
          {I18n.t('js.creators.campaigns.show.rules.submit_platform')}
        </Button>
      </Modal.Body>
    </>
  )
}

export default RulesButton
