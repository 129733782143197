import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components'

import { BaseInput } from './BaseInput'
import { InputFeedback } from './InputFeedback'

function ControlledTextInput({
  threshold,
  minMax,
  className,
  name,
  id,
  error,
  icon, // SVG Component e.g. <Icon />
  value,
  onChange,
  onBlur,
  required,
  innerRef,
  autoFocus = false,
  ...props
}) {
  const counterRef = useRef()
  const inputRef = useRef()
  const [lengthCheck, setLengthCheck] = useState()

  useEffect(() => {
    const ref = innerRef || inputRef
    if (autoFocus) ref.current.focus()
  }, [])

  useEffect(() => {
    if (threshold && minMax !== 'min' && minMax !== 'max') {
      throw new Error(
        '`minMax` must equal to `min` or `max` if `threshold` is provided'
      )
    }
    if (
      (!threshold || threshold === 0) &&
      (minMax === 'min' || minMax === 'max')
    ) {
      throw new Error(
        '`threshold` must be greater than 0 if `minMax` is provided'
      )
    }
  }, [threshold, minMax])

  const counterWidth = useMemo(() => {
    return counterRef.current
      ? counterRef.current.getBoundingClientRect().width |> Math.ceil
      : null
  }, [value])

  const shouldDisplayLengthCheck = value => {
    if (required && !value) {
      return I18n.t('js.errors.blank')
    }
    if (minMax === 'min') {
      return value.length >= threshold
        ? null
        : I18n.t('js.components.textareainput.too_short', {
            min_length: threshold,
            current_length: value.length,
          })
    } else if (minMax === 'max') {
      return value.length < threshold
        ? null
        : I18n.t('js.components.textareainput.too_long', {
            max_length: threshold,
            current_length: value.length,
          })
    } else {
      return null
    }
  }

  const onBlurCheck = e => {
    setLengthCheck(shouldDisplayLengthCheck(value))
    if (onBlur) {
      onBlur(e)
    }
  }

  const _onChange = e => {
    if (minMax === 'min') {
      setLengthCheck(null)
    } else {
      setLengthCheck(shouldDisplayLengthCheck(e.target.value))
    }
    onChange(e)
  }

  const displayError = error || lengthCheck

  return (
    <Container className={className}>
      <Input
        ref={innerRef || inputRef}
        type="text"
        paddingRight={counterWidth}
        name={name}
        id={id || name}
        error={displayError}
        hasIcon={!!icon}
        onBlur={onBlurCheck}
        value={value}
        onChange={_onChange}
        required={required}
        {...(threshold && minMax === 'max' && { maxLength: threshold })}
        {...props}
      />
      {icon && <Icon className="flex flex-center ml-16 mr-8">{icon}</Icon>}
      {!!threshold && (
        <Counter
          ref={counterRef}
          className="flex flex-center font-light tc-grayTextLight"
        >
          {value?.length || 0}/{threshold}
        </Counter>
      )}
      {displayError && <InputFeedback type="error" message={displayError} />}
    </Container>
  )
}

function UncontrolledTextInput({ defaultValue = '', ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledTextInput
      value={value}
      onChange={e => setValue(e.target.value)}
      {...props}
    />
  )
}

export const TextInput = React.forwardRef((props, ref) => {
  if (props.onChange) return <ControlledTextInput innerRef={ref} {...props} />
  return <UncontrolledTextInput innerRef={ref} {...props} />
})

const Container = styled.div`
  position: relative;
`

const Input = styled(BaseInput)`
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : '16px'};
  padding-left: ${({ hasIcon }) => (hasIcon ? '12px' : '16px')};
`

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${({ theme }) => theme.global.inputs.height};
  width: 24px;

  svg {
    width: 16px;
    height: 16px;
  }
`

const Counter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  height: ${({ theme }) => theme.global.inputs.height};
  padding: 0 16px;
`
