import { useState, useEffect } from 'react'
import useMe from './useMe'
import { isInstagramPlatform, PLATFORMS_CONFIG } from 'shared/platforms'

const ACCOUNTS = { instagram_accounts: [], tiktok_accounts: [] }

export default function useHasDisconnectedAccount() {
  const me = useMe()
  const [reconnect, setReconnect] = useState([false, ACCOUNTS])

  useEffect(() => {
    if (!!me) {
      const { platform_accounts } = me
      const accountsToReconnect = platform_accounts.reduce(
        (acc, { platform, status, has_credential, id, api_status }) => {
          if (
            isInstagramPlatform(platform) &&
            status === 'enabled' &&
            has_credential &&
            api_status === 'ko'
          ) {
            return {
              ...acc,
              instagram_accounts: [...acc.instagram_accounts, id],
            }
          }
          if (
            platform === PLATFORMS_CONFIG.tiktok.key &&
            status === 'enabled' &&
            has_credential &&
            api_status === 'ko'
          ) {
            return {
              ...acc,
              tiktok_accounts: [...acc.tiktok_accounts, id],
            }
          }

          return acc
        },
        { ...ACCOUNTS }
      )

      const accountsToReconnectCount = Object.keys(accountsToReconnect).reduce(
        (acc, current) => {
          return [...acc, ...accountsToReconnect[current]]
        },
        []
      ).length

      const hasAccountToReconnect = accountsToReconnectCount > 0

      setReconnect([hasAccountToReconnect, accountsToReconnect])
    }
  }, [])

  return [...reconnect]
}
