import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import OrderModalContent from './OrderModalContent'
import ConfirmEmailModalContent from 'creators/common/components/ConfirmEmailModalContent'
import ConfirmPhoneModal from 'creators/common/components/ConfirmPhoneModal'
import MissingFieldsFormModalContent from './MissingFieldsFormModalContent'
import { CampaignContext } from '..'
import useMe from 'creators/useMe'

function OrderFlow({ onHide }) {
  const {
    campaign: {
      applicability: {
        can_order,
        cannot_order_reason,
        cannot_order_reason_data,
      },
    },
    refreshCampaign,
    isRefreshing,
  } = useContext(CampaignContext)
  const me = useMe()

  return (
    <>
      <Modal bsSize="lg" show={can_order} onHide={onHide}>
        <OrderModalContent />
      </Modal>
      <Modal
        show={
          !isRefreshing &&
          !can_order &&
          cannot_order_reason === 'need_confirm_email'
        }
        onHide={onHide}
        bsSize="sm"
      >
        <ConfirmEmailModalContent
          onClose={onHide}
          defaultEmail={me.unconfirmed_email ?? me.email}
        />
      </Modal>
      <Modal
        show={
          !isRefreshing && !can_order && cannot_order_reason === 'missing_info'
        }
        onHide={onHide}
        bsSize="lg"
      >
        {!isRefreshing &&
          !can_order &&
          cannot_order_reason === 'missing_info' && (
            <MissingFieldsFormModalContent
              fields={
                cannot_order_reason_data &&
                cannot_order_reason_data.missing_info
              }
              onFinish={refreshCampaign}
            />
          )}
      </Modal>
      <Modal
        show={
          !isRefreshing &&
          !can_order &&
          cannot_order_reason === 'need_confirm_phone'
        }
        backdrop="static"
        bsSize="sm"
        onHide={onHide}
      >
        <ConfirmPhoneModal onClose={onHide} onFinish={refreshCampaign} />
      </Modal>
    </>
  )
}

export default OrderFlow
