import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import useIsMounted from 'shared/hooks/useIsMounted'
import { COLORS } from 'shared/scss'

import PlusCircle from 'assets/images/plus-circle.svg'
import Loader from '../Loader'

/**
  BUTTON PROPS:
    - color [String, optional] ['blue', 'dark', 'gray', 'red', 'green'] Default: 'primary'
    - outline [Boolean, optional]
    - size [String, optional] ['xs', 'sm', 'md', 'lg'] Default: 'md'
    - block [Boolean, optional]
    - disabled [Boolean, optional]
    - loading [Boolean, optional]
*/
function _Button(
  {
    children,
    loading: propsLoading = false,
    disabled = false,
    color,
    onClick,
    ...props
  },
  ref
) {
  const [localLoading, setLocalLoading] = useState(false)
  const isMounted = useIsMounted()
  const Component = useMemo(() => {
    if (props.href) return ButtonLinkContainer
    if (props.to) return ButtonRouterLinkContainer
    return ButtonContainer
  }, [props.href, props.to])

  const handleClick = async e => {
    if (typeof onClick === 'function') {
      setLocalLoading(true)
      try {
        await onClick(e)
      } finally {
        if (isMounted()) {
          setLocalLoading(false)
        }
      }
    }
  }

  const loading = propsLoading || localLoading

  let colorScheme
  switch (color) {
    case 'dark':
      colorScheme = {
        base: COLORS.dark,
        hover: COLORS.grayDarker,
      }
      break
    case 'gray':
      colorScheme = {
        base: COLORS.grayTextLight,
        hover: COLORS.grayDark,
      }
      break
    case 'red':
      colorScheme = {
        base: COLORS.redAlt,
        hover: COLORS.redAltDark,
      }
      break
    case 'green':
      colorScheme = {
        base: COLORS.greenMint,
        hover: COLORS.greenMintDark,
      }
      break
    case 'blue':
      colorScheme = {
        base: COLORS.blue,
        hover: COLORS.blueDark,
      }
      break
    case 'primary':
    default:
      colorScheme = {
        base: COLORS.primary,
        hover: COLORS.primary400,
      }
  }

  return (
    <Component
      ref={ref}
      loading={loading ? loading.toString() : undefined} // Avoid "Received `false/true` for a non-boolean attribute `loading`" warning
      disabled={disabled || loading}
      colors={colorScheme}
      onClick={handleClick}
      {...props}
    >
      <span style={loading ? { opacity: 0 } : {}}>{children}</span>
      {loading && (
        <LoaderContainer>
          <Loader size="1.2em" currentColor />
        </LoaderContainer>
      )}
    </Component>
  )
}

export const Button = React.forwardRef(_Button)

const BaseStyles = css`
  position: relative;
  font-size: 14px;
  line-height: 14px;
  outline: none;
  border-radius: 4px;
  border: 1px solid;
  transition: 250ms;
  user-select: none;
  width: ${({ block }) => (block ? '100%' : 'unset')};

  ${({ size }) => {
    switch (size) {
      case 'xs':
        return css`
          padding: 4px 8px;
        `
      case 'sm':
        return css`
          padding: 10px 16px;
        `
      case 'lg':
        return css`
          padding: 15px 24px;
          width: 208px;
        `
      case 'md':
      default:
        return css`
          padding: 15px 24px;
        `
    }
  }}

  ${({ colors: { base, hover }, outline, loading }) => {
    return css`
      background-color: ${outline ? 'white' : base};
      color: ${outline ? base : COLORS.white};
      border-color: ${base};
      text-decoration: none;

      &:hover {
        background-color: ${outline ? `${base}0F` : hover};
        border-color: ${hover};
        color: ${outline ? hover : COLORS.white};
        text-decoration: none;
      }

      &:active {
        transform: scale(0.9);
        text-decoration: none;
      }

      &:focus {
        color: ${outline ? base : COLORS.white};
        text-decoration: none;
      }

      &:disabled {
        background-color: ${outline ? 'white' : base};
        opacity: ${loading ? 1 : outline ? 0.3 : 0.2};
        cursor: not-allowed;
        color: ${outline ? base : COLORS.white};
        text-decoration: none;
      }
    `
  }};
`

const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.button`
  ${BaseStyles}
`
const ButtonLinkContainer = styled.a`
  ${BaseStyles}
`

const ButtonRouterLinkContainer = styled(Link)`
  ${BaseStyles}
`

function _AddElement({ text, className, ...props }, ref) {
  return (
    <AddElementContainer
      ref={ref}
      className={`
        d-flex
        flex-column
        align-items-center
        br-default
        hover-bs-default
        padding-12
        bgc-white
        ${className}
      `}
      {...props}
    >
      <PlusCircle width={24} height={24} />
      <p className="font-light mb-0 mt-8 fs-16 lh-18">{text}</p>
    </AddElementContainer>
  )
}

export const AddElement = React.forwardRef(_AddElement)
// TODO: Ask Aurore about disabled state
const AddElementContainer = styled.button`
  box-shadow: 0 0 0 1px ${COLORS.neutral100};
  outline: none;
  border: none;
  ${({ block }) => (block ? 'width: 100%;' : '')}

  &:hover {
    border: none;
  }

  &:active {
    transform: scale(0.98);
  }
`

function _InFormButton({ children, className, ...props }, ref) {
  return (
    <InFormButtonContainer
      ref={ref}
      className={`
        border-default
        br-default
        ${className}
      `}
      {...props}
    >
      {children}
    </InFormButtonContainer>
  )
}

export const InFormButton = React.forwardRef(_InFormButton)

const InFormButtonContainer = styled.button`
  outline: none;
  font-size: ${({ theme }) => theme.global.fontSize};
  line-height: ${({ theme }) => theme.global.fontSize};
  transition: ${({ theme }) => theme.global.transition};
  width: ${({ block }) => (block ? '100%' : 'unset')};
  color: ${COLORS.grayDarker};
  background-color: ${COLORS.white};

  ${({ size }) => {
    switch (size) {
      case 'xs':
        return css`
          padding: 8px 14px;
        `
      case 'sm':
        return css`
          padding: 10px 16px;
        `
      case 'lg':
        return css`
          padding: 15px 24px;
          width: 208px;
        `
      case 'md':
      default:
        return css`
          padding: 15px 24px;
        `
    }
  }}

  &:hover {
    background-color: ${COLORS.neutral100}99;
  }

  &:disabled {
    background-color: ${COLORS.white};
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.9);
  }
`
