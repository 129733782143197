import React, { useState, useContext, useEffect } from 'react'
import { CampaignContext } from '../..'
import useMe from 'creators/useMe'
import sumBy from 'lodash/fp/sumBy'
import every from 'lodash/fp/every'
import get from 'lodash/fp/get'
import byId from 'shared/fp/byId'
import byTypedId from 'shared/fp/byTypedId'
import { OrderContext } from '.'
import fetchAPI from 'shared/fetchAPI'
import showFlashMessage from 'creators/common/showFlashMessage'
import redirectWithFlashMessage from 'shared/redirectWithFlashMessage'
import useClassState from 'shared/hooks/useClassState'
import fbq from 'shared/fbq'

const generateOrderDeliveryAddress = mondialRelayChoice => ({
  Address1: mondialRelayChoice.Adresse1,
  Address2: mondialRelayChoice.Adresse2,
  City: mondialRelayChoice.Ville,
  CorporateName: mondialRelayChoice.Nom,
  CountryCode: mondialRelayChoice.Pays,
  PickupPointNumber: mondialRelayChoice.ID,
  ZipCode: mondialRelayChoice.CP,
})

export default function OrderProvider({ children }) {
  const me = useMe()
  const {
    campaign: {
      id: campaignId,
      is_paid,
      title: campaignTitle,
      applicability: { applicable_contracts },
    },
    selectedOptionIds,
  } = useContext(CampaignContext)
  const [
    application,
    { setContractId, setPlatformAccountIds, setPrice, setMessage },
  ] = useClassState(
    class {
      constructor() {
        this.state = {
          contractId: undefined,
          platformAccountIds: undefined,
          price: undefined,
          message: undefined,
        }
        if (applicable_contracts.length === 1) {
          this.setContractId(applicable_contracts[0].id)
        }
      }
      setContractId(contractId) {
        this.state = {
          ...this.state,
          contractId,
        }
        if (
          applicable_contracts
          |> byId(contractId)
          |> get('applicable_options')
          |> every(
            applicableOption =>
              applicableOption.applicable_platform_accounts.length === 1
          )
        ) {
          this.setPlatformAccountIds(
            (applicable_contracts |> byId(contractId)).applicable_options.map(
              option => [
                option.id,
                {
                  id: option.applicable_platform_accounts[0].id,
                  type: option.applicable_platform_accounts[0].type,
                },
              ]
            )
          )
        }
      }
      setPlatformAccountIds(platformAccountIds) {
        this.state = {
          ...this.state,
          platformAccountIds,
          price:
            platformAccountIds
            |> sumBy(
              ([optionId, typedId]) =>
                (me.platform_accounts |> byTypedId(typedId)).platform_entity
                  .paid_campaign_price || 0
            ),
        }
      }
      setPrice = true
      setMessage = true
    }
  )

  const { contractId, platformAccountIds, price, message } = application

  const [loading, setLoading] = useState(false)
  const [canOrder, setCanOrder] = useState(true)
  const submitOrder = async mondialRelayChoice => {
    // By default, the user receives product at home (Colissiomo access )
    // So backend already has the address
    let deliveryType = null
    let deliveryAddress = null

    if (mondialRelayChoice) {
      deliveryType = 'MRE'
      deliveryAddress = generateOrderDeliveryAddress(mondialRelayChoice)
    }

    setLoading(true)
    try {
      await fetchAPI(`/api/v3/orders`, {
        method: 'POST',
        body: {
          order: {
            campaign_id: campaignId,
            message: message,
            contract_id: contractId,
            order_contract_options_params: platformAccountIds.map(
              ([contractOptionId, typedId]) => ({
                contract_option_id: contractOptionId,
                platform_account_id: typedId.id,
                platform_account_type: typedId.type,
              })
            ),
            ...(is_paid && { price }),
            ...(Object.entries(selectedOptionIds).length && {
              product_option_ids: Object.values(selectedOptionIds),
            }),
            delivery_type: deliveryType,
            delivery_address: deliveryAddress,
          },
        },
      })
      fbq('trackCustom', 'OrderProduct', { campaignId, campaignTitle })
      redirectWithFlashMessage(`/creators/campaigns/search`, {
        type: 'notice',
        key: 'js.creators.campaigns.order_modal.order_created',
      })
    } catch (error) {
      if (error?.message === 'shadow_banned') {
        return redirectWithFlashMessage(`/creators/campaigns/search`, {
          type: 'notice',
          key: 'js.creators.campaigns.order_modal.shadow_banned',
        })
      }

      showFlashMessage('error', error?.message || I18n.t('js.global.error'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const can_order = await fetchAPI(`/api/v3/orders/can_order`)
        setCanOrder(can_order)
      } catch (error) {
        showFlashMessage('error', error?.message || I18n.t('js.global.error'))
      }
    })()
  }, [])

  return (
    <OrderContext.Provider
      value={{
        selectedOptionIds,
        contractId,
        setContractId,
        platformAccountIds,
        setPlatformAccountIds,
        price,
        setPrice,
        message,
        setMessage,
        submitOrder,
        loading,
        canOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}
