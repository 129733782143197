import React, { useState, useCallback } from 'react'

function RatioContainer({
  tag: Tag = 'div',
  ratio,
  children,
  style,
  ...props
}) {
  const [height, setHeight] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.clientWidth / ratio)
    }
  }, [])
  return (
    <Tag ref={measuredRef} style={{ height, ...style }} {...props}>
      {!!height && children}
    </Tag>
  )
}

export default RatioContainer
