import flatMap from 'lodash/fp/flatMap'
import dummyCampaign from './dummyCampaign.json'

const campaignSet = field => value => data => ({
  ...data,
  campaign: { ...data.campaign, [field]: value },
})
const productSet = field => value => data => ({
  ...data,
  campaign: {
    ...data.campaign,
    products: [
      {
        ...data.campaign.products[0],
        [field]: value,
      },
      ...data.campaign.products.slice(1),
    ],
  },
})

export default [
  {
    key: 'me',
    name: 'me',
    values: [true, false],
    proc: active => data => ({
      ...data,
      me: active,
    }),
  },

  {
    key: 'proPreview',
    name: 'Pro preview',
    values: [false, true],
    proc: active => data => ({ ...data, proPreview: active }),
  },

  {
    key: 'landing',
    name: 'Landing',
    values: [false, true],
    proc: active => data => ({ ...data, landing: active }),
  },

  {
    key: 'multiplePlatformAccounts',
    name: 'multiplePlatformAccounts',
    values: [false, true],
    proc: active => data => ({
      ...data,
      multiplePlatformAccounts: active,
    }),
  },

  {
    key: 'contracts',
    name: 'contracts',
    values: [
      // 1 insta
      [
        {
          id: 'contract-1',
          options: [
            {
              id: 'contract-option-1',
              platform: 'instagram',
              requested_reviews_count: 1,
            },
          ],
        },
      ],
      // 2 insta
      [
        {
          id: 'contract-1',
          options: [
            {
              id: 'contract-option-1',
              platform: 'instagram',
              requested_reviews_count: 2,
            },
          ],
        },
      ],
      // 1 insta or 1 yt
      [
        {
          id: 'contract-1',
          options: [
            {
              id: 'contract-option-1',
              platform: 'instagram',
              requested_reviews_count: 1,
            },
          ],
        },
        {
          id: 'contract-2',
          options: [
            {
              id: 'contract-option-2',
              platform: 'youtube',
              requested_reviews_count: 1,
            },
          ],
        },
      ],
      // 1 insta + 1 consumer
      [
        {
          id: 'contract-1',
          options: [
            {
              id: 'contract-option-1',
              platform: 'instagram',
              requested_reviews_count: 1,
            },
            {
              id: 'contract-option-2',
              platform: 'consumer',
              requested_reviews_count: 1,
            },
          ],
        },
      ],
      // 1 insta + 1 consumer or 1 story + 1 consumer
      [
        {
          id: 'contract-1',
          options: [
            {
              id: 'contract-option-1',
              platform: 'instagram',
              requested_reviews_count: 1,
            },
            {
              id: 'contract-option-2',
              platform: 'consumer',
              requested_reviews_count: 1,
            },
          ],
        },
        {
          id: 'contract-2',
          options: [
            {
              id: 'contract-option-3',
              platform: 'instagram_stories',
              requested_reviews_count: 1,
            },
            {
              id: 'contract-option-4',
              platform: 'consumer',
              requested_reviews_count: 1,
            },
          ],
        },
      ],
    ],
    proc: campaignSet('contracts'),
  },

  {
    key: 'bundle',
    name: 'Bundle',
    values: [true, false],
    proc: active => data => ({
      ...data,
      campaign: {
        ...data.campaign,
        products: [
          data.campaign.products[0],
          ...(active ? dummyCampaign.products.slice(1) : []),
        ],
      },
    }),
  },

  {
    key: 'options',
    name: 'Options',
    values: [
      [
        {
          id: 'hbm1j',
          name: 'Noir - Taille  L',
          picture:
            '/product/option/43241/picture/5ca79df9-addb-4132-8883-c81472528d1c.jpeg',
          rank: 1,
        },
      ],
      [
        {
          id: 'hbm1j',
          name: 'Noir - Taille  L',
          picture:
            '/product/option/43241/picture/5ca79df9-addb-4132-8883-c81472528d1c.jpeg',
          rank: 1,
        },
        {
          id: 'hbm3k',
          name: 'Vert - Taille  L',
          picture:
            'https://octoly-staging.imgix.net/product/option/43314/picture/2c9738e1-4408-4fe1-b0fc-fd4698a5da5e.jpeg',
          rank: 2,
        },
        {
          id: 'hbm1k',
          name: 'Vert - Taille S',
          picture:
            '/product/option/43242/picture/043c6aa4-8e9f-4946-8f41-0a2c41818152.jpeg',
          rank: 3,
        },
      ],
    ],
    proc: options => data => ({
      ...data,
      campaign: {
        ...data.campaign,
        products: [
          {
            ...data.campaign.products[0],
            options,
          },
          ...data.campaign.products.slice(1),
        ],
        campaign_product_options: [
          ...options,
          ...(data.campaign.products.slice(1) |> flatMap('options')),
        ].map(option => ({
          id: `cpo-${option.id}`,
          product_option_id: option.id,
          quantity_left: 3,
          checked: true,
        })),
      },
    }),
  },

  {
    key: 'video',
    name: 'Video',
    values: [undefined, 'z735x769EHA'],
    proc: campaignSet('video_id'),
  },

  {
    key: 'subtitle',
    name: 'Subtitle',
    values: [
      `My super awesome subtitle, don't forget to subscribe! All super great stuff!!`,
      undefined,
    ],
    proc: campaignSet('subtitle'),
  },

  {
    key: 'product_link',
    name: 'product link',
    values: [`https://www.google.com/`, undefined],
    proc: productSet('link'),
  },

  {
    key: 'price',
    name: 'price',
    values: [19.9, undefined],
    proc: productSet('price'),
  },

  {
    key: 'digitalProduct',
    name: 'Digital product',
    values: [false, true],
    proc: active => data => ({
      ...data,
      campaign: {
        ...data.campaign,
        products: [
          {
            ...data.campaign.products[0],
            delivery_type: active ? 'digital' : 'physical',
          },
          ...data.campaign.products.slice(1),
        ],
      },
    }),
  },

  {
    key: 'is_paid',
    name: 'Paid',
    values: [false, true],
    proc: campaignSet('is_paid'),
  },

  {
    key: 'has_promo_code',
    name: 'Promocode',
    values: [false, true],
    proc: campaignSet('has_promo_code'),
  },

  {
    key: 'has_personalized_affiliate_links',
    name: 'Personalized affiliate links',
    values: [false, true],
    proc: campaignSet('has_personalized_affiliate_links'),
  },

  {
    key: 'pre_approved',
    name: 'Pre approved',
    values: [false, true],
    proc: active => data => ({ ...data, pre_approved: active }),
  },

  {
    key: 'status',
    name: 'Status',
    values: [
      'default',
      'orderStatus:fresh',
      'orderStatus:ordered',
      'orderStatus:pending',
      'orderStatus:approved',
      'orderStatus:declined',
      'orderStatus:processing',
      'orderStatus:received',
      'orderStatus:shipped',
      'orderStatus:reviewing',
      'orderStatus:reviewed',
      'orderStatus:expired',
      'orderStatus:canceled_after_ship',
      'approvedConsumer',
      'closed',
      'outOfStock',
      'noCreditLeft',
    ],
    proc: value => data => {
      data = {
        ...data,
        orders: [],
        credits: 3,
        campaign: {
          ...data.campaign,
          closed: false,
          out_of_stock: false,
          type: 'influencer',
        },
      }
      if (value.includes('orderStatus:')) {
        data = {
          ...data,
          orders: [
            {
              id: 'order-id',
              status: value.replace('orderStatus:', ''),
            },
          ],
        }
      }
      if (value === 'approvedConsumer') {
        data = {
          ...data,
          campaign: {
            ...data.campaign,
            type: 'consumer',
          },
          orders: [
            {
              id: 'order-id',
              status: 'approved',
            },
          ],
        }
      }
      if (value === 'closed') {
        data = {
          ...data,
          campaign: {
            ...data.campaign,
            closed: true,
          },
        }
      }
      if (value === 'outOfStock') {
        data = {
          ...data,
          campaign: {
            ...data.campaign,
            sold_out: true,
          },
        }
      }
      if (value === 'noCreditLeft') {
        data = {
          ...data,
          credits: 0,
        }
      }
      return data
    },
  },
]
