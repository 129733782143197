import React, { useState } from 'react'
import styled from 'styled-components'
import cn from 'classnames'

import { InputFeedback } from './InputFeedback'

import Plus from 'assets/images/plus-circle-thin.svg'
import Bin from 'assets/images/bin.svg'
import { COLORS } from 'shared/scss'

function ControlledImageUpload({
  value,
  onChange,
  accept = '.jpg, .jpeg, .jp2, .jpx, .gif, .png, .webp',
  maxSize = 25, // MB
  className,
  name,
  id,
  width = 320,
  height = 200,
  binSize = 45,
  buttonSize = 42,
  plusSize = 24,
  error,
  innerRef,
  hideLabel = false,
  disabled = false,
}) {
  const [uploadError, setUploadError] = useState()

  const setImage = e => {
    const file = e.target.files?.[0]
    if (file) {
      // Strictly validate file extension
      const allowedExtensions = new RegExp(
        `(${accept.replace(/,\s/g, '|')})$`,
        'i'
      )
      if (!allowedExtensions.test(file.name)) {
        setUploadError(I18n.t('js.components.imageupload.errors.wrong_format'))
        return false
      }

      const reader = new FileReader()
      reader.onload = readerEvent => {
        onChange(readerEvent.target.result)
      }

      if (file.size > maxSize * 1024 * 1024) {
        setUploadError(I18n.t('js.components.imageupload.errors.too_heavy'))
      } else {
        if (setUploadError) {
          setUploadError()
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const removeImage = () => {
    onChange(null)
    setUploadError()
  }

  const displayError = error || uploadError

  if (value)
    return (
      <div>
        <Preview
          className={cn('image-preview', className)}
          width={width}
          height={height}
        >
          <img src={value} width={width} height={height} />
          <div className="flex flex-center" onClick={removeImage}>
            <Bin width={binSize} height={binSize} />
          </div>
        </Preview>
        {displayError && (
          <InputFeedback
            type="error"
            message={displayError}
            css={`
              max-width: ${width}px;
            `}
          />
        )}
      </div>
    )

  return (
    <div>
      <Uploader
        displayError={displayError}
        className={`flex flex-center ${className}`}
        width={width}
        height={height}
        centerContent={hideLabel}
      >
        {!hideLabel && (
          <Text>{I18n.t('js.pro.campaigns.form.add_picture')}</Text>
        )}
        <IconContainer size={buttonSize} disabled={disabled}>
          <Plus width={plusSize} height={plusSize} />
        </IconContainer>
        <input
          ref={innerRef}
          disabled={disabled}
          type="file"
          accept={accept}
          onChange={setImage}
          name={name}
          id={id || name}
        />
      </Uploader>
      {displayError && (
        <InputFeedback
          type="error"
          message={displayError}
          css={`
            max-width: ${width}px;
          `}
        />
      )}
    </div>
  )
}

function UncontrolledImageUpload({ defaultValue, ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledImageUpload
      value={value}
      onChange={value => setValue(value)}
      {...props}
    />
  )
}

export const ImageUpload = React.forwardRef((props, ref) => {
  if (props.onChange) return <ControlledImageUpload innerRef={ref} {...props} />
  return <UncontrolledImageUpload innerRef={ref} {...props} />
})

const Uploader = styled.div`
  flex-direction: column;
  ${({ centerContent }) => `
    justify-content: ${centerContent ? 'center' : 'flex-start'};
  `}
  gap: 24px;
  position: relative;
  ${({ width, height }) => `
    width: ${width}px;
    height: ${height}px;
  `}
  border: 2px dashed ${COLORS.neutral300};
  border-radius: ${({ theme }) => theme.global.borderRadius};

  svg {
    transition: ${({ theme }) => theme.global.transition};
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;

    &: active;
    &:focus {
      outline: none;
    }
  }

  &:hover {
    svg {
      transform: scale(1.05);
    }
  }
`

const Preview = styled.div`
  position: relative;
  ${({ width, height }) => `
    width: ${width}px;
    height: ${height}px;
  `}

  &:hover {
    cursor: pointer;

    div {
      opacity: 1;
    }
  }

  img {
    border-radius: ${({ theme }) => theme.global.borderRadius};
    object-fit: cover;
    object-position: center;
  }

  div {
    transition: 300ms;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    border-radius: ${({ theme }) => theme.global.borderRadius};
    opacity: 0;
    user-select: none;
  }

  svg path {
    stroke: ${COLORS.white};
  }
`

export const Text = styled.p`
  color: ${COLORS.neutral700};
  font-size: 18px;
  line-height: 22px;
  font-family: ${({ theme }) => theme.global.fontFamilyRegular};
  margin-top: 20px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size, disabled }) => `
    width: ${size}px;
    height: ${size}px;
    background-color: ${disabled ? COLORS.neutral500 : COLORS.primary};
  `}
  border-radius: 4px;
`
