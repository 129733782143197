import qs from 'qs'

function imgixHost(environment) {
  switch (environment) {
    case 'production': {
      return 'octoly.imgix.net'
    }
    case 'development': {
      return 'octoly-dev.imgix.net'
    }
    case 'integration': {
      return 'im-integration.imgix.net'
    }
    default: {
      return `octoly-${environment}.imgix.net`
    }
  }
}

export function imgixURL(path, params) {
  const host = imgixHost(process.env.RAILS_ENV)
  const stringifiedParams = qs.stringify(params, { addQueryPrefix: true })

  return `https://${host}${path}${stringifiedParams}`
}
