// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--skp-rem-ratio: 1.6}", ""]);
// Exports
exports.locals = {
	"color-neutral50": "#fefdfb",
	"color-neutral100": "#f7f7f7",
	"color-neutral200": "#efefef",
	"color-neutral300": "#e1e1e1",
	"color-neutral400": "#afb2b8",
	"color-neutral500": "#838893",
	"color-neutral600": "#6f747e",
	"color-neutral700": "#565961",
	"color-neutral800": "#313235",
	"color-neutral900": "#02020a",
	"color-primary50": "#edf5ff",
	"color-primary100": "#c7deff",
	"color-primary200": "#94c2ff",
	"color-primary300": "#66a6ff",
	"color-primary400": "#338aff",
	"color-primary500": "#0073ff",
	"color-primary600": "#005cc7",
	"color-primary700": "#004594",
	"color-primary800": "#002e66",
	"color-primary900": "#001733",
	"color-secondary50": "#f8f4ff",
	"color-secondary100": "#e9dfff",
	"color-secondary200": "#cfbaff",
	"color-secondary300": "#bb9eff",
	"color-secondary400": "#9e76ff",
	"color-secondary500": "#8257ff",
	"color-secondary600": "#922bfa",
	"color-secondary700": "#8519c7",
	"color-secondary800": "#6d13a4",
	"color-secondary900": "#401a54",
	"color-orange-alt50": "#fff4ef",
	"color-orange-alt100": "#ffd9c5",
	"color-orange-alt200": "#ffbd99",
	"color-orange-alt300": "#ffa270",
	"color-orange-alt400": "#ff8036",
	"color-orange-alt500": "#ff8036",
	"color-orange-alt600": "#ff6911",
	"color-orange-alt700": "#d95102",
	"color-orange-alt800": "#a83e00",
	"color-orange-alt900": "#802900",
	"color-brown-alt50": "#fbf5ef",
	"color-brown-alt100": "#f9ebdd",
	"color-brown-alt200": "#f3e1cf",
	"color-brown-alt300": "#f2dcc6",
	"color-brown-alt400": "#f0d4b7",
	"color-brown-alt500": "#eecaa6",
	"color-brown-alt600": "#e7b98b",
	"color-brown-alt700": "#e1a970",
	"color-brown-alt800": "#d29659",
	"color-brown-alt900": "#ca7f32",
	"color-sky-alt50": "#f4fdff",
	"color-sky-alt100": "#ebfbff",
	"color-sky-alt200": "#daf8ff",
	"color-sky-alt300": "#cef5ff",
	"color-sky-alt400": "#bef2ff",
	"color-sky-alt500": "#b0ebff",
	"color-sky-alt600": "#a3eafc",
	"color-sky-alt700": "#8de1f5",
	"color-sky-alt800": "#69d8f4",
	"color-sky-alt900": "#37c1e3",
	"color-success50": "#eafaf1",
	"color-success100": "#c9f3da",
	"color-success200": "#a8ebc3",
	"color-success300": "#86e4ac",
	"color-success400": "#65dc95",
	"color-success500": "#45d57f",
	"color-success600": "#28b360",
	"color-success700": "#1d8145",
	"color-success800": "#124f2a",
	"color-success900": "#071d10",
	"color-warning50": "#fff5dd",
	"color-warning100": "#ffedc0",
	"color-warning200": "#ffd682",
	"color-warning300": "#ffcc50",
	"color-warning400": "#ffbf22",
	"color-warning500": "#ffb529",
	"color-warning600": "#ffaf09",
	"color-warning700": "#cf8e06",
	"color-warning800": "#a67309",
	"color-warning900": "#804f1a",
	"color-error50": "#fff5f8",
	"color-error100": "#ffdae4",
	"color-error200": "#ffbac9",
	"color-error300": "#ffa5a5",
	"color-error400": "#ff8573",
	"color-error500": "#ff5c45",
	"color-error600": "#f53434",
	"color-error700": "#df1723",
	"color-error800": "#b50e36",
	"color-error900": "#801442",
	"color-shade0": "#fff",
	"color-shade1000": "#000",
	"color-dark-mode50": "#383838",
	"color-dark-mode100": "#363636",
	"color-dark-mode200": "#333",
	"color-dark-mode300": "#2e2e2e",
	"color-dark-mode400": "#2c2c2c",
	"color-dark-mode500": "#272727",
	"color-dark-mode600": "#252525",
	"color-dark-mode700": "#232323",
	"color-dark-mode800": "#1e1e1e",
	"color-dark-mode900": "#121212",
	"color-primary": "#0073ff",
	"color-secondary": "#8257ff",
	"color-black": "#000",
	"color-blue-dark-plus": "#1a2c79",
	"color-blue-dark": "#323657",
	"color-blue-darker": "#272a42",
	"color-blue-light-extra": "#edf9f4",
	"color-blue-light-plus": "#e7f5f9",
	"color-blue-light": "#3b91ed",
	"color-blue-lighter-plus": "#abb6ca",
	"color-blue-lighter": "#94b3d4",
	"color-blue": "#3045a1",
	"color-bronze-lighter": "#ffe48c",
	"color-bronze": "#97770f",
	"color-cyan": "#42e3f1",
	"color-dark": "#17212f",
	"color-facebook": "#3b5998",
	"color-gray-2": "#8f8f8f",
	"color-gray-black": "#333",
	"color-gray-dark": "#666",
	"color-gray-darker": "#333",
	"color-gray-light-plus": "#e8ebed",
	"color-gray-light": "#ccc",
	"color-gray-lighter": "#f5f8fa",
	"color-gray-text-light": "#999",
	"color-gray": "#a0a0a0",
	"color-green-2": "#1da57c",
	"color-green-dark": "#009961",
	"color-green-light": "#74e0b9",
	"color-green-lighter": "#e1fbf1",
	"color-green-mint-dark": "#54b393",
	"color-green-mint": "#68e3b4",
	"color-green-pastel": "#f0f9f5",
	"color-green-positive": "#15d46b",
	"color-green": "#00cd82",
	"color-instagram": "#405de6",
	"color-lime-light": "#61e6c9",
	"color-orange-2": "#ff600a",
	"color-orange-light": "#ff9400",
	"color-orange": "#f5a623",
	"color-pink-dark": "#ff7e75",
	"color-pink": "#f142c2",
	"color-pinterest": "#bd081c",
	"color-red-alt-dark": "#b4191d",
	"color-red-alt": "#e81717",
	"color-red-dark": "#9e0214",
	"color-red-light": "#ffb5be",
	"color-red-lighter": "#ffe2e2",
	"color-red": "#d0021b",
	"color-silver": "#99a6ab",
	"color-skype": "#12a5f4",
	"color-tea-lighter": "rgba(92,236,181,.1)",
	"color-tea": "#27cea9",
	"color-transparent": "transparent",
	"color-turquoise": "#7acfe5",
	"color-twitter": "#55acee",
	"color-white": "#fff",
	"color-yellow-dark": "#f5a623",
	"color-yellow-light": "#fcf3d4",
	"color-yellow-lighter": "rgba(248,231,28,.1)",
	"color-yellow": "#ffa800",
	"color-youtube": "red",
	"screen-xs": "480px",
	"screen-sm": "768px",
	"screen-md": "992px",
	"screen-lg": "1200px"
};
module.exports = exports;
