import React, {
  useEffect,
  createContext,
  useState,
  useCallback,
  useRef,
} from 'react'
import BACKEND_VALUES from 'creators/common/backendValues'
import { buildFlashMessage } from '.'

export const FlashMessageContext = createContext()

const INITIAL_FLASH_MESSAGE =
  BACKEND_VALUES &&
  BACKEND_VALUES.flashMessages &&
  BACKEND_VALUES.flashMessages.length
    ? BACKEND_VALUES.flashMessages[0]
    : null

function FlashMessageProvider({ children }) {
  const [flashMessages, setFlashMessages] = useState(
    INITIAL_FLASH_MESSAGE ? [INITIAL_FLASH_MESSAGE |> buildFlashMessage] : []
  )
  const [show, setShow] = useState(false)
  const showFlashMessage = useCallback((...args) => {
    setFlashMessages(flashMessages => [
      ...flashMessages,
      buildFlashMessage(...args),
    ])
  }, [])
  const lastFlashMessage = flashMessages.length
    ? flashMessages[flashMessages.length - 1]
    : null

  const disappearTimeoutRef = useRef(null)

  useEffect(() => {
    if (lastFlashMessage) {
      if (disappearTimeoutRef.current) {
        clearTimeout(disappearTimeoutRef.current)
        disappearTimeoutRef.current = null
      }

      setShow(true)

      if (!lastFlashMessage.props.preventAutoClose) {
        disappearTimeoutRef.current = setTimeout(() => {
          setShow(false)
          disappearTimeoutRef.current = null
        }, 6000)
      }
    }
  }, [lastFlashMessage])

  useEffect(() => {
    const listener = function (e) {
      showFlashMessage(...e.detail)
    }
    document.addEventListener('showFlashMessage', listener)
    return () => {
      document.removeEventListener('showFlashMessage', listener)
    }
  }, [flashMessages.length])

  const currentFlashMessage = show ? lastFlashMessage : null
  const closeFlashMessage = useCallback(() => {
    setShow(false)
    if (disappearTimeoutRef.current) {
      clearTimeout(disappearTimeoutRef.current)
      disappearTimeoutRef.current = null
    }
  }, [])

  return (
    <FlashMessageContext.Provider
      value={{
        showFlashMessage,
        currentFlashMessage,
        closeFlashMessage,
      }}
    >
      {children}
    </FlashMessageContext.Provider>
  )
}

export default FlashMessageProvider
