import React, { useEffect, useState, useRef, useMemo } from 'react'
import styled from 'styled-components'
import { BaseInput as Input } from 'shared/components/UIkit'
import useForceRerender from 'shared/useForceRerender'
import _Cross from 'assets/images/cross.svg'
import { Loader } from '@googlemaps/js-api-loader'
import { InputFeedback } from './UIkit/inputs/InputFeedback'

const DISTANCE_UNIT_MULTIPLIER = {
  km: 1,
  mile: 1.61,
}

const formattedPlace = place => {
  const { place_id, formatted_address, geometry } = place
  return {
    id: place_id,
    formatted_address: formatted_address,
    geometry: {
      location: geometry.location.toJSON(),
      viewport: geometry.viewport.toJSON(),
    },
  }
}

export default function PlacesAutocomplete({
  locations: _locations = [],
  country,
  distanceUnit = 'km', // all hail metric system \o/ \o/ \o/ — Ugo C. 2019 ©
  onChange,
  adminVersion = false,
  name, // only for admin version
}) {
  const forceRerender = useForceRerender()
  const [displayLocationError, setDisplayLocationError] = useState(false)
  const inputRef = useRef()
  const locations = useRef(onChange ? _locations || [] : [])
  const setLocations = newLocations => (locations.current = newLocations)
  const distanceMultiplier = useMemo(
    () => 1000 * DISTANCE_UNIT_MULTIPLIER[distanceUnit],
    [distanceUnit]
  )
  const PlaceItemComponentToUse = useMemo(
    () => (adminVersion ? PlaceItemAdmin : PlaceItem),
    [adminVersion]
  )

  useEffect(() => {
    ;(async () => {
      const google = await new Loader({
        apiKey: window.GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      }).load()

      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['(regions)'],
          ...(country ? { componentRestrictions: { country: [country] } } : {}),
        }
      )

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace()
        const selectedCountry =
          place.address_components.length === 1 &&
          place.address_components[0].types.includes('country')

        if (selectedCountry) {
          inputRef.current.style.borderColor = 'red'
          inputRef.current.style.color = 'red'
          setDisplayLocationError(true)
          return
        }
        inputRef.current.style.borderColor = ''
        inputRef.current.style.color = ''
        setDisplayLocationError(false)

        if (
          place.place_id &&
          !locations.current.find(({ id }) => id === place.place_id)
        ) {
          addPlace(place)
        }
      })
    })()
  }, [])

  const preventPressEnter = e => {
    if (e.keyCode === 13) e.preventDefault()
  }

  const handleChange = newLocations => {
    setLocations(newLocations)
    if (onChange) {
      onChange(newLocations)
    } else {
      forceRerender()
    }
  }

  const addPlace = place => {
    inputRef.current.value = ''
    const newLocations = [formattedPlace(place), ...locations.current]
    handleChange(newLocations)
  }

  const removePlace = placeId => {
    const newLocations = locations.current.filter(({ id }) => id !== placeId)
    handleChange(newLocations)
  }

  return (
    <div>
      {adminVersion ? (
        <input
          type="text"
          className="form-control"
          placeholder={I18n.t('js.pro.campaigns.audience.enter_a_location')}
          ref={inputRef}
          onKeyDown={preventPressEnter}
        />
      ) : (
        <>
          <Input
            type="text"
            placeholder={I18n.t('js.pro.campaigns.audience.enter_a_location')}
            ref={inputRef}
            onKeyDown={preventPressEnter}
          />
          {displayLocationError && (
            <InputFeedback
              type="error"
              message={I18n.t('js.pro.campaigns.audience.country.error')}
            />
          )}
        </>
      )}
      <Places
        locations={locations.current}
        distanceMultiplier={distanceMultiplier}
        PlaceItemComponentToUse={PlaceItemComponentToUse}
        handleChange={handleChange}
        distanceUnit={distanceUnit}
        removePlace={removePlace}
        name={name}
      />
    </div>
  )
}

const Places = ({
  locations,
  distanceMultiplier,
  PlaceItemComponentToUse,
  handleChange,
  distanceUnit,
  removePlace,
  name,
}) => {
  return (
    <>
      {locations.map((place, index) => {
        const distanceToShow =
          typeof place.radius === 'number' && !isNaN(place.radius)
            ? Math.round(place.radius / distanceMultiplier)
            : undefined

        return (
          <PlaceItemComponentToUse
            key={place.id}
            index={index}
            place={place}
            value={distanceToShow ? distanceToShow.toString() : ''}
            onChange={value => {
              const newLocations = [
                ...locations.slice(0, index),
                {
                  ...locations[index],
                  radius: isNaN(parseInt(value))
                    ? null
                    : Math.round(
                        Math.min(parseInt(value), 200) * distanceMultiplier
                      ),
                },
                ...locations.slice(index + 1),
              ]

              handleChange(newLocations)
            }}
            distanceUnit={distanceUnit}
            distanceToShow={distanceToShow}
            onRemove={() => removePlace(place.id)}
            name={name}
          ></PlaceItemComponentToUse>
        )
      })}
    </>
  )
}

const PlaceItem = ({
  value,
  place,
  onChange,
  onRemove,
  distanceUnit,
  distanceToShow,
}) => (
  <PlaceItemContainer className="top-8 d-flex" key={place.id}>
    <PlaceItemAddress className="font-light color-gray-darker">
      {place.formatted_address}
    </PlaceItemAddress>
    <div className="right-16">
      <AroundText distanceUnit={distanceUnit} distanceToShow={distanceToShow}>
        <InputRadius
          type="text"
          size={4}
          placeholder="0"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </AroundText>
    </div>
    <div className="d-flex flex-center right-8" onClick={onRemove}>
      <Cross width={8} height={8} />
    </div>
  </PlaceItemContainer>
)

const PlaceItemContainer = styled.div`
  border: ${({ theme }) => theme.global.inputs.border};
  border-radius: ${({ theme }) => theme.global.inputs.borderRadius};
  background: ${({ theme }) => theme.global.inputs.backgroundColor};
  padding: 10px 8px;
`

const PlaceItemAddress = styled.div`
  flex-grow: 1;
  font-size: 14px;
  line-height: 20px;
`

const InputRadius = styled(Input)`
  text-align: right;
  width: unset;
  height: unset;
  padding: 0 2px;
`

const Cross = styled(_Cross)`
  transition: 150ms;

  &:hover {
    transform: scale(1.2);
  }
`

const PlaceItemAdmin = ({
  value,
  place,
  onChange,
  onRemove,
  distanceUnit,
  distanceToShow,
  index,
  name,
}) => (
  <div
    className="padding-3 border-color-gray-light top-4"
    style={{
      display: 'flex',
      alignItems: 'center',
      border: 'solid 1px',
      borderRadius: 4,
    }}
  >
    <div style={{ flex: '1 1 auto' }}>
      <div>{place.formatted_address}</div>
      <div>
        <AroundText distanceUnit={distanceUnit} distanceToShow={distanceToShow}>
          <span className="form-inline form-group-sm">
            <input
              type="text"
              className="form-control"
              size={4}
              style={{
                textAlign: 'right',
                height: 24,
                padding: '0 5px',
              }}
              placeholder="0"
              value={value}
              onChange={e => {
                onChange(e.target.value)
              }}
            />
          </span>
        </AroundText>
      </div>
    </div>
    <div
      className="close left-5 right-5"
      onClick={() => {
        onRemove()
      }}
    >
      &#x2715;
    </div>

    <input name={`${name}[${index}][id]`} type="hidden" value={place.id} />
    <input
      name={`${name}[${index}][formatted_address]`}
      type="hidden"
      value={place.formatted_address}
    />
    <input
      name={`${name}[${index}][geometry][location][lat]`}
      type="hidden"
      value={place.geometry.location.lat}
    />
    <input
      name={`${name}[${index}][geometry][location][lng]`}
      type="hidden"
      value={place.geometry.location.lng}
    />
    <input
      name={`${name}[${index}][geometry][viewport][south]`}
      type="hidden"
      value={place.geometry.viewport.south}
    />
    <input
      name={`${name}[${index}][geometry][viewport][west]`}
      type="hidden"
      value={place.geometry.viewport.west}
    />
    <input
      name={`${name}[${index}][geometry][viewport][north]`}
      type="hidden"
      value={place.geometry.viewport.north}
    />
    <input
      name={`${name}[${index}][geometry][viewport][east]`}
      type="hidden"
      value={place.geometry.viewport.east}
    />
    <input
      name={`${name}[${index}][radius]`}
      type="hidden"
      value={place.radius || ''}
    />
  </div>
)

const AroundText = ({ distanceUnit, distanceToShow, children }) => {
  const distance = distanceToShow || 0
  const distanceText = I18n.t(`js.distances.${distanceUnit}`, {
    count: distance,
  })
  const aroundText = I18n.t('js.pro.campaigns.audience.distance_around', {
    distance: distanceText,
  })
  const [textBefore, textAfter] = aroundText.split(distance.toString())
  return (
    <>
      {textBefore && <span className="small">{textBefore} </span>}
      {children}
      {textAfter && <span className="small"> {textAfter}</span>}
    </>
  )
}
