import React, { useEffect } from 'react'
import { imagePath } from 'shared/images'
import useAPI from 'shared/useAPI'
import cn from 'classnames'
import RulesSectionAlt from './RulesSectionAlt'

function CampaignRulesList({
  campaign,
  className,
  rules,
  noFtc = false,
  noRules = false,
  noSkeepersRules = false,
}) {
  const isStoreFtcAdvancedCheck = rules.ftc_check_level === 'ftc_advanced_check'

  return (
    <div className={className}>
      {!noFtc &&
        (isStoreFtcAdvancedCheck ? (
          <RulesSectionAlt
            rules={rules.legal_rules_us}
            logo={{
              image: imagePath('creators/transparency-rules.png'),
              brand: false,
            }}
            hint={
              rules.ftc_rules &&
              I18n.t(
                'js.creators.campaigns.order_modal.transparency_rules_hint'
              )
            }
            title={I18n.t(
              'js.creators.campaigns.order_modal.transparency_rules'
            )}
            ftc
            key="legalRulesSection"
          />
        ) : (
          <RulesSection
            rules={rules.transparency_rules}
            logo={{
              image: imagePath('creators/transparency-rules.png'),
              brand: false,
            }}
            hint={
              rules.ftc_rules &&
              I18n.t(
                'js.creators.campaigns.order_modal.transparency_rules_hint'
              )
            }
            title={I18n.t(
              'js.creators.campaigns.order_modal.transparency_rules'
            )}
          />
        ))}
      {!noRules && (
        <RulesSection
          rules={rules.rules}
          logo={{ image: campaign.store.logo_urls.medium, brand: true }}
          title={I18n.t('js.creators.campaigns.order_modal.store_rules', {
            store: campaign.store.title,
          })}
        />
      )}
      {!noSkeepersRules && (
        <RulesSection
          rules={rules.octoly_rules}
          logo={{ image: imagePath('creators/octoly-rules.png'), brand: false }}
          title={I18n.t('js.creators.campaigns.order_modal.octoly_rules')}
        />
      )}
    </div>
  )
}

export const campaignRulesUriBuilder = ({
  campaignId,
  consumerReviewUrlId,
  platforms,
}) => {
  let uri = `/api/v3/campaigns/${campaignId}/rules?`
  if (platforms) uri += `platforms=${platforms}`
  if (consumerReviewUrlId)
    uri += `&consumer_review_url_id=${consumerReviewUrlId}`

  return uri
}

export function SelfFetch(props) {
  const uri = campaignRulesUriBuilder({
    campaignId: props.campaign.id,
    consumerReviewUrlId: props.consumer_review_url_id,
    platforms: props.platforms,
  })

  const { data: rules, loading } = useAPI(uri)

  useEffect(() => {
    if (props.onLoad && rules && !loading) {
      props.onLoad(rules)
    }
  }, [rules, loading])

  if (!rules) return null

  return <CampaignRulesList {...props} rules={rules} />
}

function RulesSection({
  rules,
  hint,
  logo: { image, brand },
  title,
  bulletImg = imagePath('list_bullet_green.png'),
}) {
  if (!rules?.length) {
    return null
  }

  const iconSize = brand ? 32 : 20

  return (
    <div className="rules-section">
      <div className="media bottom-10 d-flex align-items-baseline">
        <div className="media-left">
          <img
            className={cn({ 'img-circle': brand, bordered: brand })}
            src={image}
            width={iconSize}
            height={iconSize}
          />
        </div>
        <div className="media-body">
          <h5 className="margin-0">{title}</h5>
        </div>
      </div>
      {hint && (
        <p
          className="alert color-bg-yellow-dark color-white border-radius-4"
          dangerouslySetInnerHTML={{ __html: hint }}
        />
      )}
      {rules.map((rule, i) => (
        <div key={i} className="media pbottom-10 ptop-10 top-0">
          <div className="media-left">
            <img
              src={bulletImg}
              className="media-object top-3"
              width="12"
              height="12"
            />
          </div>
          <div className="media-body">
            <div dangerouslySetInnerHTML={{ __html: rule }} />
          </div>
        </div>
      ))}
    </div>
  )
}
