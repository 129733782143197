import React, { useState, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { TextInput } from './TextInput'
import { InFormButton } from '../Buttons'
import { InputFeedback } from './InputFeedback'
import { COLORS } from 'shared/scss'

import Bin from 'assets/images/bin.svg'
import HandleDrag from 'assets/images/handle-drag.svg'
import Plus from 'assets/images/plus.svg'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getListStyle = listLength => {
  return {
    height: listLength * 60,
  }
}

const getItemStyle = (isDragging, draggableStyle) => {
  const { left = 0, top = 0 } = draggableStyle
  const parentModal = document.querySelector('.modal-dialog')

  let offset = {
    left,
    top,
  }

  if (parentModal && isDragging) {
    const { x, y } = parentModal.getBoundingClientRect()
    offset = {
      left: left - x,
      top: top - y,
    }
  }

  return {
    ...draggableStyle,
    userSelect: 'none',
    ...offset,
  }
}

export function ControlledListInput({
  className,
  value = [], // Shape: [{ value: String, deletable: Boolean (optional), ...whatever }]
  onChange,
  maxCharacters,
  max,
  ctaWording = I18n.t('js.components.listinput.default_cta_wording'),
  clearable = false,
  placeholder = '',
  id = 'droppable',
  error,
}) {
  const listRef = useRef()
  const addButtonRef = useRef()
  const [mounted, setMounted] = useState(false)
  const [prevLength, setPrevLength] = useState(value.length)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted && listRef.current && value.length > prevLength) {
      listRef.current.childNodes[value.length > 0 ? value.length - 1 : 0]
        .querySelector('input')
        .focus()

      addButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    setPrevLength(value.length)
  }, [value.length])

  const disabledButton = useMemo(
    () =>
      (value.length > 0 && value[value.length - 1].value === '') ||
      value.length === max,
    [value, max]
  )

  const onRemove = i => {
    const newValue = value.filter((_, index) => i !== index)
    onChange(newValue)
  }

  const onInputChange = (inputValue, i) => {
    const newValue = [...value]
    newValue[i] = {
      ...newValue[i],
      value: inputValue,
    }
    onChange(newValue)
  }

  const onAdd = () => {
    if (!disabledButton) {
      onChange([...value, { value: '', deletable: true }])
    }
  }

  const onInputBlur = i => {
    const currentInputValue = value[i].value
    if (currentInputValue === '') onRemove(i)
  }

  const onKeyDown = keyCode => {
    if (keyCode === 13) {
      onAdd()
    }
  }

  const onDragEnd = result => {
    if (result.destination) {
      const reorderedValues = reorder(
        value,
        result.source.index,
        result.destination.index
      )

      onChange(reorderedValues)
    }
  }

  const onClear = () => {
    onChange(value.filter(item => !item.deletable))
  }

  return (
    <div className={className}>
      {value.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={id}>
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(value.length)}
              >
                <div ref={listRef} className="bottom-16">
                  {value.map((item, i) => (
                    <Draggable
                      key={`ListInput-${id}-${i}`}
                      draggableId={`item-${id}-${i}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="flex"
                          tabIndex="-1"
                        >
                          <Drag
                            className="flex flex-center"
                            isDragging={snapshot.isDragging}
                          >
                            <HandleDrag />
                          </Drag>
                          <TextInput
                            name={`listInput-${id}-${i}-input`}
                            className="fullwidth"
                            threshold={
                              maxCharacters ? maxCharacters : undefined
                            }
                            minMax={maxCharacters ? 'max' : undefined}
                            value={item.value}
                            onChange={e => onInputChange(e.target.value, i)}
                            onKeyDown={e => onKeyDown(e.keyCode)}
                            onBlur={() => onInputBlur(i)}
                            autoComplete="off"
                            disabled={!item.deletable}
                            placeholder={placeholder}
                          />
                          <Trash
                            className="flex flex-center"
                            onClick={() => {
                              if (item.deletable) onRemove(i)
                            }}
                            disabled={!item.deletable}
                          >
                            <Bin width={16} height={16} />
                          </Trash>
                        </Item>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {error && (
        <div css="margin-top: -24px;" className="mb-8">
          <InputFeedback type="error" message={error} />
        </div>
      )}
      <Add
        key={`add-btn-${id}`}
        className="caption ff-bold tc-neutral900 my-0 d-flex-inline"
        ref={addButtonRef}
        onClick={onAdd}
        disabled={disabledButton}
      >
        <Plus width={8} className="mr-4" />
        {ctaWording}
      </Add>
      {clearable && (
        <InFormButton
          key={`clear-btn-${id}`}
          size="sm"
          onClick={onClear}
          disabled={value.length === 0}
          className="ml-16"
        >
          {I18n.t('js.components.listinput.clear_all_items')}
        </InFormButton>
      )}
    </div>
  )
}

function UncontrolledListInput({ defaultValue = [], ...props }) {
  const [value, setValue] = useState(defaultValue)

  return (
    <ControlledListInput
      value={value}
      onChange={newValue => setValue(newValue)}
      {...props}
    />
  )
}

export const ListInput = props => {
  if (props.onChange) return <ControlledListInput {...props} />
  return <UncontrolledListInput {...props} />
}

const Item = styled.div`
  position: relative;

  & + * {
    margin-top: 16px;
  }
`

const Trash = styled.div`
  width: 44px;
  height: ${({ theme }) => theme.global.inputs.height};
  border: ${({ theme }) => theme.global.inputs.border};
  background-color: ${({ theme }) => theme.global.inputs.backgroundColor};
  border-radius: ${({ theme }) => theme.global.borderRadius};
  transition: ${({ theme }) => theme.global.transition};
  margin-left: 4px;
  cursor: pointer;
  ${({ disabled, theme }) => {
    if (disabled)
      return css`
        opacity: 0.4;
        cursor: not-allowed;
        border: ${theme.global.inputs.border};
      `
  }}

  &:hover {
    border: ${({ theme }) => theme.global.inputs.hover.border};
  }

  &:active {
    border: ${({ theme }) => theme.global.inputs.focus.border};
  }
`

const Drag = styled.div`
  position: absolute;
  top: 0;
  left: -26px;
  width: 26px;
  height: ${({ theme }) => theme.global.inputs.height};
  opacity: ${({ isDragging }) => (isDragging ? 1 : 0)};
  transition: ${({ theme }) => theme.global.transition};

  &:hover {
    cursor: grabbing;
    opacity: 1;
  }
`

const Add = styled.div`
  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        color: ${COLORS.neutral500};
      `
    } else {
      return css`
        cursor: pointer;
        color: ${COLORS.neutral900};
        transition: ${theme.global.transition};

        &:hover {
          transform: scale(1.02);
        }

        &:active {
          transform: scale(1);
        }
      `
    }
  }}
`
