import React, { useContext } from 'react'
import { ThemeProvider as Themeprovider, ThemeContext } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { THEME } from './variables'
import { BREAKPOINTS } from 'shared/scss'
import Utils from './utils'

/**
  SNIPPET TO ACCESS TO THEME PROP WITHIN A STYLED-COMPONENT:
  ${({ theme }) => theme.global.border};
*/

export default function ThemeProvider({ children }) {
  const formFactors = {
    isMobile: useMediaQuery({ maxWidth: BREAKPOINTS.sm }),
    isTablet: useMediaQuery({
      minWidth: BREAKPOINTS.sm,
      maxWidth: BREAKPOINTS.md,
    }),
    isDesktop: useMediaQuery({ minWidth: BREAKPOINTS.md }),
  }

  return (
    <Themeprovider theme={{ ...THEME, formFactors }}>
      <Utils />
      {children}
    </Themeprovider>
  )
}

export const useThemeContext = () => useContext(ThemeContext)
