import React, { useRef, useContext, useState } from 'react'
import cn from 'classnames'
import { Overlay, Popover } from 'react-bootstrap'
import { useFlag } from '@unleash/proxy-client-react'
import { CampaignContext } from '../..'
import { OrderContext } from '.'
import get from 'lodash/fp/get'
import byId from 'shared/fp/byId'
import sortBy from 'lodash/fp/sortBy'
import {
  PLATFORMS_CONFIG,
  platformOrder,
  hasInfluencer,
} from 'shared/platforms'
import map from 'lodash/fp/map'
import flatMap from 'lodash/fp/flatMap'
import toSentence from 'shared/fp/toSentence'
import useMe from 'creators/useMe'

export default function Conditions({ conditionsRef }) {
  const me = useMe()
  const { contractId } = useContext(OrderContext)
  const {
    campaign: { id, contracts, assignment_of_rights, store },
  } = useContext(CampaignContext)

  const [showMissing, setShowMissing] = useState(false)
  const termsContainerRef = useRef()

  const checkBoxesRef = useRef()
  checkBoxesRef.current = []

  conditionsRef.current = {
    allChecked: () =>
      !checkBoxesRef.current.some(checkbox => !checkbox.checked),
    showMissingConditionsError: () => {
      setShowMissing(true)
    },
  }

  const shouldShowBrandCompliancyLinks =
    store.brand_compliancy_links?.length > 0

  const platforms = (contracts |> byId(contractId)).options.map(
    ({ platform }) => platform
  )

  const contractsPlatforms = contracts |> flatMap('options') |> map('platform')

  const licensingRelevant =
    !!me && (platforms || contractsPlatforms |> hasInfluencer)

  const hideTermsError = () => setShowMissing(false)

  const getConsumerReviewsRequestText = ({
    requested_reviews_count,
    consumer_review_urls,
  }) => {
    const consumerReviewWithPhotosCount =
      consumer_review_urls?.filter(item => item.ask_photo).length || 0
    const hasConsumerReviewWithPhotos = consumerReviewWithPhotosCount > 0
    const doAllReviewsHavePhotos =
      requested_reviews_count === consumerReviewWithPhotosCount

    // NONE with photos
    if (!hasConsumerReviewWithPhotos) {
      return I18n.t(`platform_reviews.consumer`, {
        count: requested_reviews_count,
      })
    }

    // ALL with photos
    if (hasConsumerReviewWithPhotos && doAllReviewsHavePhotos) {
      return I18n.t(`platform_reviews.consumer_with_photos`, {
        count: requested_reviews_count,
      })
    }

    // At least one with photos
    return `${I18n.t(`platform_reviews.consumer`, {
      count: requested_reviews_count,
    })} ${I18n.t('platform_reviews.including_photos', {
      count: consumerReviewWithPhotosCount,
    })}`
  }

  return (
    <div
      onClick={hideTermsError}
      key={contractId}
      style={{
        borderTop: '1px #E8EBED solid',
      }}
    >
      {
        <Overlay
          placement="top"
          target={termsContainerRef.current}
          show={showMissing}
          rootClose
        >
          <Popover id="missing-conditions" placement="top">
            {I18n.t('js.creators.campaigns.order_modal.terms_validation')}
          </Popover>
        </Overlay>
      }
      <div
        id="conditions"
        ref={termsContainerRef}
        className={cn('campaign-terms', { 'border-color-red': showMissing })}
        data-trigger="manual"
        data-placement="top"
        data-toggle="popover"
        data-content={I18n.t(
          'js.creators.campaigns.order_modal.terms_validation'
        )}
      >
        {assignment_of_rights && (
          <Condition
            id="assignmentOfRights"
            __html={I18n.t('creators.campaigns.header.assignment_of_rights', {
              client: store.title,
              link: `<a href="/creators/campaigns/${id}/assignment_of_rights" target="blank" rel="noopener">${I18n.t(
                'creators.campaigns.header.terms_of_service'
              )}</a>`,
            })}
            checkBoxesRef={checkBoxesRef}
          />
        )}
        <Condition
          id="requestedViewCount"
          __html={I18n.t(
            'js.creators.campaigns.order_modal.requested_reviews_checkbox_label_v3',
            {
              requested_reviews_summary: (
                contracts
                |> byId(contractId)
                |> get('options')
                |> sortBy(option => option.platform |> platformOrder)
                |> map(option =>
                  option.platform === PLATFORMS_CONFIG.consumer.key
                    ? getConsumerReviewsRequestText(option)
                    : I18n.t(`platform_reviews.${option.platform}`, {
                        count: option.requested_reviews_count,
                      })
                )
                |> toSentence({ altAnd: true })
              ).replace('&', '&amp;'),
            }
          )}
          checkBoxesRef={checkBoxesRef}
        />
        {licensingRelevant && (
          <Condition
            id="licensing_checkbox"
            __html={I18n.t(
              'js.creators.campaigns.order_modal.licensing.label',
              {
                link: '/community-agreement',
              }
            )}
            checkBoxesRef={checkBoxesRef}
          />
        )}
        {shouldShowBrandCompliancyLinks && (
          <Condition
            id="brand_compliancy"
            __html={I18n.t(
              'js.creators.campaigns.order_modal.brand_compliancy_checkbox',
              {
                count: store.brand_compliancy_links.length,
                links: store.brand_compliancy_links
                  .map(
                    ({ url, name }) =>
                      `<a href="${url}" target="_blank" rel="noopener noreferrer" class="link primary">
                      ${name}
                    </a>`
                  )
                  .join(', '),
              }
            )}
            checkBoxesRef={checkBoxesRef}
          />
        )}
      </div>
    </div>
  )
}

const Condition = ({ checkBoxesRef, __html, id }) => (
  <div className="media">
    <div className="media-left">
      <input
        id={id}
        type="checkbox"
        ref={el => {
          if (el) checkBoxesRef.current.push(el)
        }}
      />
    </div>
    <div className="media-body">
      <label
        htmlFor={id}
        className="bottom-0"
        dangerouslySetInnerHTML={{ __html }}
      />
    </div>
  </div>
)
