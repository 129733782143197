import React, { useEffect, useContext, useState } from 'react'
import { FlashMessageContext } from 'creators/common/flashMessages/FlashMessageProvider'
import GreenValidatedCircle from 'assets/images/green-validated-circle.svg'
import RedRefusedCircle from 'assets/images/red-refused-circle.svg'
import Warning from 'assets/images/warning.svg'
import TransparentCross from 'assets/images/transparent-cross.svg'
import { COLORS } from 'shared/scss'

export default function NewFlashMessageLayout({
  type,
  message,
  subMessage,
  rightActions,
  closable = false,
  preventAutoClose = false,
}) {
  let Icon
  switch (type) {
    case 'success':
      Icon = GreenValidatedCircle
      break
    case 'warning':
      Icon = Warning
      break
    case 'error':
      Icon = RedRefusedCircle
      break
    default:
      throw new Error(`Type ${type} not implemented on NewFlashMessageLayout`)
  }
  const [animationClass, setAnimationClass] = useState('in')
  const { closeFlashMessage } = useContext(FlashMessageContext)

  useEffect(() => {
    if (!preventAutoClose) {
      setTimeout(() => {
        setAnimationClass('out')
      }, 5500)
    }
  })

  return (
    <div id="flash-message">
      <div
        className={`flash-message-${type} ${animationClass} slide-flash-message flex align-items-stretch`}
      >
        <div className="left-border" />
        <div className="ptop-15 pright-8 pbottom-0 pleft-12">
          <Icon />
        </div>
        <div className="flex flex-column align-items-start message-block">
          <p className="bottom-6 fs-16">{message}</p>
          {subMessage && <p className="fs-12">{subMessage}</p>}
        </div>
        {rightActions}
        {closable && !rightActions && (
          <div className="flex align-items-center pright-16 pleft-16">
            <TransparentCross
              style={{ fill: COLORS.success500, cursor: 'pointer' }}
              onClick={closeFlashMessage}
            />
          </div>
        )}
      </div>
    </div>
  )
}
