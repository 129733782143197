import React, { useState, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { CampaignContext } from '../..'
import { OrderContext } from '../../OrderFlow/OrderModalContent'
import OrderProvider from '../../OrderFlow/OrderModalContent/OrderProvider'
import withHOC from 'shared/fp/withHOC'
import OrderFlow from '../../OrderFlow'
import SimpleTooltip from 'shared/components/SimpleToolTip'
import moment from 'moment'
import { setLabelFullPublicationDate } from 'shared/publicationDate'
import useMe from 'creators/useMe'
import Loader from 'shared/components/Loader'

const OrderButton =
  function ({ onMissingOptionError }) {
    const { time_zone } = useMe()
    const {
      campaign: {
        is_paid,
        products,
        applicability: { pre_approved },
        start_at,
      },
      selectedOptionIds,
    } = useContext(CampaignContext)
    const { canOrder } = useContext(OrderContext)

    const [showOrderFlow, setShowOrderFlow] = useState(false)

    const handleClick = () => {
      if (products.some(product => !selectedOptionIds[product.id])) {
        onMissingOptionError()
      } else {
        setShowOrderFlow(true)
      }
    }

    const disabled = start_at ? moment().isBefore(moment(start_at)) : false

    if (canOrder && !canOrder.hasOwnProperty('ip_in_free_store_country')) {
      return <Loader />
    }

    if (canOrder?.ip_in_free_store_country) {
      return (
        <>
          <SimpleTooltip
            text={
              disabled
                ? I18n.t('js.creators.campaigns.show.scheduled_campaign', {
                    date: setLabelFullPublicationDate(start_at, time_zone),
                  })
                : null
            }
            display="block"
          >
            <Button
              bsStyle="primary"
              block
              className="order-campaign"
              onClick={handleClick}
              disabled={disabled}
            >
              {pre_approved
                ? I18n.t('js.creators.campaigns.show.order_now')
                : is_paid
                ? I18n.t(
                    'js.creators.campaigns.show.order_this_paid_product_no_amount'
                  )
                : I18n.t('js.creators.campaigns.show.order_this_product')}
            </Button>
          </SimpleTooltip>
          {is_paid && (
            <div className="top-10">
              <small>
                <div
                  dangerouslySetInnerHTML={{
                    __html: I18n.t(
                      'js.creators.campaigns.show.paid_campaign_price_explanation'
                    ),
                  }}
                />
              </small>
            </div>
          )}
          {showOrderFlow && (
            <OrderFlow
              onHide={() => {
                setShowOrderFlow(false)
              }}
            />
          )}
        </>
      )
    }

    return (
      <>
        <Button
          bsStyle="primary"
          block
          className="order-campaign top-10"
          disabled={true}
        >
          {I18n.t('js.creators.campaigns.show.order_this_product')}
        </Button>
        <div className="top-10">
          <p className="color-red">
            {canOrder?.free_store_country
              ? I18n.t('js.creators.campaigns.show.cant_order_this_product', {
                  country: canOrder?.free_store_country,
                })
              : I18n.t(
                  'js.creators.campaigns.show.cant_order_this_product_fallback_country'
                )}
          </p>
        </div>
      </>
    )
  } |> withHOC(OrderProvider)

export default OrderButton
