import React from 'react'
import { Modal } from 'react-bootstrap'

import { imagePath } from 'shared/images'

export default function CountryNotOpenModal({ openModal, setOpenModal }) {
  const dismiss = () => {
    setOpenModal(false)
  }

  return (
    <Modal show={openModal} onHide={dismiss} bsSize="sm">
      <Modal.Header closeButton>
        <Modal.Title className="align-items-center flex flex-column">
          <img
            src={imagePath('creators/icon_gomen.png')}
            className="block top-60"
            style={{ maxWidth: '100px' }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="align-items-center flex flex-column text-center ptop-20">
        <h4 className="text-muted font-light">
          {I18n.t('js.creators.alerts.country_not_open.title')}
          <br />
          {I18n.t('js.creators.alerts.country_not_open.subtitle')}
        </h4>
      </Modal.Body>
    </Modal>
  )
}
