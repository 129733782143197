import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import debounce from 'lodash/debounce'

const STANDARD_SIZES = {
  xs: '1em',
  sm: 20,
  md: 40,
  lg: 80,
}

export default function Loader({
  inline = false,
  fullHeight = false,
  size = fullHeight ? 'lg' : inline ? 'xs' : 'md',
  className,
  left,
  right,
  xs,
  sm,
  lg,
  containerHeight,
  type = fullHeight ? 'fullscreen' : 'spinner',
  currentColor = inline,
  overContent = false,
  ...props
}) {
  let position = 'center'
  if (left) position = 'left'
  if (right) position = 'right'
  let cssSize = size
  if (xs) cssSize = 'xs'
  if (sm) cssSize = 'sm'
  if (lg) cssSize = 'lg'
  if (cssSize in STANDARD_SIZES) {
    cssSize = STANDARD_SIZES[cssSize]
  }
  if (typeof cssSize === 'number') cssSize = `${cssSize}px`

  const addedStyle = {}
  if (inline && position === 'left') addedStyle.marginRight = '0.7em'
  if (inline && position === 'right') addedStyle.marginLeft = '0.7em'

  const elementRef = useRef(null)

  const checkHeight = (el = elementRef.current) => {
    if (!fullHeight && !containerHeight) return
    elementRef.current = el
    if (!el) return
    const { height: spinnerHeight } = el
      .querySelector('.loader-skeepers-inner')
      .getBoundingClientRect()
    if (containerHeight) {
      el.style.paddingTop = el.style.paddingBottom = `${
        (containerHeight - spinnerHeight) / 2
      }px`
    }
    if (fullHeight) {
      const { top } = el.getBoundingClientRect()
      const verticalCenterPosition = (window.innerHeight - top) * 0.4 // in theory it should be divided by 2 but we want it a bit more on top since there may be some footer content on the page
      el.style.paddingTop = `${verticalCenterPosition - spinnerHeight / 2}px`
    }
  }

  useEffect(() => {
    if (!fullHeight) return
    const listener = window.addEventListener(
      'resize',
      debounce(e => {
        checkHeight()
      }, 50)
    )
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return (
    <div
      className={cn(
        'loader-skeepers',
        `loader-skeepers-${position}`,
        `loader-skeepers-${inline ? 'inline' : 'block'}`,
        `loader-skeepers-${type}`,
        {
          'loader-skeepers-current-color': currentColor,
          'loader-skeepers-over-content': overContent,
        },
        className
      )}
      {...props}
      ref={el => {
        checkHeight(el)
      }}
    >
      <div className="loader-skeepers-inner" style={{ '--size': cssSize }} />
      <span className="sr-only">{I18n.t('js.global.loading')}</span>
    </div>
  )
}
