import { useContext } from 'react'
import { CreatorContext } from 'creators/CreatorProvider'
import { FacebookSdkContext } from 'creators/FacebookSdkProvider'
import fetchAPI, { APIError } from 'shared/fetchAPI'
import { addEvent } from 'shared/addEvent'
import showFlashMessage from 'creators/common/showFlashMessage'

function MissingPermissionsException() {
  this.message = 'missing_permissions'
}

export function useFacebookSdk() {
  const context = useContext(CreatorContext)
  const { isFacebookSdkReady } = useContext(FacebookSdkContext)
  const { FB } = window

  let scope = [
    'instagram_basic',
    'pages_show_list',
    'instagram_manage_insights',
    'business_management',
  ]

  const connectInstagram = async (
    { isUpdating = false, isReconnecting = false },
    callBack = null
  ) => {
    addEvent('creator_social_linking_process', {
      platform: 'instagram',
      reconnect: isReconnecting,
      is_updating: isUpdating,
    })
    try {
      const { status, authResponse } = await new Promise(resolve =>
        FB.login(resolve, {
          scope: scope.join(','),
          auth_type: 'rerequest',
        })
      )
      if (status === 'connected') {
        const permissions = await new Promise(resolve =>
          FB.api('/me/permissions', resolve)
        )
        const missingPermissions = permissions.data
          .filter(
            ({ permission, status }) =>
              scope.includes(permission) && status === 'declined'
          )
          .map(({ permission }) => permission)
        if (missingPermissions.length > 0) {
          throw new MissingPermissionsException()
        } else {
          const { userID, accessToken } = authResponse
          await fetchAPI(
            `/api/v3/instagram/creators/registrations/link_api_account?uid=${userID}&token=${accessToken}&is_updating=${isUpdating}`
          )
          if (context.refreshMe) {
            await context.refreshMe()
          }
          showFlashMessage({
            type: 'success',
            message: isUpdating
              ? I18n.t('creators.settings.platform_account.refresh_success')
              : I18n.t('creators.registrations.accounts.added.generic'),
            layout: 'new',
            closable: true,
          })
          if (callBack) {
            callBack()
          }
        }
      }
    } catch (e) {
      if (e instanceof MissingPermissionsException) {
        addEvent('creator_social_linking_failed', {
          platform: 'instagram',
          linking_issues: [e.message],
          is_updating: isUpdating,
        })
        FB.logout()
        return {
          error: { message: e.message },
        }
      } else if (e instanceof APIError && e.message === 'instagram_error') {
        addEvent('creator_social_linking_failed', {
          platform: 'instagram',
          linking_issues: e.data.map(({ message }) => message),
          is_updating: isUpdating,
        })
        FB.logout()
        if (e?.data?.[0]) {
          return {
            error: {
              message: e.data[0].message,
              username: e.data[0].username || '',
              email: e.data[0].email || '',
            },
          }
        } else {
          showFlashMessage({
            type: 'error',
            message: I18n.t('js.global.error'),
            layout: 'new',
            closable: true,
          })
          console.error(e)
        }
      } else {
        FB.logout()
        addEvent('creator_social_linking_failed', {
          platform: 'instagram',
          linking_issues: [e],
          is_updating: isUpdating,
        })
        showFlashMessage({
          type: 'error',
          message: I18n.t('js.global.error'),
          layout: 'new',
          closable: true,
        })
        console.error(e)
      }
    }
  }

  return [connectInstagram, isFacebookSdkReady, FB]
}
