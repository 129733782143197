/* eslint-disable indent */
import { css } from 'styled-components'

export function BoxShadow() {
  return css`
    .bs-none {
      box-shadow: none;
    }
    .bs-xs {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-xs-i {
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-sm {
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-sm-i {
      box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-md {
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-md-i {
      box-shadow: 0px -6px 16px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-lg {
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    }
    .bs-lg-i {
      box-shadow: 0px -8px -20px 0px rgba(0, 0, 0, 0.1);
    }
    .bg-xl {
      box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);
    }
    .bg-xl-i {
      box-shadow: 0px -10px -24px 0px rgba(0, 0, 0, 0.1);
    }
  `
}
