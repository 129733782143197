import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import cn from 'classnames'
import RatioContainer from 'shared/components/RatioContainer'
import { CampaignContext } from '.'
import { imgixURL } from 'shared/imgix'
import sortBy from 'lodash/fp/sortBy'
import findIndex from 'lodash/fp/findIndex'
import difference from 'lodash/difference'
import Loader from 'shared/components/Loader'

function Assets() {
  const { campaign, selectedOptionIds, setSelectedOptionIds } =
    useContext(CampaignContext)
  const assets = useMemo(() => campaign |> getAssets, [campaign.id])
  const [index, setIndex] = useState(0)
  const previousSelectedOptionIds = useRef(selectedOptionIds)
  useEffect(() => {
    const addedOptionId = difference(
      Object.values(selectedOptionIds),
      Object.values(previousSelectedOptionIds.current)
    )[0]
    const matchingAssetIndex =
      assets
      |> findIndex(asset => asset.key === `productOption-${addedOptionId}`)
    if (matchingAssetIndex !== -1) {
      setIndex(matchingAssetIndex)
    }
    previousSelectedOptionIds.current = selectedOptionIds
  }, [selectedOptionIds])

  const [loadedImages, setLoadedImages] = useState({})

  if (!assets.length) {
    return <RatioContainer ratio={4 / 3} />
  }

  const currentAsset = assets[index]

  return (
    <div id="campaign-pictures">
      {(currentAsset.type === 'youtubeVideo' ||
        !loadedImages[currentAsset.key]) && (
        <div
          style={{
            aspectRatio: '4 / 3',
            ...(currentAsset.type === 'youtubeVideo'
              ? { position: 'absolute', width: '100%' }
              : {}),
          }}
          className="color-bg-white"
        >
          <Loader
            overContent
            style={{ aspectRatio: '4 / 3', height: 'auto' }}
          />
        </div>
      )}
      {currentAsset.type === 'youtubeVideo' ? (
        <YoutubeVideo videoId={currentAsset.videoId} />
      ) : (
        <img
          className="img-responsive"
          css="display: inline-block;"
          src={currentAsset.url}
          onLoad={() =>
            setLoadedImages({ ...loadedImages, [currentAsset.key]: true })
          }
        />
      )}
      {assets.length > 1 && (
        <div className="thumbs-list clearfix top-10">
          {assets.map((asset, i) => (
            <div
              key={asset.key}
              className={cn('thumb', { active: i === index })}
              onClick={() => {
                setIndex(i)
                // If asset is variant, select it
                if (asset.productId && asset.optionId) {
                  setSelectedOptionIds({
                    ...selectedOptionIds,
                    [asset.productId]: asset.optionId,
                  })
                }
              }}
            >
              <img className="img-thumb" src={asset.thumbnailUrl} height="55" />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function YoutubeVideo({ videoId }) {
  return (
    <div className="embed-responsive embed-responsive-4by3">
      <iframe
        className="embed-responsive-item"
        id="ytplayer"
        src={videoId |> getYoutubeEmbedUrl}
        frameBorder="0"
        allowFullScreen
        wmode="opaque"
      />
    </div>
  )
}

function getAssets(campaign) {
  const pictures = []
  if (campaign.video_id) {
    pictures.push({
      key: 'video',
      type: 'youtubeVideo',
      thumbnailUrl: campaign.video_id |> getYoutubeThumbnailUrl,
      videoId: campaign.video_id,
    })
  }
  if (!campaign.photo_from_video && campaign.photo_urls) {
    pictures.push({
      key: 'mainCampaign',
      type: 'image',
      thumbnailUrl: campaign.photo_urls.small,
      url: campaign.photo_urls.large,
    })
  }

  const optionsOrder = [
    option => option.cpo.quantity_left === 0,
    option => option.rank,
  ]

  for (const product of campaign.products) {
    if (product.photo) {
      pictures.push({
        key: `product-${product.id}`,
        type: 'image',
        thumbnailUrl: imgixURL(product.photo, { w: 200, h: 150, fit: 'crop' }),
        url: imgixURL(product.photo, { w: 800, h: 600, fit: 'crop' }),
      })
    }

    for (const option of product.options.filter(option => option.cpo)
      |> sortBy(optionsOrder)) {
      if (!option.cpo.checked) continue
      if (!option.picture) continue
      pictures.push({
        key: `productOption-${option.id}`,
        type: 'image',
        thumbnailUrl: imgixURL(option.picture, { w: 200, h: 150, fit: 'crop' }),
        url: imgixURL(option.picture, { w: 800, h: 600, fit: 'crop' }),
        productId: product.id,
        optionId: option.id,
      })
    }
  }

  return pictures
}

function getYoutubeEmbedUrl(videoId) {
  return `https://www.youtube.com/embed/${videoId}?wmode=transparent`
}

function getYoutubeThumbnailUrl(videoId) {
  return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
}

export default Assets
