import React from 'react'
import styled, { css } from 'styled-components'
import useMe from 'creators/useMe'
import compact from 'lodash/compact'
import MondialRelayPicker from './MondialRelay'
import { imagePath } from 'shared/images'
import { Radio } from 'shared/components/UIkit'

function ShippingSummary({
  addressChecked,
  setAddressChecked,
  error,
  setError,
  deliveryType,
  setDeliveryType,
  setMondialRelayChoice,
  mondialRelayChoice,
  isEligibleMondialRelay,
}) {
  const me = useMe()

  return (
    <Container className="top-10">
      <h5 className="top-0 d-flex align-items-center">
        <img
          className="right-10 bottom-4"
          src={imagePath('creators/shipping-address.png')}
          width="20"
          height="20"
        />
        {I18n.t('js.pro.campaigns.form.product.logistics.delivery_type.label')}
      </h5>
      {isEligibleMondialRelay && (
        <>
          <Radio
            label="Mondial Relay"
            id="mondial_relay"
            name="mondial_relay"
            value="mondial_relay"
            checked={deliveryType === 'mondial_relay'}
            onChange={() => {
              setDeliveryType('mondial_relay')
            }}
          />
          {deliveryType === 'mondial_relay' && (
            <MondialRelayPicker
              setMondialRelayChoice={setMondialRelayChoice}
              mondialRelayChoice={mondialRelayChoice}
            />
          )}
        </>
      )}
      <div className="mt-10 mb-10">
        <Radio
          label={I18n.t('js.creators.campaigns.order_modal.home_delivery')}
          id="home"
          name="home"
          value="home"
          checked={deliveryType === 'home'}
          onChange={() => {
            setDeliveryType('home')
          }}
        />
      </div>
      {(deliveryType === 'home' || !isEligibleMondialRelay) && (
        <>
          <span className="d-block">
            {(
              [me.address, me.address_2, me.postal_code, me.city, me.state]
              |> compact
            ).join(', ')}
          </span>
          <span className="text-muted d-block">{me.phone}</span>
          <a href="/creators/settings">
            {I18n.t('js.creators.campaigns.order_modal.edit_address')}
          </a>
          <AddressConfirm error={error?.address}>
            <label>
              <input
                id="checkAddress"
                type="checkbox"
                checked={addressChecked}
                onChange={() => {
                  setAddressChecked(!addressChecked)
                  setError({ ...error, address: false })
                }}
              />
              {I18n.t('js.creators.campaigns.order_modal.confirm_address')}
            </label>
          </AddressConfirm>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: 20px 0;
  border-top: 1px solid #e8ebed;
`

const AddressConfirm = styled.div`
  ${({ error }) => css`
    padding: 8px 0;
    label {
      margin: 0;
      display: flex;
      align-items: center;
      user-select: none;

      input {
        margin: 0 8px 0 0;
        cursor: pointer;
      }
      color: ${error ? '#D0021B' : '#666'};
    }
  `}
`

export default ShippingSummary
