import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Geosuggest from 'react-geosuggest'
import { Controller, useFormContext } from 'react-hook-form'

import Group from './Group'

const Address = ({ countryCode, defaultValue }) => {
  const { errors, setValue, clearError } = useFormContext()

  const ref = useRef()

  return (
    <Group
      error={errors.address}
      label={I18n.t('js.creators.settings.edit.address')}
    >
      <Controller
        name="address"
        defaultValue={defaultValue}
        as={
          <Geosuggest
            ref={ref}
            maxLength={35}
            type="text"
            name="address"
            inputClassName="form-control"
            initialValue={defaultValue}
            placeholder=""
            country={countryCode}
            minLength={5}
            onSuggestSelect={suggest => {
              if (suggest) {
                setValue('address', suggest.gmaps.name)
                setValue(
                  'postal_code',
                  findShortValue(
                    suggest.gmaps.address_components,
                    'postal_code'
                  )
                )
                setValue(
                  'city',
                  findShortValue(suggest.gmaps.address_components, 'locality')
                )
                setValue(
                  'state',
                  findShortValue(
                    suggest.gmaps.address_components,
                    'administrative_area_level_1'
                  )
                )
                setValue(
                  'department',
                  findLongValue(
                    suggest.gmaps.address_components,
                    'administrative_area_level_2'
                  )
                )
                ref.current.update(suggest.gmaps.name)
                clearError([
                  'address',
                  'postal_code',
                  'city',
                  'state',
                  'department',
                ])
              }
            }}
          />
        }
        rules={{
          required: I18n.t('js.forms.validation_errors.field_required'),
          maxLength: {
            value: 35,
            message: I18n.t('js.forms.validation_errors.too_long', {
              maxLength: 35,
            }),
          },
        }}
      />
    </Group>
  )
}

Address.propTypes = {
  countryCode: PropTypes.string.isRequired,
  defaultValue: PropTypes.any.isRequired, // TODO: Check actual type
}

export default Address

function findShortValue(address_components, field) {
  const found = address_components.find(component =>
    component.types.includes(field)
  )
  if (found) return found.short_name
  return ''
}

function findLongValue(address_components, field) {
  const found = address_components.find(component =>
    component.types.includes(field)
  )
  if (found) return found.long_name
  return ''
}
