import React, { useEffect } from 'react'
import useMe from 'creators/useMe'
import { useThemeContext } from 'styles'

const MondialRelayPicker = ({ setMondialRelayChoice, mondialRelayChoice }) => {
  const me = useMe()
  const {
    formFactors: { isMobile },
  } = useThemeContext()

  const initMondialRelayWidget = () => {
    $('#parcelshop-picker').MR_ParcelShopPicker({
      // 'F2FUTURM' -> key to be used in production
      // 'BDTEST13' -> key to be used in test
      Brand: 'F2FUTURM',
      Country: 'FR',
      PostCode: me.postal_code,
      // delivery type (Standard [24R], XL [24L], XXL [24X], Drive [DRI])
      ColLivMod: '24R',
      NbResults: '15',
      ShowResultsOnMap: true,
      DisplayMapInfo: true,
      Responsive: true,
      OnParcelShopSelected: function (data) {
        // We first delete the previous checked image if one exists
        const previousShop = document.querySelector(
          '.MRW-RList .mr-image-checked'
        )
        if (previousShop) {
          previousShop.remove()
        }

        const newShop = document.querySelector(
          `.PR-List-Item .PR-Id[value="FR-${data.ID}"]`
        )

        // Then we add the new one
        if (newShop) {
          const parentDiv = newShop.parentNode
          parentDiv.insertAdjacentHTML(
            'afterbegin',
            `<div class="mr-image-container"><div class="mr-image-checked"></div></div>`
          )
        }
        setMondialRelayChoice(data)
      },
      AllowedCountries: 'FR',
    })
  }

  useEffect(() => {
    // we are already using jquery3.6.1 through the app with jquery-rails
    // (see the file unique_jquery.js)
    // but for some unknnown reason, the mondial relay widget is not working with it
    // so we are loading it again but only here to prevent conflicts
    if (!window.MR_Jquery) {
      const jqueryScript = document.createElement('script')
      jqueryScript.src =
        'https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js'
      jqueryScript.async = true
      document.head.appendChild(jqueryScript)

      const mondialRelayScript = document.createElement('script')
      mondialRelayScript.src =
        'https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js'
      mondialRelayScript.async = true

      jqueryScript.onload = function () {
        document.head.appendChild(mondialRelayScript)
      }

      mondialRelayScript.onload = function () {
        initMondialRelayWidget()
      }
    } else {
      initMondialRelayWidget()
    }

    return () => {
      // We remove both mondialrelay and jquery scripts
      // to prevent conflicts with the rest of the app
      const scriptElements = document.querySelectorAll('head script, head link')
      scriptElements.forEach(script => {
        const src = script.getAttribute('src') || script.getAttribute('href')
        if (
          src &&
          (src.startsWith('https://widget.mondialrelay.com/') ||
            src ===
              'https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js')
        ) {
          script.parentNode.removeChild(script)
        }
      })
    }
  }, [])

  return (
    <div id="parcelshop-picker" className="mt-5 mb-15">
      {mondialRelayChoice && (
        <div className="mt-10 mb-10 d-flex pb-1 mobile-shop-name-selected">
          {isMobile && (
            <div className="d-flex mr-5 mr-image-container">
              <div className="mr-image-checked"></div>
            </div>
          )}
          <div className={`d-flex-column ${isMobile ? 'ml-60' : ''}`}>
            {!isMobile && (
              <p className="bold mb-2">
                {I18n.t('js.creators.campaigns.order_modal.selected_address')}
              </p>
            )}
            <p className="mb-2 mobile-shop-name">{mondialRelayChoice.Nom}</p>
            <p className="mb-2">{mondialRelayChoice.Adresse1}</p>
            <p className="mb-2">{`${mondialRelayChoice.CP} ${mondialRelayChoice.Ville}`}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default MondialRelayPicker
