const getUserId = (me, proUser) => {
  let userId = null
  if (me) {
    userId = `c:${me.id}`
  } else if (proUser) {
    userId = `u:${proUser.id}`
  }

  return userId
}

module.exports = {
  getUserId,
}
