import React from 'react'
import { Button } from 'react-bootstrap'
import Loader from './Loader'

export default class ButtonWithLoader extends React.Component {
  onPromiseEnd = null

  constructor(props) {
    super(props)
    this.state = {
      loading: props.loading,
    }
  }

  handleClick = async e => {
    if (this.props.onClick) {
      this.setState({ loading: true })
      this.onPromiseEnd = () => {
        this.setState({ loading: false })
      }
      try {
        await this.props.onClick(e)
      } finally {
        if (this.onPromiseEnd) this.onPromiseEnd()
      }
    }
  }

  componentWillUnmount() {
    this.onPromiseEnd = null
  }

  render() {
    let { loading } = this.state
    let { callback, onClick, loading: propsLoading, ...otherProps } = this.props

    return (
      <ControlledButtonWithLoader
        onClick={this.handleClick}
        loading={loading}
        {...otherProps}
      />
    )
  }
}

export function ControlledButtonWithLoader({
  children,
  loading,
  disabled,
  ...otherProps
}) {
  return (
    <Button disabled={disabled || loading} {...otherProps}>
      {children}
      {loading && <Loader inline right />}
    </Button>
  )
}
