/* eslint-disable indent */
import { css } from 'styled-components'

const VALUES = Array.from(Array(301).keys())

export function Margins() {
  return VALUES.map(
    value => css`
      ${['top', 'right', 'bottom', 'left'].map(
        dir => css`
        .m${dir[0]}-${value} {
          margin-${dir}: ${value}px;
        }
      `
      )}
      .m-${value} {
        margin: ${value}px;
      }
      .mx-${value} {
        margin-left: ${value}px;
        margin-right: ${value}px;
      }
      .my-${value} {
        margin-top: ${value}px;
        margin-bottom: ${value}px;
      }
    `
  )
}

export function MarginsAuto() {
  return ['top', 'right', 'bottom', 'left'].map(
    dir => css`
    .m${dir[0]}-a {
      margin-${dir}: auto;
    }
  `
  )
}

export function SpaceChildren() {
  return ['top', 'right', 'bottom', 'left'].map(
    dir => css`
      ${[8, 10, 16, 24, 32].map(
        value => css`
      .sc${dir[0]}-${value} {
        & > * + * {
          margin-${dir}: ${value}px;
        }
      }
    `
      )}
    `
  )
}

export function Paddings() {
  return VALUES.map(
    value => css`
      ${['top', 'right', 'bottom', 'left'].map(
        dir => css`
        .p${dir[0]}-${value} {
          padding-${dir}: ${value}px;
        }
      `
      )}
      .p-${value} {
        padding: ${value}px;
      }
      .px-${value} {
        padding-left: ${value}px;
        padding-right: ${value}px;
      }
      .py-${value} {
        padding-top: ${value}px;
        padding-bottom: ${value}px;
      }
    `
  )
}
