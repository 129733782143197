import { useEffect, useContext } from 'react'
import { DebugContext } from './DebugProvider'

export default function useDebug({ key, label, onClick, color }) {
  const ctx = useContext(DebugContext)
  useEffect(() => {
    if (ctx) {
      ctx.registerTool({ key, label, color })
      return () => {
        ctx.unregisterTool(key)
      }
    }
  }, [])
  useEffect(() => {
    if (ctx?.registeredTools.some(tool => tool.key === key)) {
      ctx.updateTool(key, {
        label,
        color,
      })
    }
  }, [label, color])
  if (ctx) {
    ctx.callbackRef.current[key] = onClick
  }
  return {
    active: ctx?.activeTool === key,
  }
}
