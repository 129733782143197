import React, { useState, useEffect, useContext } from 'react'
import { useForm, Controller, FormContext } from 'react-hook-form'
import { Modal, Form, FormControl, Row, Col, HelpBlock } from 'react-bootstrap'
import styled from 'styled-components'

import showFlashMessage from 'creators/common/showFlashMessage'
import Group from 'shared/components/Form/Group'
import GenderInput from 'shared/components/Form/GenderInput'
import PhoneInput from 'shared/components/Form/PhoneInput'
import AddressInput from 'shared/components/Form/AddressInput'
import ConfirmPasswordModal from 'creators/common/components/ConfirmPasswordModal'
import { ControlledButtonWithLoader } from 'shared/components/ButtonWithLoader'
import fetchAPI from 'shared/fetchAPI'
import { CreatorContext } from 'creators/CreatorProvider'
import countriesWithStates from 'creators/common/countriesWithStates'
import useCountryStates from 'creators/common/useCountryStates'
import OSelect from 'shared/components/OSelect'
import MissingInfoIcon from 'assets/images/problem.svg'
import { CampaignContext } from '..'

const PASSWORDREQUIREDFIELDS = [
  'address',
  'address_2',
  'department',
  'postal_code',
  'state',
  'city',
  'unconfirmed_phone',
]

const MissingFieldsFormModalContent = ({ fields, onFinish }) => {
  const { creator: currentCreator, setCreator: setCurrentCreator } =
    useContext(CreatorContext)
  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)
  const [submitting, setSubmitting] = useState(false)
  const [confirmPasswordModal, setConfirmPasswordModal] = useState(false)
  const [updatedData, setUpdatedData] = useState(null)

  const { states, isFetching: isFetchingStates } = useCountryStates(
    currentCreator.country_code
  )

  const form = useForm({})

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    clearError,
    watch,
  } = form

  const watchAddress = watch('address')
  const watchAddress2 = watch('address_2')

  useEffect(() => {
    const { address, address_2 } = errors
    if (watchAddress) {
      watchAddress.length > 35
        ? !address
          ? setError(
              'address',
              'maxLength',
              I18n.t('js.forms.validation_errors.too_long', { maxLength: 35 })
            )
          : undefined
        : address
        ? clearError('address')
        : undefined
    }

    if (watchAddress2) {
      watchAddress2.length > 35
        ? !address_2
          ? setError(
              'address_2',
              'maxLength',
              I18n.t('js.forms.validation_errors.too_long', { maxLength: 35 })
            )
          : undefined
        : address_2
        ? clearError('address_2')
        : undefined
    }
  }, [watchAddress, watchAddress2])

  const usesStateSelectInput = countriesWithStates.includes(
    currentCreator.country_code
  )

  // Registers/unregisters state_select field
  useEffect(() => {
    if (usesStateSelectInput) {
      register('state_select', {
        required: I18n.t('js.forms.validation_errors.field_required'),
      })
    } else {
      unregister('state_select')
    }
  }, [usesStateSelectInput])

  const updateUserData = async data => {
    setSubmitting(true)

    if (usesStateSelectInput) {
      data.state = data.state_select
      delete data.state_select
    }

    try {
      const updatedCreator = await fetchAPI(`/api/v3/creators`, {
        method: 'PATCH',
        body: {
          ...data,
          _source: 'fill_missing_info_for_campaign',
          _source_campaign_id: campaignId,
        },
      })
      setCurrentCreator(updatedCreator)
      showFlashMessage(
        'notice',
        I18n.t('js.creators.campaigns.missing_fields_modal.creator_updated')
      )
      onFinish()
    } catch (error) {
      if (error.data) {
        handleError(error.data)
      } else {
        showFlashMessage(
          'notice',
          I18n.t(
            'activerecord.errors.messages.restrict_dependent_destroy.has_many'
          )
        )
      }
      setSubmitting(false)
    }
  }

  async function onSubmit(filledFields) {
    if (!isPasswordRequired(filledFields)) {
      await updateUserData(filledFields)
    } else {
      setUpdatedData(filledFields)
      setConfirmPasswordModal(true)
    }
  }

  const onSubmitWithPassword = async password => {
    setConfirmPasswordModal(false)
    const data = { ...updatedData, current_password: password }

    await updateUserData(data)
  }

  function handleError(errors) {
    try {
      for (const key in errors) {
        const errorMsg = errors[key][0]
        setError(key, errorMsg.error, errorMsg.message)
      }
    } catch (e) {
      showFlashMessage(
        'notice',
        I18n.t(
          'activerecord.errors.messages.restrict_dependent_destroy.has_many'
        )
      )
    }
  }

  const requiredField = rest =>
    register({
      required: I18n.t('js.forms.validation_errors.field_required'),
      ...rest,
    })
  const maxLength = length => ({
    maxLength: {
      value: length,
      message: I18n.t('js.forms.validation_errors.too_long', {
        maxLength: length,
      }),
    },
  })

  const isPasswordRequired = filledFields => {
    if (fields.includes('address') || fields.includes('phone')) {
      return PASSWORDREQUIREDFIELDS.some(e => {
        if (!!currentCreator[e] && !!filledFields[e]) {
          return currentCreator[e] !== filledFields[e]
        }

        return false
      })
    }

    return false
  }

  return (
    <>
      <FormContext {...form}>
        <Form id="creators-settings-form" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <div className="flex justify-content-center bottom-30 top-30">
              <MissingInfoIcon />
            </div>
            <Title className="text-center font-light" componentClass="h1">
              {I18n.t('js.creators.campaigns.missing_fields_modal.main')}
            </Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {fields.includes('first_name') && (
                <Col md={6}>
                  <Group
                    error={errors.first_name}
                    label={I18n.t('js.creators.settings.edit.first_name')}
                  >
                    <FormControl
                      type="text"
                      name="first_name"
                      inputRef={requiredField()}
                    />
                  </Group>
                </Col>
              )}
              {fields.includes('last_name') && (
                <Col md={6}>
                  <Group
                    error={errors.last_name}
                    label={I18n.t('js.creators.settings.edit.last_name')}
                  >
                    <FormControl
                      type="text"
                      name="last_name"
                      inputRef={requiredField()}
                    />
                  </Group>
                </Col>
              )}
            </Row>
            <Row>
              {fields.includes('gender') && (
                <Col md={6}>
                  <GenderInput defaultGender="" />
                </Col>
              )}
              {fields.includes('phone') && (
                <Col md={6}>
                  <PhoneInput
                    phoneProps={{
                      country: currentCreator.country_code.toLowerCase(),
                      onlyCountries:
                        currentCreator.country_code == 'US'
                          ? ['us']
                          : undefined,
                    }}
                    defaultValue=""
                  />
                </Col>
              )}
            </Row>
            {fields.includes('address') && (
              <>
                <Row>
                  <Col md={12}>
                    <AddressInput
                      countryCode={currentCreator.country_code}
                      defaultValue={currentCreator.address || ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Group
                      error={errors.address_2}
                      label={I18n.t('js.creators.settings.edit.address_2')}
                    >
                      <FormControl
                        maxLength={35}
                        type="text"
                        name="address_2"
                        inputRef={register(maxLength(35))}
                        defaultValue={currentCreator.address_2}
                      />
                    </Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Group
                      error={errors.postal_code}
                      label={I18n.t('js.creators.settings.edit.postal_code')}
                    >
                      <FormControl
                        maxLength={13}
                        type="text"
                        name="postal_code"
                        inputRef={requiredField(maxLength(13))}
                        defaultValue={currentCreator.postal_code}
                      />
                    </Group>
                  </Col>
                  <Col md={6}>
                    <Group
                      error={errors.city}
                      label={I18n.t('js.creators.settings.edit.city')}
                    >
                      <FormControl
                        maxLength={32}
                        type="text"
                        name="city"
                        inputRef={requiredField(maxLength(32))}
                        defaultValue={currentCreator.city}
                      />
                    </Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {usesStateSelectInput && (
                      <Group
                        error={errors.state_select}
                        label={I18n.t('js.creators.settings.edit.state')}
                      >
                        <Controller
                          name="state_select"
                          defaultValue={currentCreator.state}
                          as={
                            <OSelect
                              name="state_select"
                              options={states.map(state => ({
                                value: state,
                                label: state,
                              }))}
                              isLoadingOptions={isFetchingStates}
                              isDisabled={isFetchingStates}
                            />
                          }
                        />
                      </Group>
                    )}
                    {!usesStateSelectInput && (
                      <Group
                        error={errors.state}
                        label={I18n.t('js.creators.settings.edit.state')}
                      >
                        <FormControl
                          maxLength={35}
                          type="text"
                          name="state"
                          inputRef={register(maxLength(35))}
                          defaultValue={currentCreator.state}
                        />
                      </Group>
                    )}
                  </Col>
                  <Col md={6}>
                    <Group
                      error={errors.department}
                      label={I18n.t('js.creators.settings.edit.department')}
                    >
                      <FormControl
                        maxLength={33}
                        type="text"
                        name="department"
                        inputRef={register(maxLength(33))}
                        defaultValue={currentCreator.department}
                      />
                    </Group>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="text-center">
            {errors.current_password && (
              <HelpBlock style={{ color: '#D0021B', marginBottom: '20px' }}>
                {I18n.t('js.creators.settings.edit.current_password')}{' '}
                {I18n.t('activerecord.errors.messages.invalid')}
              </HelpBlock>
            )}
            <ControlledButtonWithLoader
              bsStyle="primary"
              type="submit"
              loading={submitting}
            >
              {I18n.t('js.creators.campaigns.missing_fields_modal.update')}
            </ControlledButtonWithLoader>
          </Modal.Footer>
        </Form>
      </FormContext>
      <Modal
        show={confirmPasswordModal}
        onHide={() => setConfirmPasswordModal(false)}
        className="modal-overlay-index-2"
        backdropClassName="modal-backdrop modal-backdrop-overlay-index-2"
      >
        <ConfirmPasswordModal onClick={onSubmitWithPassword} />
      </Modal>
    </>
  )
}

const Title = styled(Modal.Title)`
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 56px;
    padding: 0 100px 0 100px;
  }
`

export default MissingFieldsFormModalContent
