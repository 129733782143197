import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'shared/scss'

function _Switch({ name, disabled, newUI = false, ...rest }, ref) {
  // Until Creator UI update, use `newUI` flag to use new design
  return (
    <>
      <SwitchInput
        ref={ref}
        newUI={newUI}
        name={name}
        id={name}
        type="checkbox"
        disabled={disabled}
        {...rest}
      />
      <SwitchLabel newUI={newUI} htmlFor={name} disabled={disabled}>
        <SwitchButton />
      </SwitchLabel>
    </>
  )
}

const Switch = React.forwardRef(_Switch)

export default Switch

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked {
    & + label {
      ${({ newUI }) =>
        newUI
          ? `background: ${COLORS.primary}`
          : `background: ${COLORS.neutral100}`};

      span {
        left: calc(100% + 2px);
        transform: translateX(-100%);
        ${({ newUI }) => (newUI ? '' : `background: ${COLORS.primary}`)};
      }
    }
  }
`

const SwitchLabel = styled.label`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 36px;
  height: 18px;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  ${({ disabled, newUI }) => {
    if (disabled) {
      return newUI
        ? `background: ${COLORS.neutral100}; border: 1px solid ${COLORS.neutral100}`
        : `background: ${COLORS.neutral100}; opacity: 0.4`
    }
    return newUI
      ? `background: transparent; border: 1px solid ${COLORS.primary}`
      : `background: ${COLORS.neutral100}; border: 1px solid ${COLORS.neutral100}`
  }};
`

const SwitchButton = styled.span`
  content: '';
  position: absolute;
  top: -3px;
  left: -2px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  transition: 0.2s;
  background: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`
