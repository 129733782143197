import Quill from 'quill'

const InlineEmbed = Quill.import('blots/embed')

class VideoBlot extends InlineEmbed {
  static create({ src, width, height }) {
    const node = super.create({ src, width, height })
    node.setAttribute('src', src)
    if (width) node.setAttribute('width', width)
    if (height) node.setAttribute('height', height)
    node.setAttribute('controls', '')

    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
    }
  }
}

VideoBlot.blotName = 'videoBlot'
VideoBlot.className = 'video-blot'
VideoBlot.tagName = 'video'

Quill.register({ 'formats/videoBlot': VideoBlot })
