function tokenSanitization(token) {
  if (!token) return null
  return token
}

function getReferer() {
  return document.referrer
}

function handleSignupBypassSearchParams(params) {
  return {
    bypass_token: tokenSanitization(params.bypass_token),
    referer: getReferer(),
  }
}

module.exports = {
  getReferer,
  tokenSanitization,
  handleSignupBypassSearchParams,
}
