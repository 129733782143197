import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import OSelect from 'shared/components/OSelect'
import Group from './Group'

const GenderInput = ({ defaultGender }) => {
  const { errors } = useFormContext()
  const genders = Object.keys(I18n.t('enumerize.gender'))

  return (
    <Group
      error={errors.gender}
      label={I18n.t('js.creators.settings.edit.gender')}
    >
      <Controller
        name="gender"
        as={
          <OSelect
            options={genders.map(gender => ({
              value: gender,
              label: I18n.t(`enumerize.gender.${gender}`),
            }))}
            name="gender"
          />
        }
        rules={{
          required: I18n.t('js.forms.validation_errors.field_required'),
        }}
        defaultValue={defaultGender}
      />
    </Group>
  )
}

GenderInput.propTypes = {
  defaultGender: PropTypes.string,
}

export default GenderInput
