import fromPairs from 'lodash/fromPairs'

// handles errors payloads created with api_error! @ back
export class APIError extends Error {
  constructor(errorPayload) {
    super(errorPayload.message)
    this.name = 'APIError'
    this.data = errorPayload.data
    this.status = errorPayload.status
  }

  asObject() {
    return fromPairs((this.data || []).map(errKey => [errKey, true]))
  }
}

export default APIError
