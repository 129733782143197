import _ from 'lodash'

export const imagePath = path => {
  const image = _.find(OctolyImages, { path: path })

  if (image) {
    return image.url
  } else {
    console.log('This image does not exist')
  }
}
