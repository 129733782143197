import React, { isValidElement } from 'react'
import { NewFlashMessageLayout, ClassicFlashMessageLayout } from '.'

/**
 * showFlashMessage("error", "It failed!")
 * showFlashMessage("error", "It failed!", { layout: "new" })
 * showFlashMessage({ type: "error", message: "It failed!" })
 * showFlashMessage({ type: "success", message: "It worked!", layout: "new", subMessage: "And that's cool!" })
 * showFlashMessage(<NewFlashMessageLayout type="success" message="It worked!" subMessage="And that's cool!" />)
 * showFlashMessage(<div>My custom content !</div>)
 * For implementation details, see function buildFlashMessage below
 */
export function showFlashMessage(...args) {
  const event = new CustomEvent('showFlashMessage', {
    detail: args,
  })
  document.dispatchEvent(event)
}

export function buildFlashMessage(
  typeOrOptionsOrReactElement,
  messageOrNothing = undefined,
  optionsOrNothing = {}
) {
  if (typeOrOptionsOrReactElement |> isValidElement) {
    return typeOrOptionsOrReactElement
  }
  let options
  if (typeof typeOrOptionsOrReactElement === 'string') {
    const type = typeOrOptionsOrReactElement
    const message = messageOrNothing
    options = {
      layout: 'classic',
      ...optionsOrNothing,
      type,
      message,
    }
  }
  if (typeof typeOrOptionsOrReactElement === 'object') {
    options = {
      layout: 'classic',
      ...typeOrOptionsOrReactElement,
    }
  }

  const { layout, ...layoutProps } = options

  const LayoutToUse = {
    classic: ClassicFlashMessageLayout,
    new: NewFlashMessageLayout,
  }[layout]

  return <LayoutToUse {...layoutProps} />
}

if (process.env.NODE_ENV === 'development') {
  window.flash = showFlashMessage
}
