import React, { useEffect, useState } from 'react'
import range from 'lodash/range'
import min from 'lodash/min'
import max from 'lodash/max'
import sortBy from 'lodash/sortBy'
import padStart from 'lodash/padStart'
import moment from 'moment'
import OSelect from 'shared/components/OSelect'
import cn from 'classnames'
import styled from 'styled-components'
const defaultYearsRange = [moment().year() - 100, moment().year()]

const SimpleDateSelector = ({
  value,
  onChange,
  yearsRange = defaultYearsRange,
  invalid,
  name,
  className,
  dateFormatL = moment.localeData()._longDateFormat.L,
  ...otherProps
}) => {
  const day = value.date()
  const month = value.month()
  const year = value.year()

  const [localDay, setLocalDay] = useState(undefined)
  const [localMonth, setLocalMonth] = useState(undefined)
  const [localYear, setLocalYear] = useState(undefined)

  const maxDayInMonth = value.isValid()
    ? moment([year, month, 1]).daysInMonth()
    : moment([
        localYear !== undefined ? localYear : 1,
        localMonth !== undefined ? localMonth : 0,
        1,
      ]).daysInMonth()

  useEffect(() => {
    if (value.isValid()) {
      setLocalDay(value.date())
      setLocalMonth(value.month())
      setLocalYear(value.year())
    }
  }, [value])

  useEffect(() => {
    const localDate = moment([localYear, localMonth, localDay])
    onChange(localDate)
  }, [localDay, localMonth, localYear])

  useEffect(() => {
    if (
      !value.isValid() &&
      localDay !== undefined &&
      localDay > maxDayInMonth
    ) {
      setLocalDay(maxDayInMonth)
    }
  }, [value])

  return (
    <div {...otherProps} className={cn('d-flex', className)}>
      {sortBy(
        [
          <StyledOSelect
            key="day"
            name={name ? `${name}[day]` : undefined}
            value={day}
            options={range(1, maxDayInMonth + 1).map(d => ({
              value: d,
              label: d.toString(),
            }))}
            onChange={day => {
              setLocalDay(day)
            }}
            invalid={invalid}
            size={4}
          />,
          <StyledOSelect
            key="month"
            name={name ? `${name}[month]` : undefined}
            value={month}
            onChange={month => {
              setLocalMonth(month)
            }}
            invalid={invalid}
            options={range(0, 12).map(month => ({
              value: month,
              label: padStart(month + 1, 2, '0'),
            }))}
            size={4}
          />,
          <StyledOSelect
            key="year"
            name={name ? `${name}[year]` : undefined}
            value={year}
            onChange={year => {
              setLocalYear(year)
            }}
            invalid={invalid}
            options={range(max(yearsRange), min(yearsRange)).map(y => ({
              value: y,
              label: y,
            }))}
            size={4}
          />,
        ],
        reactElement =>
          dateFormatL.indexOf(
            {
              day: 'DD',
              month: 'MM',
              year: 'YYYY',
            }[reactElement.key]
          )
      )}
    </div>
  )
}

const StyledOSelect = styled(OSelect)`
  & + & {
    margin-left: 4px;
  }
`

export default SimpleDateSelector
