import React, { useContext } from 'react'
import cn from 'classnames'
import { CampaignContext } from '../..'
import byId from 'shared/fp/byId'
import get from 'lodash/fp/get'
import some from 'lodash/fp/some'
import PlatformIcon from 'shared/components/PlatformIcon'
import { isInfluencer } from 'shared/platforms'
import { cleanTitle } from 'shared/fp/platformEntity'
import { OrderContext } from '.'
import useMe from 'creators/useMe'
import byTypedId from 'shared/fp/byTypedId'

export default function CampaignSummary({ className }) {
  const {
    campaign: { title, store, photo_urls, contracts, products },
    selectedOptionIds,
  } = useContext(CampaignContext)
  const { contractId, platformAccountIds } = useContext(OrderContext)
  const me = useMe()
  return (
    <div className={cn('media', className)}>
      <div className="media-left">
        <img
          src={photo_urls.small}
          className="media-object img-thumb"
          width="140"
        />
      </div>
      <div className="media-body">
        <div className="bottom-10">
          <img
            src={store.logo_urls.small}
            width="16"
            height="16"
            className="img-circle"
          />
          &nbsp;
          <span className="s12l16">
            <a href={store.web_path}>{store.title}</a>
          </span>
        </div>
        <h4 className="top-0 bottom-10">
          <strong>{title}</strong>
        </h4>
        <div className="clearfix d-flex flex-wrap align-items-start">
          {!!Object.entries(selectedOptionIds).length && (
            <div className="text-muted right-15">
              {Object.entries(selectedOptionIds)
                .map(
                  ([productId, productOptionId]) =>
                    products
                    |> byId(productId)
                    |> get('options')
                    |> byId(productOptionId)
                    |> get('name')
                )
                .join(', ')}
            </div>
          )}
          {platformAccountIds &&
            (contracts
              |> byId(contractId)
              |> get('options')
              |> some(
                option =>
                  me.platform_accounts.filter(
                    pa =>
                      (pa.status === 'enabled' || pa.status === 'active') &&
                      pa.platform === option.platform
                  ).length > 1
              )) &&
            platformAccountIds
              .map(
                ([contractOptionId, { id, type }]) =>
                  me.platform_accounts |> byTypedId({ id, type })
              )
              .filter(
                platformAccount => platformAccount.platform |> isInfluencer
              )
              .map(platformAccount => (
                <div
                  className="right-15"
                  key={`${platformAccount.type}-${platformAccount.id}`}
                >
                  <PlatformIcon
                    platform={platformAccount.platform}
                    width={'1.2em'}
                    height={'1.2em'}
                    css="vertical-align: middle; margin-right: 0.4em;"
                  />
                  {platformAccount.platform_entity |> cleanTitle()}
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}
