/* eslint-disable indent */
import { css } from 'styled-components'

import { THEME } from '../variables'
import { COLORS } from 'shared/scss'
import { BREAKPOINTS } from 'shared/scss'

const VALUES = Array.from(Array(100).keys())

export function FontSizes() {
  return VALUES.map(
    value => css`
      .fs-${value} {
        font-size: ${value}px;
      }
    `
  )
}

export function LineHeights() {
  return css`
    ${VALUES.map(
      value => css`
        .lh-${value} {
          line-height: ${value}px;
        }
      `
    )}
    .lh-n {
      line-height: normal;
    }
  `
}

export function FontFamilies() {
  return css`
    .ff-regular {
      font-family: ${THEME.global.fontFamilyRegular};
    }

    .ff-light {
      font-family: ${THEME.global.fontFamilyLight};
    }

    .ff-bold {
      font-family: ${THEME.global.fontFamilyBold};
    }

    .ff-roboto {
      font-family: ${THEME.global.fontFamilyRoboto};
    }
  `
}

export function Displays() {
  return css`
    .display-1 {
      font-size: 72px;
      line-height: 80px;
      letter-spacing: -0.02em;
    }
    .display-2 {
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
    }
  `
}

export function Headings() {
  return css`
    .heading-1 {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: -0.02em;

      @media (max-width: ${BREAKPOINTS.lg - 1}) {
        font-size: 39px;
        line-height: 47px;
      }
    }
    .heading-2 {
      font-size: 39px;
      line-height: 47px;
      letter-spacing: -0.02em;

      @media (max-width: ${BREAKPOINTS.lg - 1}) {
        font-size: 33px;
        line-height: 40px;
      }
    }
    .heading-3 {
      font-size: 33px;
      line-height: 40px;
      letter-spacing: -0.02em;

      @media (max-width: ${BREAKPOINTS.lg - 1}) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    .heading-4 {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -0.02em;

      @media (max-width: ${BREAKPOINTS.lg - 1}) {
        font-size: 23px;
        line-height: 28px;
      }
    }
    .heading-5 {
      font-size: 23px;
      line-height: 28px;
      letter-spacing: -0.02em;

      @media (max-width: ${BREAKPOINTS.lg - 1}) {
        font-size: 19px;
        line-height: 23px;
      }
    }
    .heading-6 {
      font-size: 19px;
      line-height: 23px;
      letter-spacing: -0.02em;

      @media (max-width: ${BREAKPOINTS.lg - 1}) {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .subheading {
      font-size: 20px;
      line-height: 24px;
    }
  `
}

export function Paragraphs() {
  return css`
    .paragraph-1 {
      font-size: 18px;
      line-height: 22px;
    }
    .paragraph-2 {
      font-size: 16px;
      line-height: 19px;
    }
    .paragraph-3 {
      font-size: 14px;
      line-height: 17px;
    }
  `
}

export function Caption() {
  return css`
    .caption {
      font-size: 12px;
      line-height: 14px;
    }
  `
}

export function FooterText() {
  return css`
    .footer-text {
      font-size: 10px;
      line-height: 12px;
    }
  `
}

export default css`
  .link {
    transition: ${THEME.global.transition};
    color: ${COLORS.grayTextLight};
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;

    &:hover {
      color: ${COLORS.grayDark};
      text-decoration: underline;
    }

    &.default {
      color: ${COLORS.blue};

      &:hover {
        color: ${COLORS.blueDark};
      }
    }

    &.primary {
      color: ${COLORS.primary500};

      &:hover {
        color: ${COLORS.primary400};
      }
    }
  }

  .text-justify {
    text-align: justify;
  }

  .font-smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
