import React from 'react'
import {
  Form,
  Modal,
  FormGroup,
  ControlLabel,
  HelpBlock,
  FormControl,
} from 'react-bootstrap'
import styled from 'styled-components'
import { useForm, FormContext } from 'react-hook-form'
import { ControlledButtonWithLoader } from 'shared/components/ButtonWithLoader'
import showFlashMessage from 'creators/common/showFlashMessage'
import fetchAPI from 'shared/fetchAPI'

export default function ConfirmEmailModalContent({ defaultEmail, onClose }) {
  const form = useForm({
    defaultValues: {
      email: defaultEmail,
    },
  })
  const { register, handleSubmit, errors } = form

  async function onSubmit({ email }) {
    try {
      await fetchAPI(`/api/v3/creators/resend_confirmation_email`, {
        method: 'PATCH',
        body: { email },
      })
      showFlashMessage(
        'notice',
        I18n.t('js.creators.alerts.confirm_email.email_sent')
      )
      onClose()
    } catch (error) {
      if (error?.data?.[0]) {
        showFlashMessage(
          'error',
          I18n.t(`js.creators.alerts.confirm_email.error.${error['data'][0]}`)
        )
      } else {
        showFlashMessage('error', I18n.t('js.global.error'))
      }
    }
  }

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Title componentClass="div" className="">
          <h1
            className="font-light text-uppercase text-center"
            dangerouslySetInnerHTML={{
              __html: I18n.t('js.creators.alerts.confirm_email.title'),
            }}
          />
          <h4 className="text-muted font-light text-center">
            {I18n.t('js.creators.alerts.confirm_email.subtitle')}
          </h4>
        </Title>
      </Modal.Header>
      <FormContext {...form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="align-items-center flex flex-column ptop-40 pbottom-0">
            <FormGroup
              style={{ width: '100%' }}
              validationState={errors.email ? 'error' : null}
            >
              <ControlLabel>
                {I18n.t('js.creators.alerts.confirm_email.label')}
              </ControlLabel>
              <div className="d-flex flex-column align-items-center">
                <FormControl
                  type="email"
                  name="email"
                  bsSize="lg"
                  inputRef={register({ required: true })}
                />
                {errors.email ? <HelpBlock className="has-error" /> : undefined}
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="align-items-center flex flex-column top-20 pbottom-45">
            <ControlledButtonWithLoader bsStyle="primary" type="submit">
              {I18n.t('js.creators.alerts.confirm_email.cta')}
            </ControlledButtonWithLoader>
          </Modal.Footer>
        </Form>
      </FormContext>
    </React.Fragment>
  )
}

/**
 * STYLED COMPONENTS
 */
const Title = styled(Modal.Title)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0 27.45px;

  h1 {
    font-size: 36px;
    line-height: 40px;
    margin: 40px 0 8px 0;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`
