export function toNumber(value, options) {
  var number,
    defaultOptions = { precision: 0, negativeToZero: false },
    opts = _.extend(defaultOptions, options)

  if (value !== null && typeof value !== 'undefined') {
    if (opts.negativeToZero) {
      value = value < 0 ? 0 : value
    }
    number = I18n.toNumber(value, opts)
    if (opts.plusSign && value > 0) {
      number = '+' + number
    }
  } else {
    number = 'n/a'
  }
  return number
}

export function toHuman(num, digits) {
  var units = I18n.t('number.human.decimal_units.units')
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: units.thousand },
    { value: 1e6, symbol: units.million },
    { value: 1e9, symbol: units.billion },
    { value: 1e12, symbol: units.trillion },
    { value: 1e15, symbol: units.quadrillion },
    { value: 1e18, symbol: 'E' },
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export function toCurrency(value, locale) {
  return value.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'currency',
    currency: locale == 'en-US' ? 'USD' : 'EUR',
  })
}

export function toSize(bytes) {
  const units = ['B', 'KB', 'MB', 'GB']
  let size = bytes
  let unitIndex = 0
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }
  return `${parseFloat(size.toFixed(2))} ${units[unitIndex]}`
}
