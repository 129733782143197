import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import fetchAPI from 'shared/fetchAPI'
import { imgixURL } from 'shared/imgix'
import redirectWithFlashMessage from 'shared/redirectWithFlashMessage'

import Card from './Card'
import CampaignList from './CampaignList'
import useMe from 'creators/useMe'
import Loader from 'shared/components/Loader'

export function Store({
  store,
  campaigns,
  proPreview = false,
  isInfluencer,
  ...rest
}) {
  const { banner } = store

  const cover = banner ? imgixURL(banner) : null

  return (
    <Container proPreview={proPreview}>
      {cover && <Cover src={cover} />}
      <Card store={store} isInfluencer={isInfluencer} {...rest} />
      <CampaignList campaigns={campaigns} hideTooltips={!isInfluencer} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  ${({ proPreview }) => (proPreview ? 'pointer-events: none' : '')};
`
const Cover = styled.div`
  width: 100vw;
  height: 30vw;
  max-height: 440px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ src }) => `background-image: url(${src})`};
`

const withData = Component => props => {
  const { storeId } = props.match.params
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const { is_influencer, followed_store_ids } = useMe()

  const fetchData = async () => {
    try {
      const { average_response_time: averageResponseTime, store } =
        await fetchAPI(`/api/v3/stores/${storeId}`)
      const params = qs.stringify(
        {
          filter: {
            store_id: storeId,
          },
          show_influencer_campaigns: is_influencer ? undefined : '1',
        },
        { addQueryPrefix: true }
      )

      const campaigns = await fetchAPI(`/api/v3/campaigns${params}`)

      const fetchedData = {
        averageResponseTime,
        store,
        campaigns,
        followedStoreIds: followed_store_ids,
        isInfluencer: is_influencer,
      }

      setData(fetchedData)
      setIsLoading(false)
    } catch (error) {
      redirectWithFlashMessage(`/creators/campaigns`, {
        type: 'notice',
        key: `js.global.error`,
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (isLoading) return <Loader fullHeight />

  return <Component {...data} {...props} />
}

const StoreData = withData(Store)
StoreData.displayName = 'StoreData'

export default StoreData
