import React, { useEffect, useState, useContext } from 'react'
import { Nav, NavItem } from 'react-bootstrap'
import RoutedNavItem from 'shared/components/RoutedNavItem'
import { imagePath } from 'shared/images'
import useMe from 'creators/useMe'
import styled from 'styled-components'
import useBreakpoint from 'shared/useBreakpoint'
import fetchAPI from 'shared/fetchAPI'
import { CreatorContext } from 'creators/CreatorProvider'
import {
  ArppBadge,
  ArppBadgeContainer,
} from 'shared/components/Arpp/badge/index'
import cn from 'classnames'

const DesktopNav = styled(Nav).attrs(({ className }) => ({
  className: cn(className, 'nav-pills nav-stacked'),
}))`
  > li > a {
    color: black;
  }
`
const MobileNav = styled.ul`
  list-style: none;
  padding-left: 0;
  > li > a {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: black;
  }
`

function Menu() {
  const {
    is_influencer,
    phone,
    unconfirmed_phone,
    free_store_country_code,
    arpp_certified_date,
  } = useMe()
  const mobileUI = !useBreakpoint('sm')
  const ResponsiveNav = mobileUI ? MobileNav : DesktopNav
  const { alerts } = useContext(CreatorContext)
  const [badges, setBadges] = useState()
  useEffect(() => {
    fetchAPI(`/api/v3/creators/alerts/settings_badges`).then(setBadges)
  }, [alerts])
  return (
    <ResponsiveNav className="child-links-unstyled">
      {arpp_certified_date &&
        is_influencer &&
        free_store_country_code === 'FR' && (
          <ArppBadgeContainer>
            <div className={`${mobileUI ? '' : 'pt-10 pr-10 pl-10 pb-5'}`}>
              <ArppBadge hasUnderText arppCertifiedDate={arpp_certified_date} />
            </div>
            <Separator />
          </ArppBadgeContainer>
        )}
      <RoutedNavItem
        to="/creators/settings/accounts"
        altRoutes={['/creators/settings/social_accounts_interests']}
      >
        {I18n.t('creators.settings.sidemenu.social_accounts')}
        {badges?.has_social_accounts_to_tag && <MissingInfoBadge />}
      </RoutedNavItem>
      {is_influencer && free_store_country_code !== 'US' && (
        <RoutedNavItem to="/creators/settings/professional_account">
          {I18n.t('creators.settings.sidemenu.professional_account')}
        </RoutedNavItem>
      )}
      <Separator />

      <RoutedNavItem to="/creators/settings/personal">
        {I18n.t('creators.settings.sidemenu.personal_settings')}
        {badges?.personal_settings_incomplete && <MissingInfoBadge />}
      </RoutedNavItem>
      {mobileUI && (
        <RoutedNavItem to="/creators/settings/personal">
          {I18n.t('layouts.creators.user_menu.change_language')}
        </RoutedNavItem>
      )}
      <RoutedNavItem to="/creators/settings/change_password">
        {I18n.t('creators.settings.sidemenu.change_password')}
      </RoutedNavItem>
      <RoutedNavItem to="/creators/settings/interests">
        {I18n.t('creators.settings.sidemenu.interests')}
      </RoutedNavItem>
      <RoutedNavItem to="/creators/settings/profile">
        {I18n.t('creators.settings.sidemenu.profile')}
        {badges?.personal_profile_incomplete && <MissingInfoBadge />}
      </RoutedNavItem>
      <RoutedNavItem to="/creators/settings/notifications">
        {I18n.t('creators.settings.sidemenu.notifications')}
      </RoutedNavItem>

      {(phone || unconfirmed_phone) && (
        <>
          <Separator />
          <RoutedNavItem to="/creators/settings/confirm_phone">
            {I18n.t('creators.settings.sidemenu.confirm_phone')}
            {badges?.unconfirmed_phone && <MissingInfoBadge />}
          </RoutedNavItem>
        </>
      )}

      <Separator />

      <NavItem
        href={I18n.t(
          is_influencer
            ? 'creators.settings.sidemenu.help_link'
            : 'creators.settings.sidemenu.help_link_consumer'
        )}
      >
        {I18n.t('creators.settings.sidemenu.help_label')}
      </NavItem>
      {mobileUI && (
        <>
          <Separator />
          <NavItem href="/logout/creator">
            {I18n.t('layouts.creators.user_menu.logout')}
          </NavItem>
        </>
      )}
    </ResponsiveNav>
  )
}

function MissingInfoBadge() {
  const mobileUI = !useBreakpoint('sm')
  return (
    <img
      src={imagePath('creators/settings/sidemenu/missing_info.svg')}
      style={
        mobileUI
          ? {
              height: 17,
              marginLeft: 5,
              verticalAlign: 'middle',
            }
          : {
              height: 17,
              position: 'absolute',
              left: -8,
              top: 10,
              zIndex: 1,
              display: 'inline-block',
            }
      }
    />
  )
}

const Separator = styled.div.attrs(() => ({ className: 'border-top' }))`
  margin-top: 7px;
  margin-bottom: 7px;
  @media (min-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export default Menu
