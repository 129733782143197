import { useReducer } from 'react'

/**
  SOURCE: https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  USAGE:
    const MyComponent = props => {
      const forceRerender = useForceRerender()

      return (
        <div onClick={() => forceRerender()}>Hello, World!</div>
      )
    }
*/

function useForceRerender() {
  // eslint-disable-next-line no-unused-vars
  const [ignored, forceRerender] = useReducer(x => x + 1, 0)

  return forceRerender
}

export default useForceRerender
