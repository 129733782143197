import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Button, DropdownButton, MenuItem } from 'react-bootstrap'
import { toCurrency } from 'shared/numbers'
import { CampaignContext } from '../..'
import useMe from 'creators/useMe'
import flatMap from 'lodash/fp/flatMap'
import findIndex from 'lodash/fp/findIndex'
import byId from 'shared/fp/byId'
import { OrderContext } from '.'
import byTypedId from 'shared/fp/byTypedId'
import { isInfluencer } from 'shared/platforms'

function SelectContractAndPlatformAccounts() {
  const {
    campaign: {
      contracts,
      is_paid,
      store,
      applicability: { applicable_contracts },
    },
  } = useContext(CampaignContext)
  const { setContractId, setPlatformAccountIds } = useContext(OrderContext)
  const me = useMe()

  const [selectedContract, setSelectedContract] = useState(
    applicable_contracts.length > 1 || applicable_contracts.length === 0
      ? undefined
      : applicable_contracts?.[0]?.id
  )
  const [selectedPlatformAccounts, setSelectedPlatformAccounts] = useState([])
  useEffect(() => {
    setSelectedPlatformAccounts(
      selectedContract
        ? (
            applicable_contracts |> byId(selectedContract)
          ).applicable_options.map(option => [
            option.id,
            option.applicable_platform_accounts.length === 1
              ? {
                  id: option.applicable_platform_accounts[0].id,
                  type: option.applicable_platform_accounts[0].type,
                }
              : undefined,
          ])
        : []
    )
  }, [selectedContract])

  const thatNeedPlatformAccountChoice = selectedPlatformAccounts =>
    selectedPlatformAccounts.filter(
      ([optionId]) =>
        (
          applicable_contracts
          |> flatMap('applicable_options')
          |> byId(optionId)
        ).applicable_platform_accounts.length > 1
    )

  return (
    <div id="select-contract-and-platform-accounts">
      {applicable_contracts.length > 1 && (
        <>
          <div className="bold top-10 bottom-5">
            {I18n.t(
              'js.creators.campaigns.order_modal.contract_selection.label'
            )}
          </div>
          <Dropdown
            id="contract-selection"
            title={
              selectedContract
                ? contracts |> byId(selectedContract) |> asText
                : I18n.t(
                    'js.creators.campaigns.order_modal.contract_selection.placeholder'
                  )
            }
            onClick={e => e.stopPropagation()}
          >
            {applicable_contracts.map(({ id }) => {
              const contract = contracts |> byId(id)
              return (
                <MenuItem
                  key={id}
                  onClick={() => {
                    setSelectedContract(id)
                  }}
                  active={id === selectedContract}
                >
                  {contract |> asText}
                </MenuItem>
              )
            })}
          </Dropdown>
          {(selectedPlatformAccounts |> thatNeedPlatformAccountChoice)
            .length !== 0 && <hr className="border-color-gray-light-plus" />}
        </>
      )}
      {(selectedPlatformAccounts |> thatNeedPlatformAccountChoice).map(
        ([optionId, typedId]) => {
          const option = contracts |> flatMap('options') |> byId(optionId)
          const applicablePlatformAccounts = (
            applicable_contracts
            |> flatMap('applicable_options')
            |> byId(optionId)
          ).applicable_platform_accounts
          const setSelectedPlatformAccount = typedId => {
            const index =
              selectedPlatformAccounts |> findIndex(([oid]) => oid === optionId)
            setSelectedPlatformAccounts([
              ...selectedPlatformAccounts.slice(0, index),
              [optionId, typedId],
              ...selectedPlatformAccounts.slice(index + 1),
            ])
          }
          return (
            <React.Fragment key={optionId}>
              <div className="bold top-16 bottom-5">
                {I18n.t(
                  'js.creators.campaigns.order_modal.platform_account_selection.label',
                  { platform: I18n.t(`platforms.${option.platform}`) }
                )}
              </div>
              <Dropdown
                id={`platform-account-selection-for-option-${option.id}`}
                title={
                  typedId
                    ? (me.platform_accounts |> byTypedId(typedId))
                        .platform_entity.title
                    : I18n.t(
                        'js.creators.campaigns.order_modal.platform_account_selection.placeholder'
                      )
                }
                onClick={e => e.stopPropagation()}
              >
                {applicablePlatformAccounts.map(({ id, type }) => {
                  const platformAccount = me.platform_accounts |> byId(id)
                  return (
                    <MenuItem
                      key={id}
                      active={typedId?.id === id && typedId?.type === type}
                      onClick={() => {
                        setSelectedPlatformAccount({ id, type })
                      }}
                    >
                      <div
                        className={`platform-icon platform-icon-xs ${platformAccount.platform}`}
                      />{' '}
                      {platformAccount.platform_entity.title}
                      {is_paid &&
                        (platformAccount.platform |> isInfluencer) &&
                        ` - ${toCurrency(
                          platformAccount.platform_entity.paid_campaign_price,
                          store.locale
                        )}`}
                    </MenuItem>
                  )
                })}
              </Dropdown>
            </React.Fragment>
          )
        }
      )}
      {selectedContract &&
        !selectedPlatformAccounts.some(([optionId, typedId]) => !typedId) && (
          <div className="top-30 text-right" style={{ visibility: '' }}>
            <Button
              bsStyle="primary"
              onClick={() => {
                setContractId(selectedContract)
                setPlatformAccountIds(selectedPlatformAccounts)
              }}
            >
              {I18n.t(
                'js.creators.campaigns.order_modal.contract_and_platform_accounts_selection.submit'
              )}
            </Button>
          </div>
        )}
    </div>
  )
}

const asText = contract =>
  contract.options
    .map(({ platform, requested_reviews_count: count }, i) => {
      let str = ''
      if (i > 0) {
        str +=
          i < contract.options.length - 1
            ? I18n.t('support.array.words_connector')
            : I18n.t('support.array.last_word_connector')
      }
      str += `${count} ${I18n.t(`platform_reviews.no_count.${platform}`, {
        count,
      })}`
      return str
    })
    .join('')

export default SelectContractAndPlatformAccounts

const Dropdown = styled(DropdownButton)`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
