import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'

import useComponentWillMount from 'shared/useComponentWillMount'
import { COLORS } from 'shared/scss'

function _Datepicker(
  {
    defaultFocused = undefined,
    focused: _focused = undefined,
    onFocusChange: _onFocusChange = undefined,
    className,
    numberOfMonths = 1,
    locale,
    date,
    onDateChange,
    ...props
  },
  ref
) {
  if (
    (_focused !== undefined && !_onFocusChange) ||
    (_onFocusChange && _focused === undefined)
  ) {
    throw new Error(
      '"focused" and "onFocusChange" props: If one of them is defined, the other one cannot be undefined.'
    )
  }

  if (
    _focused !== undefined &&
    _onFocusChange &&
    defaultFocused !== undefined
  ) {
    console.warning(
      'Since focus is controlled externally, "defaultFocused" is not used.'
    )
  }

  const [localFocused, setLocalFocused] = useState(defaultFocused || false)
  const focused = useMemo(
    () => (_focused !== undefined ? _focused : localFocused),
    [_focused, localFocused]
  )
  const setFocused = useMemo(
    () => (_onFocusChange ? _onFocusChange : setLocalFocused),
    [_onFocusChange]
  )

  useComponentWillMount(() => {
    moment.locale(locale || window.CURRENT_USER?.locale || navigator.language)
  })

  return (
    <Container className={className}>
      <SingleDatePicker
        ref={ref}
        date={moment(date)}
        onDateChange={date => {
          if (date) {
            onDateChange(date.format('YYYY-MM-DD'))
          }
        }}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={numberOfMonths}
        verticalSpacing={10}
        hideKeyboardShortcutsPanel
        {...props}
      />
    </Container>
  )
}

export const Datepicker = React.forwardRef(_Datepicker)

const Container = styled.div`
  ${({
    theme: {
      global: { inputs, transition },
    },
  }) => css`
    .SingleDatePickerInput__withBorder {
      border: ${inputs.border};
      border-radius: ${inputs.borderRadius};
      transition: ${transition};

      &:hover {
        border: ${inputs.hover.border};
      }

      &:focus {
        border: ${inputs.focus.border};
      }
    }

    .DateInput {
      width: 120px;
    }

    .DateInput_input {
      font-size: 14px;
      line-height: 18px;
      font-family: ${inputs.fontFamily};
      color: ${inputs.color};
      text-align: center;
      padding: 8px 0 6px;
      border: none;
    }

    .DateInput_input__focused {
      border: none;
    }

    .CalendarDay {
      padding: 0 !important;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: ${COLORS.primary};
      border: none;
    }
  `}
`
