import React from 'react'
import Select from 'react-select'
import cn from 'classnames'
import OctoChevron from 'assets/images/chevron.svg'
import max from 'lodash/max'

const sizeStyles = {
  control: (base, { selectProps }) => {
    let { size, width, options } = selectProps
    if (!width && size) {
      const charWidth = 18
      const additionalSpace = 32
      if (size === 'auto') {
        const maxOptionLabelLength = max(
          (options || []).map(option => (option.label || '').length)
        )
        width = maxOptionLabelLength * charWidth
        if (width > 300) width = undefined
      }
      if (typeof size === 'number') width = size * charWidth
      if (width) width = width + additionalSpace
    }
    return {
      ...base,
      width,
    }
  },
}

const DropdownIndicator = () => <OctoChevron style={{ margin: 6 }} />

export default function OSelect({
  className,
  components,
  styles,
  onChange: baseOnChange,
  value,
  isLoadingOptions,
  ...props
}) {
  if (value !== null && value !== undefined)
    value = props.options.find(opt => opt.value === value)
  let onChange
  if (typeof baseOnChange === 'function')
    onChange = item => baseOnChange(item ? item.value : null)

  return (
    <Select
      className={cn('oselect', className)}
      classNamePrefix="oselect"
      components={{ DropdownIndicator, ...components }}
      styles={{ ...sizeStyles, ...styles }}
      value={value}
      onChange={onChange}
      placeholder={
        isLoadingOptions
          ? I18n.t('js.global.loading')
          : I18n.t('js.shared.oselect.placeholder')
      }
      {...props}
    />
  )
}
