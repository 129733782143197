const env = process.env.RAILS_ENV
const envPath = env === 'development' ? '' : `/${env}`
const domain = window.OctolyImagesDomain || window.location.origin

export const SKEEPERS_UI_ASSET_PATH = `${domain}${envPath}/packs/skeepers-ui-core/`
export const STORE_ID = window.STORE_ID
export const CLIENT_ID = window.CLIENT_ID
export const CURRENT_STORE = window.CURRENT_STORE
export const CURRENT_USER = window.CURRENT_USER
export const apolloInitialCache = window.apolloInitialCache

export default {
  SKEEPERS_UI_ASSET_PATH,
  STORE_ID,
  CLIENT_ID,
  CURRENT_STORE,
  CURRENT_USER,
  apolloInitialCache,
}
