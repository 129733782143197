import React from 'react'
import { Popover } from 'react-bootstrap'
import APP_ISO_CONF from 'shared/appIsoConf'

export default ({ passwordCheck, ...props }) => {
  return (
    <Popover style={{ maxWidth: 350 }} {...props}>
      <div>
        {(() => {
          const [textBefore, textAfter] = I18n.t(
            'js.creators_registration.form.password_rules.password_policy_20191122.description',
            { min_length: '<SEPARATOR>' }
          ).split('<SEPARATOR>')
          return (
            <React.Fragment>
              {textBefore}
              <span
                className={
                  passwordCheck.details.minLength ? 'color-green' : 'color-red'
                }
              >
                {I18n.t(
                  'js.creators_registration.form.password_rules.password_policy_20191122.n_characters',
                  { count: APP_ISO_CONF.password_policy.min_length }
                )}
              </span>
              {textAfter}
            </React.Fragment>
          )
        })()}
      </div>
      {['lowercase', 'uppercase', 'number', 'symbol'].map(criteria => (
        <div
          key={criteria}
          className={
            passwordCheck.details[criteria] ? 'color-green' : 'color-red'
          }
        >
          {I18n.t(
            `js.creators_registration.form.password_rules.password_policy_20191122.${criteria}`
          )}
        </div>
      ))}
    </Popover>
  )
}
