import React from 'react'
import PropTypes from 'prop-types'
import ReactPhoneInput from 'react-phone-input-2'
import { Controller, useFormContext } from 'react-hook-form'
import Group from './Group'
import APP_ISO_CONF from 'shared/appIsoConf'

/**
 * react-phone-input-2 documentation
 * https://github.com/bl00mber/react-phone-input-2
 */

const PhoneInput = ({ controllerProps, phoneProps }) => {
  const { errors } = useFormContext()

  return (
    <Group
      error={errors.unconfirmed_phone}
      label={I18n.t('js.creators.settings.edit.phone')}
    >
      <Controller
        name="unconfirmed_phone"
        as={
          <ReactPhoneInput
            placeholder=""
            preferredCountries={
              phoneProps.onlyCountries
                ? undefined
                : APP_ISO_CONF.preferred_countries
            }
            onlyCountries={phoneProps.onlyCountries}
            disableDropdown={phoneProps.onlyCountries?.length === 1}
            enableTerritories
            countryCodeEditable={false}
            inputStyle={{ width: '100%' }}
            specialLabel=""
            {...phoneProps}
          />
        }
        onChange={value => value[0]}
        {...controllerProps}
      />
    </Group>
  )
}

PhoneInput.propTypes = {
  controllerProps: PropTypes.object,
  phoneProps: PropTypes.shape({
    country: PropTypes.string, // Country code, must be in lowercase
    containerStyle: PropTypes.object, // Override react-phone-input container style
    inputStyle: PropTypes.object, // Override react-phone-input container style
    inputProps: PropTypes.object, // Props passed to the native input
  }),
}

export default PhoneInput
