import { THEME } from 'styles'
import { COLORS } from 'shared/scss'

const white = '#ffffff'
const blue = '#4A90E2'
const bsBorderBlue = '#66afe9'
const bsBorderGrey = '#E8EBED'
const bsBoxShadowBlue =
  'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)'
const bsBackgroundOption = '#f8f9fa'
const bsControlTextColor = '#555555'

export const oldSelectStyles = {
  container: (base, state) => ({
    ...base,
    minWidth: '150px',
  }),
  control: (base, state) => ({
    ...base,
    height: 34,
    minHeight: 34,
    backgroundColor: white,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: state.selectProps.menuIsOpen ? bsBorderBlue : bsBorderGrey,
    boxShadow: state.selectProps.menuIsOpen ? bsBoxShadowBlue : 'none',
    color: bsControlTextColor,
    ':hover': {
      borderColor: state.selectProps.menuIsOpen ? bsBorderBlue : bsBorderGrey,
    },
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    backgroundColor: blue,
    color: white,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    backgroundColor: blue,
    color: white,
    cursor: 'pointer',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    ':hover': {
      backgroundColor: bsBorderBlue,
    },
  }),
  option: (base, state) => ({
    ...base,
    color: bsControlTextColor,
    backgroundColor: state.isFocused ? bsBackgroundOption : white,
    '&:focus,&:active': {
      backgroundColor: 'inherit',
    },
  }),
  menu: (base, state) => ({
    ...base,
    minWidth: '100%',
    maxWidth: 300,
    width: 'auto',
  }),
}

// TODO: Handle error styles
export const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.4 : 1,
  }),
  control: (provided, { isDisabled, isFocused, selectProps: { error } }) => ({
    ...provided,
    '&:hover': {
      ...provided['&:hover'],
      borderWidth: THEME.global.inputs.borderWidth,
      boxShadow: 'none',
      borderColor: isDisabled
        ? THEME.global.inputs.borderColor
        : isFocused
        ? THEME.global.inputs.focus.borderColor
        : THEME.global.inputs.hover.borderColor,
    },
    borderWidth: THEME.global.inputs.borderWidth,
    borderColor: isFocused
      ? THEME.global.inputs.focus.borderColor
      : error
      ? COLORS.red
      : THEME.global.inputs.borderColor,
    borderRadius: THEME.global.inputs.borderRadius,
    boxShadow: 'none',
    minHeight: THEME.global.inputs.height,
    transition: THEME.global.transition,
    fontSize: THEME.global.inputs.fontSize,
    backgroundColor: THEME.global.inputs.backgroundColor,
    color: THEME.global.inputs.color,
  }),
  indicatorSeparator: () => {},
  clearIndicator: provided => ({
    ...provided,
    color: COLORS.neutral900,
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: 10,
    '& > svg': {
      width: '15px',
      height: '15px',
    },
    '&:hover': {
      ...provided['&:hover'],
      color: COLORS.neutral900,
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: COLORS.neutral900,
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: 12,
    '& > svg': {
      width: '14px',
      height: '14px',
    },
    '&:hover': {
      ...provided['&:hover'],
      color: COLORS.neutral900,
    },
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: THEME.global.inputs.fontSize,
    fontFamily: THEME.global.inputs.fontFamily,
    color: THEME.global.inputs.placeholderColor,
    margin: 0,
  }),
  input: provided => ({
    ...provided,
    fontFamily: THEME.global.inputs.fontFamily,
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: '12px',
    backgroundColor: `${COLORS.primary}99`,
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: COLORS.white,
    fontFamily: THEME.global.inputs.fontFamily,
    padding: 4,
    paddingLeft: 8,
    paddingRight: 0,
    paddingTop: 4,
  }),
  multiValueRemove: provided => ({
    ...provided,
    '&:hover': {
      ...provided['&:hover'],
      backgroundColor: 'transparent',
      color: COLORS.white,
      cursor: 'pointer',
    },
    color: COLORS.white,
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: 8,
    paddingRight: 8,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? THEME.global.inputs.color : provided.color,
  }),
  menu: provided => ({
    ...provided,
    zIndex: 1000,
  }),
  option: (provided, { data, isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected
      ? COLORS.primary
      : isFocused
      ? COLORS.neutral100
      : 'white',
    paddingLeft: data.level ? (data.level === 1 ? 12 : 16 * data.level) : 12,
  }),
}

export const selectVariantStyles = variant => {
  switch (variant) {
    case 'statistics_filter':
      return {
        ...selectStyles,
        control: (base, state) => ({
          ...base,
          minHeight: 35,
          borderRadius: 4,
          borderColor: state.selectProps.menuIsOpen
            ? COLORS.neutral400
            : COLORS.neutral300,
          boxShadow: 'none',
          ':hover': {
            borderColor: state.selectProps.menuIsOpen
              ? COLORS.neutral400
              : COLORS.neutral300,
          },
        }),
        placeholder: provided => ({
          ...provided,
          color: COLORS.neutral900,
          fontSize: 14,
          margin: 0,
        }),
        valueContainer: provided => ({
          ...provided,
          paddingLeft: 12,
          paddingRight: 12,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          flexWrap: 'nowrap',
        }),
        input: provided => ({
          ...provided,
          minWidth: '20%',
        }),
        option: (provided, { isSelected }) => ({
          ...provided,
          cursor: 'pointer',
          color: isSelected ? `${COLORS.primary}!important` : COLORS.neutral900,
          '&:focus,&:active': {
            backgroundColor: 'inherit',
            color: COLORS.primary,
          },
        }),
      }
    default:
      return selectStyles
  }
}
