export const addEvent = (event, properties = {}) => {
  const storeIdMeta = document.querySelector("meta[name='storeId']")
  const clientIdMeta = document.querySelector("meta[name='clientId']")
  const accessTokenMeta = document.querySelector(
    "meta[name='0229446d125a944b391f0385ff2bfe04']"
  )

  if (storeIdMeta && clientIdMeta) {
    properties = {
      ...properties,
      client_id: clientIdMeta.getAttribute('content'),
      store_id: storeIdMeta.getAttribute('content'),
    }
  }

  fetch('/api/v3/events', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': accessTokenMeta.getAttribute('content'),
    },
    body: JSON.stringify({
      event: event,
      properties: properties,
    }),
  })
}
