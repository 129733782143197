import cn from 'classnames'
import gql from 'graphql-tag'
import update from 'immutability-helper'
import Cookies from 'js-cookie'
import qs from 'qs'
import scss from 'shared/scss'

import fetchAPI from 'shared/fetchAPI'

const fapi = (...args) => fetchAPI(...args).then(console.log, console.error)

// useful to inspect dropdown menu items that disappear on blur
function debugInOneSecond() {
  setTimeout(() => {
    debugger
  }, 1000)
}

Object.assign(window, {
  fapi,
})

window.inj = () => {
  const client = window.__APOLLO_CLIENT__
  const UTILS = {
    cn,
    gql,
    update,
    Cookies,
    qs,
    fetchAPI,
    client,
    scss,
    debugInOneSecond,
  }
  Object.assign(window, UTILS)
  console.log('injected utils', UTILS)
}
