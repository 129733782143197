import { useState, useEffect } from 'react'
import useMe from './useMe'
import { isInstagramPlatform, PLATFORMS_CONFIG } from 'shared/platforms'

const ACCOUNTS = { instagram_accounts: [], tiktok_accounts: [] }

export default function useAccountToMigrate() {
  const me = useMe()
  const [migrate, setMigrate] = useState([false, ACCOUNTS])

  useEffect(() => {
    if (!!me) {
      const { platform_accounts } = me
      const accountsToMigrate = platform_accounts.reduce(
        (acc, { platform, status, has_credential, id }) => {
          if (
            isInstagramPlatform(platform) &&
            status === 'enabled' &&
            !has_credential
          ) {
            return {
              ...acc,
              instagram_accounts: [...acc.instagram_accounts, id],
            }
          }
          if (
            platform === PLATFORMS_CONFIG.tiktok.key &&
            status === 'enabled' &&
            !has_credential
          ) {
            return {
              ...acc,
              tiktok_accounts: [...acc.tiktok_accounts, id],
            }
          }

          return acc
        },
        { ...ACCOUNTS }
      )

      const accountsToMigrateCount = Object.keys(accountsToMigrate).reduce(
        (acc, current) => {
          return [...acc, ...accountsToMigrate[current]]
        },
        []
      ).length

      const hasAccountToMigrate = accountsToMigrateCount > 0

      if (!hasAccountToMigrate && localStorage.getItem('hasSeenNotif')) {
        localStorage.removeItem('hasSeenNotif')
      }

      setMigrate([hasAccountToMigrate, accountsToMigrate])
    }
  }, [])

  return [...migrate]
}
