import styled, { css } from 'styled-components'
import { COLORS } from 'shared/scss'

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: unset;
    cursor: pointer;
    font-family: ${theme.global.fontFamilyBold};
    color: ${COLORS.grayDarker};
  `}
`
