import { useState, useEffect } from 'react'
import fetchAPI from 'shared/fetchAPI'

function useAPI(url, fetchOptions, { fetchToUse = fetchAPI } = {}) {
  const [data, setData] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)
  useEffect(() => {
    ;(async () => {
      try {
        setData(await fetchToUse(url, fetchOptions))
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [url])
  return { data, loading, error }
}

export default useAPI
