import React from 'react'
import ConsumerIcon from 'assets/images/social-platforms/consumer.svg'
import ConsumerWhiteIcon from 'assets/images/social-platforms/consumer-white.svg'
import ConsumerGrayIcon from 'assets/images/social-platforms/consumer-gray.svg'
import InstagramIcon from 'assets/images/social-platforms/instagram.svg'
import InstagramWhiteIcon from 'assets/images/social-platforms/instagram-white.svg'
import InstagramGrayIcon from 'assets/images/social-platforms/instagram-gray.svg'
import PostsIcon from 'assets/images/social-platforms/instagram-posts.svg'
import PostsWhiteIcon from 'assets/images/social-platforms/instagram-posts-white.svg'
import PostsGrayIcon from 'assets/images/social-platforms/instagram-posts-gray.svg'
import StoriesIcon from 'assets/images/social-platforms/instagram-stories.svg'
import StoriesWhiteIcon from 'assets/images/social-platforms/instagram-stories-white.svg'
import StoriesGrayIcon from 'assets/images/social-platforms/instagram-stories-gray.svg'
import TiktokIcon from 'assets/images/social-platforms/tiktok.svg'
import TiktokWhiteIcon from 'assets/images/social-platforms/tiktok-white.svg'
import TiktokGrayIcon from 'assets/images/social-platforms/tiktok-gray.svg'
import ReelsIcon from 'assets/images/social-platforms/instagram-reels.svg'
import ReelsWhiteIcon from 'assets/images/social-platforms/instagram-reels-white.svg'
import ReelsGrayIcon from 'assets/images/social-platforms/instagram-reels-gray.svg'
import YoutubeIcon from 'assets/images/social-platforms/youtube.svg'
import YoutubeWhiteIcon from 'assets/images/social-platforms/youtube-white.svg'
import YoutubeGrayIcon from 'assets/images/social-platforms/youtube-gray.svg'
import { isInstagramPlatform } from 'shared/platforms'

const icons = {
  youtube: {
    icon: YoutubeIcon,
    whiteIcon: YoutubeWhiteIcon,
    grayIcon: YoutubeGrayIcon,
  },
  instagram_global: {
    icon: InstagramIcon,
    whiteIcon: InstagramWhiteIcon,
    grayIcon: InstagramGrayIcon,
  },
  instagram: {
    icon: PostsIcon,
    whiteIcon: PostsWhiteIcon,
    grayIcon: PostsGrayIcon,
  },
  instagram_stories: {
    icon: StoriesIcon,
    whiteIcon: StoriesWhiteIcon,
    grayIcon: StoriesGrayIcon,
  },
  tiktok: {
    icon: TiktokIcon,
    whiteIcon: TiktokWhiteIcon,
    grayIcon: TiktokGrayIcon,
  },
  instagram_reels: {
    icon: ReelsIcon,
    whiteIcon: ReelsWhiteIcon,
    grayIcon: ReelsGrayIcon,
  },
  consumer: {
    icon: ConsumerIcon,
    whiteIcon: ConsumerWhiteIcon,
    grayIcon: ConsumerGrayIcon,
  },
}

export default function PlatformIcon({
  platform,
  white,
  disabled,
  shouldShowBasePlatformIcon = false,
  ...props
}) {
  if (!(platform in icons)) {
    throw new Error(`Invalid platform ${platform}`)
  }

  const p =
    shouldShowBasePlatformIcon && isInstagramPlatform(platform)
      ? 'instagram_global'
      : platform

  const Icon = disabled
    ? icons[p].grayIcon
    : white
    ? icons[p].whiteIcon
    : icons[p].icon

  return <Icon {...props} />
}
