import React, { useContext } from 'react'
import OptionsSelect from './OptionsSelect'
import { CampaignContext } from '../..'
import byId from 'shared/fp/byId'

function CampaignOptions({ showSelectOptionError, productId }) {
  const {
    selectedOptionIds,
    setSelectedOptionIds,
    campaign: { products },
  } = useContext(CampaignContext)
  return (
    <>
      <div className="bottom-5">
        {products.length > 1
          ? (products |> byId(productId)).title
          : I18n.t('js.creators.campaigns.show.options.label')}
      </div>
      <OptionsSelect
        productId={productId}
        valid={!showSelectOptionError}
        placeholder={I18n.t('js.creators.campaigns.show.options.placeholder')}
        value={selectedOptionIds[productId]}
        onChange={productOptionId =>
          setSelectedOptionIds({
            ...selectedOptionIds,
            [productId]: productOptionId,
          })
        }
      />
    </>
  )
}

export default CampaignOptions
