import React, { useContext } from 'react'
import styled from 'styled-components'
import { SkpIcon } from '@skeepers/skeepers-ui-react'
import { faCamera } from '@skeepers/skeepers-icons/regular/faCamera'
import { CampaignContext } from '../..'
import PlatformIcon from 'shared/components/PlatformIcon'
import { PLATFORMS_CONFIG } from 'shared/platforms'

const Contracts = () => {
  const {
    campaign: { contracts, applicability },
  } = useContext(CampaignContext)
  let contractsToShow = contracts
  if (applicability) {
    contractsToShow = contractsToShow.filter(contract =>
      applicability.applicable_contracts.some(({ id }) => id === contract.id)
    )
  }
  return (
    <>
      <div className="uppercase bold bottom-10">
        {I18n.t('js.creators.campaigns.show.contracts.title')}
      </div>
      {contractsToShow.map(({ id, options }, i) => (
        <React.Fragment key={id}>
          {i > 0 && (
            <div className="d-flex align-items-center top-2 bottom-2">
              <div className="border-bottom flex-grow-1" />
              <div
                className="left-20 right-20 uppercase bold color-green-light border-color-green-light bordered rounded text-center"
                style={{ height: 30, width: 30, lineHeight: '30px' }}
              >
                {I18n.t('js.global.or')}
              </div>
              <div className="border-bottom flex-grow-1" />
            </div>
          )}
          <Contract>
            {options.map(option => (
              <ContractOption key={option.id} contractOption={option} />
            ))}
          </Contract>
        </React.Fragment>
      ))}
    </>
  )
}

const Contract = styled.div`
  border-radius: 4px;
  box-shadow: 0px 0px 10px #e6e6e6;
`

function ContractOption({
  contractOption: { platform, requested_reviews_count, consumer_review_urls },
}) {
  const consumerReviewWithPhotosCount =
    consumer_review_urls?.filter(item => item.ask_photo).length || 0
  const otherPlatformCount =
    requested_reviews_count - consumerReviewWithPhotosCount
  const hasConsumerReviewWithPhotos =
    platform === PLATFORMS_CONFIG.consumer.key &&
    consumerReviewWithPhotosCount > 0

  return (
    <>
      {/* GIFTED REVIEWS WITH PHOTOS */}
      {hasConsumerReviewWithPhotos && (
        <ContractOptionContainer className="d-flex align-items-center">
          <PlatformIcon
            platform={platform}
            className="mr-10"
            width={24}
            height={24}
          />
          <div className="d-flex flex-grow-1 flex-shrink-1">
            <span
              dangerouslySetInnerHTML={{
                __html: I18n.t(
                  'platform_reviews.no_count.consumer_with_photos',
                  {
                    count: consumerReviewWithPhotosCount,
                  }
                ),
              }}
            />
            <SkpIcon icon={faCamera} size="medium" className="ml-5 mt-1" />
          </div>
          <div className="bold">× {consumerReviewWithPhotosCount}</div>
        </ContractOptionContainer>
      )}

      {/* GIFTED REVIEWS WITHOUT PHOTOS AND OTHER PLATFORMS */}
      {otherPlatformCount > 0 && (
        <ContractOptionContainer className="d-flex align-items-center">
          <PlatformIcon
            platform={platform}
            className="mr-10"
            width={24}
            height={24}
          />
          <div className="flex-grow-1 flex-shrink-1">
            {I18n.t(`platform_reviews.no_count.${platform}`, {
              count: otherPlatformCount,
            })}
          </div>
          <div className="bold">× {otherPlatformCount}</div>
        </ContractOptionContainer>
      )}
    </>
  )
}

const ContractOptionContainer = styled.div`
  padding: 8px 15px;
  background-color: white;
  & + & {
    border-top: solid 1px #e0e0e0;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

export default Contracts
