import React from 'react'
import ReactDOM from 'react-dom'

export class FlashMessages extends React.Component {
  componentDidMount() {
    this.show()
  }

  componentDidUpdate() {
    this.show()
  }

  show() {
    const el = ReactDOM.findDOMNode(this)

    $(el).on('click', function () {
      $(this).fadeOut()
    })

    if (this.timeout) clearTimeout(this.timeout)
    el.style.display = 'block'
    this.timeout = setTimeout(function () {
      el.style.display = 'none'
    }, 6000)
  }

  render() {
    return (
      <div className={'flash-message-' + this.props.type}>
        {this.props.text}
      </div>
    )
  }
}

FlashMessages.defaultProps = {
  type: 'notice',
  text: 'loading ...',
}

export const showFlashMessage = (type, text) => {
  return ReactDOM.render(
    <FlashMessages text={text} type={type} />,
    document.getElementById('flash-message')
  )
}
