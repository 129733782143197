import React, { useState } from 'react'
import styled from 'styled-components'

import { imagePath } from 'shared/images'
import fetchAPI from 'shared/fetchAPI'
import showFlashMessage from 'creators/common/showFlashMessage'
import { COLORS } from 'shared/scss'
import Loader from 'shared/components/Loader'

export default function FollowButton({
  storeFollowed = false,
  storeId,
  source,
}) {
  const [isfollowing, setIsFollowing] = useState(storeFollowed)
  const [isLoading, setIsLoading] = useState(false)

  const toggleFollow = async () => {
    setIsLoading(true)
    try {
      if (isfollowing) {
        await fetchAPI(`/api/v3/stores/${storeId}/unfollow`, {
          method: 'POST',
          body: { source },
        })
        setIsFollowing(false)
      } else {
        await fetchAPI(`/api/v3/stores/${storeId}/follow`, {
          method: 'POST',
          body: { source },
        })
        setIsFollowing(true)
      }
    } catch (error) {
      const errorMsg = error.data[0]
      switch (errorMsg) {
        case 'store_id_taken':
          setIsFollowing(true)
          break
        case 'follow_not_found':
          setIsFollowing(false)
          break
        default:
          showFlashMessage('error', I18n.t(`js.global.error`))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      isfollowing={isfollowing}
      onClick={toggleFollow}
      disabled={isLoading || !!window.CURRENT_STORE}
    >
      {isfollowing
        ? I18n.t(`js.creators.stores.following`)
        : I18n.t(`js.creators.stores.follow`)}
      {isLoading && <Loader inline right />}
    </Button>
  )
}

const Button = styled.button`
  padding: 0px 20px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 50px;
  color: ${COLORS.primary};
  height: 35px;
  display: flex;
  align-items: center;
  outline: 0;
  transition: 300ms;
  ${props =>
    props.isfollowing
      ? `
      background-color: ${COLORS.primary};
      color: white;
    `
      : `
      background-color: white;
      color: ${COLORS.primary};
    `}

  &:hover {
    ${props =>
      props.isfollowing
        ? `
        background-color: white;
        color: ${COLORS.primary};
      `
        : `
        background-color: ${COLORS.primary};
        color: white;
      `}
  }
`
