import Quill from 'quill'

const InlineEmbed = Quill.import('blots/embed')

class ImageBlot extends InlineEmbed {
  static create({ src, width, height }) {
    const node = super.create({ src, width, height })
    node.setAttribute('src', src)
    if (width) node.setAttribute('width', width)
    if (height) node.setAttribute('height', height)

    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
    }
  }
}

ImageBlot.blotName = 'imageBlot'
ImageBlot.className = 'image-blot'
ImageBlot.tagName = 'img'

Quill.register({ 'formats/imageBlot': ImageBlot })
