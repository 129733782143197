// handles anything that is not 2xx when calling fetch
export class FetchError extends Error {
  constructor(fetchResponse) {
    super(`Error ${fetchResponse.status} - ${fetchResponse.statusText}`)
    this.name = 'FetchError'
    this.response = fetchResponse
  }
}

export default FetchError
