import { createGlobalStyle } from 'styled-components'
import { Margins, MarginsAuto, SpaceChildren, Paddings } from './spacing'
import Typo, {
  Caption,
  Displays,
  FontFamilies,
  FontSizes,
  FooterText,
  Headings,
  LineHeights,
  Paragraphs,
} from './typo'
import { TextColors, BackgroundColors } from './colors'
import Misc, {
  BootstrapModalCustom,
  Border,
  BorderRadius,
  Heights,
  Hover,
  Widths,
} from './misc'
import { BoxShadow } from './boxShadows'

export default createGlobalStyle`
  ${BackgroundColors}
  ${BootstrapModalCustom}
  ${Border}
  ${BorderRadius}
  ${BoxShadow}
  ${Caption}
  ${Displays}
  ${FontFamilies}
  ${FontSizes}
  ${FooterText}
  ${Headings}
  ${Heights}
  ${Hover}
  ${LineHeights}
  ${Margins}
  ${MarginsAuto}
  ${Misc}
  ${Paddings}
  ${Paragraphs}
  ${SpaceChildren}
  ${TextColors}
  ${Typo}
  ${Widths}
`
