import React from 'react'
import styled from 'styled-components'

import Logo from 'assets/images/skeepers-title.svg'

export default function ServerOverLoaded() {
  return (
    <Container className="flex flex-column flex-center">
      <Logo width={200} height={100} />
      <h1 className="text-center">
        {I18n.t('js.creators.server_is_overloaded.title')}
      </h1>
      <h4 className="text-center">
        {I18n.t('js.creators.server_is_overloaded.subtitle')}
      </h4>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 50px;
  padding: 16px;

  @media (min-width: 769px) {
    margin-top: 100px;
  }
`
