/* eslint-disable indent */
import { css } from 'styled-components'

import { THEME } from '../variables'

export function Border() {
  return css`
    .border-default {
      border: ${THEME.global.border};
    }
    .border-top-default {
      border-top: ${THEME.global.border};
    }
    .border-right-default {
      border-right: ${THEME.global.border};
    }
    .border-bottom-default {
      border-bottom: ${THEME.global.border};
    }
    .border-left-default {
      border-left: ${THEME.global.border};
    }
  `
}

export function BorderRadius() {
  return css`
    .br-default {
      border-radius: ${THEME.global.borderRadius};
    }

    .br-input {
      border-radius: ${THEME.global.inputs.borderRadius};
    }

    .br-rounded {
      border-radius: 50%;
    }
  `
}

export function Hover() {
  return css`
    .hover-pointer {
      &:hover {
        cursor: pointer;
      }
    }

    .hover-bs-default {
      transition: ${THEME.global.transition};

      &:hover {
        box-shadow: ${THEME.global.hover.boxShadow};
      }
    }

    .hover-click-bounce {
      transition: ${THEME.global.transition};

      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }

    .hover-underline {
      &:hover {
        text-decoration: underline;
      }
    }
  `
}

export function BootstrapModalCustom() {
  return css`
    .custom-bs-modal {
      &.width-600 {
        max-width: 600px;
      }
      &.width-640 {
        max-width: 640px;
      }
      &.width-900 {
        max-width: 900px;
      }
      &.custom-width {
        width: fit-content;
      }

      &.border-radius {
        & > .modal-content {
          border-radius: ${THEME.global.borderRadius};
        }
      }
      &.no-border {
        & > .modal-content {
          border: none;
        }
      }

      &.thin-close-button {
        .modal-header {
          & > button {
            margin-top: unset;
            opacity: 1;

            & > span {
              font-size: 20px;
              color: #2f2e2e;
            }
          }
        }
      }
    }
  `
}

export function Widths() {
  return css`
    .w-auto {
      width: auto;
    }

    .w-full {
      width: 100%;
    }

    .w-unset {
      width: unset;
    }

    .w-fit {
      width: fit-content;
    }

    .w-fullscreen {
      width: 100vw;
    }
  `
}

export function Heights() {
  return css`
    .h-auto {
      height: auto;
    }

    .h-full {
      height: 100%;
    }

    .h-unset {
      height: unset;
    }

    .h-fit {
      height: fit-content;
    }

    .h-fullscreen {
      height: 100vh;
    }
  `
}

export default css`
  .overflow-hidden {
    overflow: hidden;
  }
  .overflow-auto {
    overflow: auto;
  }
`
